import React, { Component } from 'react';
import './TicketTemplate.scss';
import Utils from '../../../services/utils';

var QRCode = require('qrcode.react')


class TicketTemplate extends Component {

    /*-----------------------------------------------------------*/
    // Action Callback

    formatDate(date) {
        let dateObj;

        if (!date) return '';

        if (typeof date === 'string') {
            dateObj = new Date(date);
        } else if (date.seconds) {
            dateObj = new Date(date.seconds * 1000)
        } else {
            return ''
        }

        if (dateObj === 'Invalid Date') {
            return ''
        }

        return Utils.getDateStringOf(dateObj);
    }

    formatTime(date) {
        let dateObj;

        if (!date) return '';

        if (typeof date === 'string') {
            dateObj = new Date(date);
        } else if (date.seconds) {
            dateObj = new Date(date.seconds * 1000)
        } else {
            return ''
        }

        if (dateObj === 'Invalid Date') {
            return ''
        }

        return Utils.getTimeStringOf(dateObj);
    }

    onPrintTapped(divRef) {
        console.log(window.html2canvas)
        window.html2canvas(document.querySelector(divRef)).then(canvas => {
            let wndNew = window.open()
            wndNew.document.body.appendChild(canvas)
            wndNew.focus()
            wndNew.print()
            wndNew.close()
        });
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { object, style } = this.props;

        if (!object) {
            return (
                <div className="loading-view">NO TICKET!</div>
            )
        }

        let { BuyerUser } = object;
        let { DisplayName } = BuyerUser;

        let dateTimeStr = '';
        if (object.LastUpdatedStr) {
            dateTimeStr = `${this.formatDate(object.LastUpdatedStr)} ${this.formatTime(object.LastUpdatedStr)}`;
        } else {
            dateTimeStr = `${this.formatDate(object.LastUpdated)} ${this.formatTime(object.LastUpdated)}`;
        }

        return (
            <div id='order-div' style={style} className="ticket-template-content">
                {/* <img className="print-icon" alt="PRINT"
                    src={require('../../../../assets/images/print.png')}
                    onClick={() => this.onPrintTapped('#order-div')} /> */}

                <div className="ticket-red-header">
                    <div className="text-center">
                        <img src="https://storage.googleapis.com/ibazaarprod.appspot.com/primetickets/logo2.png" alt="LOGO" />
                    </div>
                    <div className="header-title text-center">e-Ticket - {DisplayName}</div>
                </div>

                <div className="ticket-block">
                    <div className="ticket-title">Good news!</div>
                    <div className="ticket-content">
                        Your tickets to <b>{object.ListTitle}</b> is now <b style={{ color: 'green' }}>confirmed</b>.
                        You are required to scan their ticket at the door to be permitted entry. Mobile or printed tickets will be accepted.
                    </div>
                </div>

                <div className="line" />

                <div className="ticket-block flex">
                    <div className="qr-code"><QRCode id="qr-code" value={object.PaymentId} size={200} /></div>
                    <div className="ticket-detail">
                        <table style={{ width: '100%' }} cellPadding="0"><tbody>
                            {
                                object.TicketNumber &&
                                <tr>
                                    <td className="detail-title"><b>Ticket Number:</b></td>
                                    <td className="detail-description">{object.TicketNumber}</td>
                                </tr>
                            }
                            {
                                object.ItemTitle &&
                                <tr>
                                    <td className="detail-title"><b>Ticket Detail:</b></td>
                                    <td className="detail-description">{object.ItemTitle}</td>
                                </tr>
                            }
                            {
                                object.Items &&
                                <tr>
                                    <td className="detail-title"><b>Ticket Detail:</b></td>
                                    <td className="detail-description">
                                        {
                                            object.Items.map((item, index) => {
                                                return (
                                                    <div key={index}>{item.ItemSelected} X {item.ItemTitle}</div>
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td className="detail-title"><b>Purchase Date:</b></td>
                                <td className="detail-description">{dateTimeStr}</td>
                            </tr>
                            {
                                object.SelectedSeat &&
                                <tr>
                                    <td className="detail-title"><b>Seat Number:</b></td>
                                    <td className="detail-description">{object.SelectedSeat}</td>
                                </tr>
                            }
                            <tr>
                                <td className="detail-title"><b>Payment Receipt:</b></td>
                                <td className="detail-description">{object.PaymentId}</td>
                            </tr>

                            <tr>
                                <td className="detail-title"><b>Payment Amount:</b></td>
                                <td className="detail-description">${object.PaymentTotal}</td>
                            </tr>

                            {
                                object.CouponCode && object.CouponCode.length > 0 ?
                                    <tr>
                                        <td className="detail-title"><b>Coupon:</b></td>
                                        <td className="detail-description">{object.CouponCode}</td>
                                    </tr> : ''
                            }

                            <tr>
                                <td className="detail-title"><b>Payment Status:</b></td>
                                <td className="detail-description">{object.Status ? 'Success' : 'Failure'}</td>
                            </tr>
                            <tr>
                                <td className="detail-title"><b>Ticket Status:</b></td>
                                <td className="detail-description">{object.Status}</td>
                            </tr>

                            {
                                object.ShowNotes && object.Notes.length > 0 &&
                                <tr>
                                    <td className="detail-title"><b>Notes to seller:</b></td>
                                    <td className="detail-description">{object.Notes}</td>
                                </tr>
                            }

                        </tbody></table>

                    </div>
                </div>

                <div className="line" />

                <div className="ticket-block">
                    <img className="fix" src={object.Logo} alt="" />
                    <div className="text-center">
                        <img style={{marginTop: '30px'}}
                            src={require('../../../assets/images/COVID_Safe.png')} alt="" height="150" />
                    </div>
                </div>

                <div className="line" />
                <div className="footercopy">
                    &copy;&#160;
                    <a href="https://www.primetickets.com.au/" rel="noopener noreferrer" target="_blank">
                        2021 primetickets.com.au
                    </a>
                </div>

            </div>
        )
    }
}

export default TicketTemplate;
