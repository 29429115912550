import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    loading: false,
    errorText: '',
    userState: false,
    login: false,
    loginInfo: null,
    banners: {},
    categories: [],
    subCategories: [],
    events: [],
    allEvents: [],
    pastEvents: [],
    bookEvent: null,
    listDetail: null,
    eventDetail: null,
    saleItems: null,
    coupons: [],
    confirm: null,
    dashboard: [],
    report: false,
    hostEvent: false,
    ads: null,
    seats: null,
    eventId: '',
});

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        
        case types.LOADING_SET:
            return state.merge({
                loading: action.value,
            });

        case types.ERROR_SET:
            return state.merge({
                errorText: action.value,
            });

        case types.USER_STATE_SET:
            return state.merge({
                userState: action.value,
            });

        case types.LOGIN_SET:
            return state.merge({
                login: action.value,
            });

        case types.LOGIN_INFO_SET:
            return state.merge({
                loginInfo: action.value,
            });

        case types.BANNERS_SET:
            return state.merge({
                banners: action.value,
            });

        case types.CATEGORIES_SET:
            return state.merge({
                categories: action.value,
            });

        case types.SUB_CATEGORIES_SET:
            return state.merge({
                subCategories: action.value,
            });

        case types.EVENTS_SET:
            return state.merge({
                events: action.value,
            });

        case types.ALL_EVENTS_SET:
            return state.merge({
                allEvents: action.value
            });

        case types.PAST_EVENTS_SET:
            return state.merge({
                pastEvents: action.value
            });

        case types.BOOK_EVENT_SET:
            return state.merge({
                bookEvent: action.value,
            });

        case types.LIST_DETAIL_SET:
            return state.merge({
                listDetail: action.value,
            });

        case types.EVENT_DETAIL_SET:
            return state.merge({
                eventDetail: action.value,
            });

        case types.SALE_ITEM_SET:
            return state.merge({
                saleItems: action.value,
            });

        case types.COUPON_SET:
            return state.merge({
                coupons: action.value,
            });

        case types.CONFIRM_PAYMENT_SET:
            return state.merge({
                confirm: action.value,
            });

        case types.DASHBOARD_CONTENT_SET:
            return state.merge({
                dashboard: action.value,
            });

        case types.REPORT_STATE_SET:
            return state.merge({
                report: action.value,
            });

        case types.HOST_EVENT_TAP_SET:
            return state.merge({
                hostEvent: action.value,
            });

        case types.ADS_SET:
            return state.merge({
                ads: action.value,
            });

        case types.SEATS_SET:
            return state.merge({
                seats: action.value,
            });

        case types.EVENT_ID_SET:
            return state.merge({
                eventId: action.value
            });

        default:
            return state;
    }
}

export function getLoading(state) {
    return state.home.loading;
}

export function getError(state) {
    return state.home.errorText;
}

export function getUserState(state) {
    return state.home.userState;
}

export function getLoginState(state) {
    return state.home.login;
}

export function getLoginInfo(state) {
    return state.home.loginInfo;
}

export function getBanners(state) {
    return state.home.banners;
}

export function getCategories(state) {
    return state.home.categories;
}

export function getSubCategories(state) {
    return state.home.subCategories;
}

export function getEvents(state) {
    return state.home.events;
}

export function getAllEvents(state) {
    return state.home.allEvents;
}

export function getPastEvents(state) {
    return state.home.pastEvents;
}

export function getBookEvent(state) {
    return state.home.bookEvent;
}

export function getListDetail(state) {
    return state.home.listDetail;
}

export function getEventDetail(state) {
    return state.home.eventDetail;
}

export function getSaleItems(state) {
    return state.home.saleItems;
}

export function getCoupons(state) {
    return state.home.coupons;
}

export function getPaymentConfirm(state) {
    return state.home.confirm;
}

export function getDashboard(state) {
    return state.home.dashboard;
}

export function getReportState(state) {
    return state.home.report;
}

export function getHostEventTappedState(state) {
    return state.home.hostEvent;
}

export function getAds(state) {
    return state.home.ads;
}

export function getSeats(state) {
    return state.home.seats;
}

export function getEventId(state) {
    return state.home.eventId;
}
