import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import './CheckoutTicketContent.scss';
import firebase from 'firebase';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as selectors from '../../../../store/home/reducer';
import * as actions from '../../../../store/home/actions';
import AppConfig from '../../../../config/app.config';
import StripeConfig from '../../../../config/stripe.config';
import Utils from '../../../../services/utils';
import { RadioButton } from '../../../compnents';
import CardTypes from './type.card';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class CheckoutTicketContent extends Component {


    constructor(props) {
        super(props);

        let { listDetail, saleItems, eventDetail } = this.props;

        if (!listDetail || !saleItems || !eventDetail) {
            let { params } = this.props.match;
            let { id } = params;
            this.props.history.push(`/event/${id}`);
        }

        if (listDetail) {
            let { Title, CouponCollection, ListId } = listDetail;
            this.props.dispatch(actions.loadCoupons(CouponCollection));
            this.headerItems = [{
                title: 'Home',
                link: '/home',
            }, {
                title: 'All Events',
                link: '/events',
            }, {
                title: Title,
                link: `/event/${ListId}`
            }];
        } else {
            this.headerItems = [{
                title: 'Home',
                link: '/home',
            }, {
                title: 'All Events',
                link: '/events',
            }, {
                title: '',
                link: `/home`
            }];
        }

        let price = 0;
        if (saleItems) {
            saleItems.forEach(item => price += Number(item['ItemValue'] * item['ItemSelected']));
        }

        let { loginInfo } = props;

        this.state = {
            current: 0,
            coupon: '',
            couponApplied: false,
            couponValue: 0,
            couponItem: 'all',
            itemsPrice: price,
            error: '',
            process: 0,
            ticketVat: 0,
            addOnsVat: 0,
            note: '',
            card: CardTypes[0].key,
            name: loginInfo && loginInfo['DisplayName'] ? loginInfo['DisplayName'] : '',
            // phone: loginInfo && loginInfo['PhoneNumber'] ? loginInfo['PhoneNumber'] : '',
        };

        this.cards = [{
            title: 'Credit Card',
            value: 'VISA',
        }, {
            title: 'Debit Card',
            value: 'DEBIT',
        }, {
            title: 'Netbanking',
            value: 'NET',
        }];
    }

    componentDidMount() {
        this.initStripeCard();
    }


    /*-----------------------------------------------------------*/
    // Action Functions

    handleChange(type, value) {
        let state = this.state;
        state[type] = value;
        this.setState(state);
    }

    handleCardChange(index, value) {

        let state = this.state;
        let type = `number${index}`;
        state[type] = value;
        this.setState(state);
    }

    handleApplyCoupon() {
        let { couponApplied, coupon } = this.state;
        let { coupons } = this.props;

        if (couponApplied || !coupon) {
            this.selCoupon = null;
            this.setState({
                couponApplied: false,
                couponValue: 0,
            });
            return;
        }

        let splits = coupon.split('-');
        let couponValue = splits[0].trim();
        let serial = splits.length > 1 ? Number(splits[1]) : null;

        let selCoupon = null;
        for (let i = 0; i < coupons.length; i++) {

            if (coupons[i].Code === couponValue) {
                let coupon = coupons[i];

                if (coupon.CheckSerialNum) {
                    if (serial && coupon.SerialNumStart <= serial && serial <= coupon.SerialNumEnd) {
                        selCoupon = coupons[i];
                        break;
                    }
                } else if (serial == null) {
                    selCoupon = coupons[i];
                    break;
                }
            }
        }

        if (!selCoupon) {
            this.showToast("Invalid Coupon!");
            return;
        }

        let count = this.totalItemCount()
        if (count > selCoupon.TicketMax || selCoupon.CouponUsed + count > selCoupon.CouponCap) {
            this.showToast("Maximum tickets exceeded!")
            return
        }

        let { CouponValue, CouponItem } = selCoupon;

        if (serial) {
            let { listDetail } = this.props;
            let { OrderCollection } = listDetail;

            this.props.dispatch(actions.setLoading(true));

            firebase.firestore().collection(OrderCollection)
                .where('CouponCode', '==', coupon)
                .get()
                .then(snapshot => {
                    let isExist = snapshot.docs.length

                    if (isExist) {
                        this.showToast("Coupon code already used")
                    } else {
                        this.selCoupon = selCoupon
                    }

                    this.setState({
                        couponApplied: true,
                        couponValue: serial,
                        couponItem: CouponItem,
                    })

                    this.props.dispatch(actions.setLoading(false));
                })
                .catch(err => {
                    this.props.dispatch(actions.setLoading(false));
                    console.log("Error on coupon checking", err)
                })
        } else {
            this.selCoupon = selCoupon;
            this.setState({
                couponApplied: true,
                couponValue: CouponValue,
                couponItem: CouponItem,
            })
        }
    }

    handlePay() {
        if (this.state.process > 0 || this.state.loading) {
            return
        }

        let { couponValue } = this.state;

        if (this.totalPrice().toFixed(2) != 0.00) {
            if (!this.state.name || this.state.name.length === 0) {
                this.showToast('Please enter name on the card');
                return
            }
        }

        this.payWithStripe()
    }


    /*-----------------------------------------------------------*/
    // Stripe Payment Functions..

    payWithStripe() {
        this.setState({ error: '' });

        let count = this.totalItemCount();
        if (count === 0) {
            this.showToast("Your cart is empty!");
            return;
        }

        this.setState({ process: 1 });
        this.startProcessing();
    }

    startProcessing() {
        let { listDetail } = this.props;
        let collection = listDetail.OrderCollection;
        if (!collection) collection = 'Payments';

        firebase.firestore()
            .collection(collection)
            .doc('LastOrderNo')
            .get()
            .then(snapshot => {
                let data = snapshot.data();
                if (!data) {
                    return 0;
                } else {
                    return data.OrderNo;
                }
            })
            .then(orderNo => {
                return this.createPaymentObject(orderNo);
            })
            .then(() => {
                let couponValue = 0;
                if (this.selCoupon) {
                    if (this.selCoupon.Value) {
                        couponValue = this.selCoupon.Value;
                    } else {
                        couponValue = this.selCoupon.CouponValue;
                    }
                }
                couponValue = couponValue ? couponValue : 0;

                if (this.totalPrice().toFixed(2) == 0.00) {
                    return 100;
                } else {
                    return this.Stripe.createToken(this.card);
                }
            })
            .then((result) => {
                if (result === 100) {
                    return result;
                } else if (result.error) {
                    this.setState({ process: 0 });
                    // var errorElement = document.getElementById('card-errors');
                    // errorElement.textContent = result.error.message;
                    throw Error(result.error.message);
                } else {
                    return this.createStripePayment(result.token);
                }
            })
            .then(res => {
                if (res === 100) {
                    return { id: '' };
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (!result.id && result.statusCode) {
                    console.log(result);
                    if (result.message) throw Error(result.message);
                    else throw Error("Unable to process the payment");
                }

                this.paymentObject['PaymentType'] = 'stripe';
                this.paymentObject['PaymentNonce'] = result['id'];
                this.paymentObject['Status'] = true;

                let paymentId = this.paymentObject.PaymentId;

                if (!listDetail.GroupTicket) {
                    this.payItems.forEach((payItem, index) => {
                        let id = paymentId + '_' + (index + 1);
                        console.log("payItem", payItem)
                        firebase.firestore().collection(collection).doc(id).set(payItem);
                        console.log("id", id)

                    })
                }
                firebase.firestore().collection('Payments').doc(paymentId).set(this.paymentObject);
                return firebase.firestore().collection(collection).doc(paymentId).set(this.paymentObject);
            })
            .then(() => {
                return firebase.firestore().collection('Users').where("UserId", "==", listDetail.SellerId).get();
            })
            .then(snapshot => {
                this.seller = {};

                if (!snapshot.empty) {
                    this.seller = snapshot.docs[0].data();
                }

                console.log("Seller Info", this.seller);

                let confirm = {
                    id: this.paymentObject.PaymentId,
                    collection: listDetail.OrderCollection,
                    groupTicket: listDetail.GroupTicket,
                    title: listDetail.Title,
                    listId: listDetail.ListId,
                    updated: this.paymentObject.LastUpdated,
                    total: this.paymentObject.PaymentTotal,
                    payItems: this.payItems,
                    userId: this.paymentObject.UserId,
                    buyer: this.paymentObject.BuyerUser.DisplayName,
                    buyerEmail: this.paymentObject.BuyerUser.Email,
                    buyerPhone: this.paymentObject.BuyerUser.PhoneNumber,
                    Logo: listDetail.WebImgUrl,
                    items: this.paymentObject.Items,
                };

                this.props.dispatch(actions.setPaymentConfirm(confirm));

                // Update coupon used
                if (this.selCoupon) {
                    let items = this.totalItemCount();
                    let { CouponCollection } = listDetail;
                    return firebase.firestore().collection(CouponCollection)
                        .doc(this.selCoupon.Code)
                        .update({ CouponUsed: this.selCoupon.CouponUsed + items })
                } else {
                    return null
                }
            })
            .then(() => this.bookSeats())
            .then(() => {
                this.setState({ process: 0 });
                this.showToast("Payment successfully processed")
                let { PaymentId } = this.paymentObject;
                this.props.history.push(`/confirm/${PaymentId}`);
            })
            .catch(error => {
                this.setState({ process: 0 })
                console.log("ERROR", error)
                this.setState({ error: error.toString() });
            })
    }

    createStripePayment(token) {
        let { listDetail } = this.props;
        let stripeURL = StripeConfig[AppConfig.environment].charge_url;
        let user = this.paymentObject.BuyerUser;
        let coupon = this.selCoupon ? this.selCoupon.Code : 'NO COUPON';

        let description =
            'L:' + listDetail['ListId'] +
            '-N:' + user.DisplayName +
            '-E:' + user.Email +
            '-P:' + user.PhoneNumber +
            '-C:' + coupon +
            '-PID:' + this.paymentObject.PaymentId +
            '-U:' + this.paymentObject.UserId +
            '-T:WEB';

        for (let i = 0; i < this.paymentObject.Items.length; i++) {
            let item = this.paymentObject.Items[i];
            // description += ' I:' + item.ItemTitle.split('-')[0];
            description += '-I:' + item.DocId;
            description += '-S:' + item.ItemSelected;
        }

        let metadata = user.uid + '_' + listDetail.ListId + ' ' + user.displayName + ' ' + user.phoneNumber + ' ' + user.email;

        let totalPrice = this.totalPrice();

        let paymentMethod = "live"
        if (AppConfig.environment === 'DEVELOPMENT') {
            paymentMethod  = 'sandbox';
        }

        if(AppConfig.environment === 'PRODUCTION') {
            paymentMethod  = 'live';
        }

        let params = {
            cardToken: token.id,
            amount: totalPrice * 100,
            description: description,
            metadata: metadata,
            paymentMethod: paymentMethod,
            paymentId: this.paymentObject.PaymentId,
            listid: listDetail['ListId'],
        }

        console.log("Payment method", paymentMethod)

        return fetch(stripeURL, {
            method: 'POST',
            body: JSON.stringify(params),
        });
    }

    createPaymentObject(orderNo) {
        let { listDetail, eventDetail, saleItems, loginInfo } = this.props;

        let user = firebase.auth().currentUser;
        let id = user ? user.uid.substring(0, 4) : 'GUEST';
        let time = Utils.getTimeId(new Date());
        time = time.replace(/:/g, '.');
        let paymentId = id + "." + time;

        let items = [];
        saleItems.forEach(selItem => {
            let included = selItem.GSTIncluded ? selItem.GSTIncluded : false;
            items.push({
                ...selItem,
                GSTIncluded: included,
            });
        })

        this.qrCode = paymentId;

        let totalPrice = this.totalPrice();
        let { name, phone } = this.state;

        let userInfo = {
            ...loginInfo,
        }

        userInfo['DisplayName'] = name;

        if (!Utils.isEmptyString(phone)) {
            userInfo['PhoneNumber'] = phone;
        }

        this.paymentObject = {
            PaymentId: paymentId,
            PaymentNonce: '',
            ConfEmailSend: false,
            ListId: listDetail.ListId,
            ListTitle: listDetail.Title,
            ListSubTitle: listDetail.SubTitle,
            PaymentType: '',
            PaymentAddress: '',
            PaymentTotal: totalPrice,
            PaymentChannel: 'Web',
            UserId: user ? user.uid : 'GUEST',
            Notes: this.state.note,
            //NoteSeller: this.state.note,
            ShowNotes: eventDetail['ShowNotes'],
            SellerId: listDetail.SellerId,
            OrderNo: this.GenerateOrder ? orderNo : '',
            BuyerUser: userInfo,
            CouponCode: this.selCoupon ? this.state.coupon : '',
            CouponValue: this.selCoupon ? this.state.couponValue : '',
            Status: false,
            Msg: '',
            LastUpdated: new Date(),
            Items: items,
            Stripe: listDetail.Stripe ? listDetail.Stripe : '',
            Logo: listDetail.WebImgUrl,
            GroupTicket: listDetail.GroupTicket ? listDetail.GroupTicket : false,
        }

        this.payItems = [];

        let idx = 0;

        items.forEach(item => {
            for (let i = 0; i < item.ItemSelected; i++) {
                idx = idx + 1;

                let paymentTotal = item.ItemValue;
                if (this.selCoupon) {
                    let items = this.selCoupon['Items'];
                    if (!items) items = [];
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].type === item.DocId && items[i].checked) {
                            paymentTotal = item.ItemValue - this.selCoupon['CouponValue'];
                            break;
                        }
                    }

                    if (paymentTotal < 0) paymentTotal = 0;
                }

                this.payItems.push({
                    ...item,
                    SelectedSeat: listDetail.SeatSelection ? item.SelectedSeats[i] : '',
                    SeatSelection: listDetail.SeatSelection ? true : false,
                    ItemSelected: 1,
                    ListId: listDetail.ListId,
                    ListTitle: listDetail.Title,
                    BuyerUser: userInfo,
                    PaymentId: `${paymentId}_${idx}`,
                    PaymentTicketId: paymentId,
                    TicketNumber: `${i + 1}`,
                    SellerId: listDetail.SellerId,
                    CouponCode: this.selCoupon ? this.state.coupon : '',
                    CouponValue: this.selCoupon ? this.state.couponValue : '',
                    LastUpdated: new Date(),
                    Notes: this.state.note,
                    ShowNotes: eventDetail['ShowNotes'],
                    LastUpdatedStr: new Date().toISOString(),
                    PaymentTotal: paymentTotal,
                    Logo: listDetail.WebImgUrl,
                })
            }
        })

        let info = null;
        if (!loginInfo.DisplayName || loginInfo.DisplayName.length === 0) {
            if (user && !Utils.isEmptyString(this.state.name)) {
                info = { DisplayName: this.state.name };
            }
        }

        if (!loginInfo.PhoneNumber || loginInfo.PhoneNumber.length === 0) {
            if (!Utils.isEmptyString(this.state.phone)) {
                if (info != null) info = { ...info, PhoneNumber: this.state.phone };
                else info = { PhoneNumber: this.state.phone };
            }
        }

        if (info != null) firebase.firestore().collection('Users').doc(user.uid).update(info);

        console.log("PaymentObject", this.paymentObject, this.payItems);
        console.log("Login Info", userInfo);
        console.log("User Info to update", info);
    }

    bookSeats() {
        let { saleItems } = this.props;
        let items = saleItems[0]['SelectedSeats'].map(selItem => {
            return { objectId: selItem };
        })

        let { SeatsEventId } = saleItems[0];

        if (!SeatsEventId) return;

        let params = { objects: items }
        let bookURL = `https://api.seatsio.net/events/${SeatsEventId}/actions/book`;
        const encodedString = new Buffer('6efbcc68-1dae-40e8-a250-87f296217575:').toString('base64');

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + encodedString);

        return fetch(bookURL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params),
        });

    }


    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }

    totalItemCount() {
        let { saleItems } = this.props;
        let count = 0;

        if (saleItems) {
            saleItems.map(item => count += Number(item['ItemSelected']));
        }

        return count;
    }

    totalPrice() {
        let { itemsPrice, addOnsVat, ticketVat } = this.state;
        let totalAdd = addOnsVat + ticketVat;
        let totalPrice = itemsPrice * (100 + totalAdd) / 100;

        let discount = this.discountFromCoupon();

        return totalPrice - discount;
    }

    initStripeCard() {
        let stripeKey = StripeConfig[AppConfig.environment].key

        let { listDetail } = this.props;
        let stripe = listDetail ? listDetail['Stripe'] : null

        if (AppConfig.environment === 'DEVELOPMENT' && listDetail && stripe && stripe.SPS) {
            stripeKey = stripe.SPS
        } else if (AppConfig.environment === 'PRODUCTION' && listDetail && stripe && stripe.SPL) {
            stripeKey = stripe.SPL
        }

        this.Stripe = window.Stripe(stripeKey)

        var elements = this.Stripe.elements()

        var style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }

        this.card = elements.create('card', {
            hidePostalCode: true,
            style: style
        })
        this.card.mount('#card-element')

        this.card.addEventListener('change', (event) => {
            var displayError = document.getElementById('card-errors')
            if (event.error) {
                displayError.textContent = event.error.message
            } else {
                displayError.textContent = ''
            }
        })
    }

    discountFromCoupon() {
        let { saleItems } = this.props;

        if (!this.selCoupon) return 0;

        let couponItems = this.selCoupon['Items'];
        if (!couponItems) {
            let { itemsPrice, couponValue } = this.state;
            let discountPrice = itemsPrice / 100 * couponValue;
            return discountPrice;
        }

        let discount = 0;

        console.log("Coupon Discount", saleItems, this.selCoupon);

        for (let i = 0; i < saleItems.length; i++) {
            let item = saleItems[i];

            for (let j = 0; j < couponItems.length; j++) {
                let coupon = couponItems[j];
                if (item.DocId === coupon.type && coupon.checked) {
                    let value = this.selCoupon['CouponValue'] > item.ItemValue ? item.ItemValue : this.selCoupon['CouponValue'];
                    discount += value * item.ItemSelected;
                }
            }
        }

        return discount;
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { loading, saleItems, loginInfo, eventDetail, listDetail } = this.props;
        listDetail = listDetail ? listDetail : {};
        eventDetail = eventDetail ? eventDetail : {};
        let { DisplayCoupon } = listDetail;
        let { ShowNotes } = eventDetail;
        var { showFood } = false;
        var { innisaiVIP } = false;
        if (listDetail.ListId === 'innisai') {
            showFood = true
        }
        // For coupon code..
        let { couponApplied, couponValue, coupon, error } = this.state;
        let discountPrice = this.discountFromCoupon();
        // console.log("Coupon Discount", discountPrice);

        let headerItems = [...this.headerItems, { title: 'CheckOut', link: '' }];


        return (
            <div className="checkout-content-container">

                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="checkout-header">
                    <img src={require('../../../../assets/images/ic_home.png')} alt="HOME-ICON" />
                    <div className="path">
                        {
                            headerItems.map((item, index) => {

                                let style = {};
                                if (index === headerItems.length - 1) {
                                    style = { color: '#be2927' }
                                }

                                if (index === 0) {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <Link to={item.link}>{item.title}</Link>
                                        </div>
                                    )
                                } else if (index === headerItems.length - 1) {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <span style={{ marginRight: 10, color: 'black' }} className="fa fa-angle-right" />
                                            {item.title}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <span style={{ marginRight: 10, color: 'black' }} className="fa fa-angle-right" />
                                            <Link to={item.link}>{item.title}</Link>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>

                <div className="checkout-content">
                    <div className="payment-method">
                        <div className="panel-title">SELECT PAYMENT METHOD</div>

                        <div className="method-content" style={{ flex: 1 }}>
                            {
                                this.state.process === 1 &&
                                <div className="loading-view">
                                    <div>Payment is being processing...</div>
                                    <div style={{ display: 'inline-block' }}><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>
                                </div>
                            }

                            <div className="content-panel">
                                <div className="block">
                                    <div className="block-title">Breakdown</div>
                                    {!saleItems && <div className="block-item">NO ITEM TO BUY!</div>}
                                    {
                                        saleItems && saleItems.map((saleItem, index) => {
                                            let amount = Number(saleItem['ItemValue']) * Number(saleItem['ItemSelected']);
                                            return (
                                                <div key={index} className="block-item">
                                                    <div className="item-left">
                                                        <span className="fa fa-circle" />
                                                        {saleItem['ItemSelected']} X {saleItem['ItemTitle']}
                                                    </div>
                                                    <div className="item-right">${amount.toFixed(2)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                                {
                                    ShowNotes &&
                                    <div className="block" style={{ borderBottom: 'none', marginTop: 10 }}>

                                        <div> </div>
                                        <div> </div>


                                        {showFood && <div className="item-left">For VIP & Gold tickets, please mention your food preference veg or non-veg below</div>}


                                        {!showFood && <div className="block-title">Notes to seller</div>}


                                        <div className="row">
                                            <textarea
                                                style={{ margin: 0, marginRight: 10 }}
                                                onChange={evt => this.handleChange('note', evt.target.value)} />
                                        </div>
                                    </div>
                                }

                                {
                                    DisplayCoupon &&
                                    <div className="block" style={{ borderBottom: 'none', marginTop: 10 }}>
                                        <div className="block-title">Discount</div>
                                        {
                                            couponApplied &&
                                            <div className="block-item">
                                                <div className="item-left"><span className="fa fa-circle" /> Discount applied</div>
                                                <div className="item-right">-${discountPrice.toFixed(2)}</div>
                                            </div>
                                        }

                                        <div className="row" style={{ height: 40 }}>
                                            {
                                                !couponApplied ?
                                                    <input
                                                        style={{ margin: 0, marginRight: 10 }}
                                                        placeholder="Coupon code"
                                                        value={coupon}
                                                        onChange={evt => this.handleChange('coupon', evt.target.value)} /> :
                                                    <div style={{ flex: 1 }} />
                                            }
                                            <div className="button" onClick={() => this.handleApplyCoupon()}>{couponApplied ? 'REMOVE' : 'APPLY'}</div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="content-panel card-info">
                                {
                                    this.totalPrice().toFixed(2) != 0.00 &&
                                    <div>
                                        <div className="block-title">Card Type</div>

                                        <div className="row card-name">
                                            {
                                                CardTypes.map((card, index) => {
                                                    return (
                                                        <RadioButton key={index} style={{ marginRight: 20 }}
                                                            title={card.title}
                                                            checked={this.state.card === card.key}
                                                            onClick={() => this.setState({ card: card.key })} />
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className="block-title">Card Details</div>

                                        <div className="row card-name">
                                            <input placeholder="Name on card"
                                                value={this.state.name}
                                                onChange={evt => this.handleChange('name', evt.target.value)} />
                                        </div>

                                        <div className="row card-name">
                                            {/* <input placeholder="Phone number"
                                            value={this.state.phone}
                                            onChange={evt => this.handleChange('phone', evt.target.value)} maxlength="12"/> */}
                                            <PhoneInput inputProps={{ name: 'phone', required: true, autoFocus: true }}
                                                country="au" onlyCountries={['au']} preserveOrder={['onlyCountries']}
                                                containerClass={'phone-input-container'} inputClass={'phone-input'} autoFormat={false}
                                                placeholder={''} onChange={evt => this.handleChange('phone', evt)} />
                                        </div>

                                        <div className="card" style={{ display: couponValue === 100 ? 'none' : 'block' }}>
                                            <label htmlFor="card-element"></label>
                                            <div id="card-element"></div>
                                            <div id="card-errors" role="alert"></div>
                                        </div>

                                        {error && error.length > 0 && <div className="error">{error}</div>}
                                    </div>
                                }

                                {showFood && <div className="block-title">For Silver & General tickets, you can pre-order your food on the next screen</div>}
                                {showFood && <img src="https://storage.googleapis.com/ibazaarprod.appspot.com/images/lists/Innisai/preorder.jpeg" alt="Order Food" width="350px" />}
                            </div>
                        </div>

                        <div className="method-content">
                            <div className="content-panel">
                                <div className="block-item">
                                    <div className="item-left"><span className="fa fa-circle" /> Total Payable</div>
                                    <div className="item-right">${this.totalPrice().toFixed(2)}</div>
                                </div>
                            </div>

                            <div className="content-panel row" style={{ borderLeft: '1px solid #DDD' }}>
                                <div className="flex-full"></div>
                                <div className="button" onClick={() => this.handlePay()}>
                                    {this.totalPrice().toFixed(2) == 0.00 ? 'Book Now' : this.state.process === 1 ? 'Processing..' : 'Pay Now'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="payment-summary">
                        <div className="panel-title">SUMMARY</div>
                        {
                            loginInfo && loginInfo['DisplayName'] && loginInfo['DisplayName'].length > 0 &&
                            <div className="content-panel block">
                                <div className="title"><span className="fa fa-check-circle" /> Name</div>
                                <div className="item">{loginInfo && loginInfo['DisplayName']}</div>
                            </div>
                        }
                        {
                            loginInfo && loginInfo['Email'] && loginInfo['Email'].length > 0 &&
                            <div className="content-panel block">
                                <div className="title"><span className="fa fa-check-circle" /> Email</div>
                                <div className="item">{loginInfo && loginInfo['Email']}</div>
                            </div>
                        }
                        {
                            (this.state.phone && this.state.phone.length) > 0 &&
                            <div className="content-panel block">
                                <div className="title"><span className="fa fa-check-circle" /> Phone</div>
                                <div className="item">{loginInfo && loginInfo['PhoneNumber']}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        loginState: selectors.getLoginState(state),
        loginInfo: selectors.getLoginInfo(state),
        bookEvent: selectors.getBookEvent(state),
        listDetail: selectors.getListDetail(state),
        eventDetail: selectors.getEventDetail(state),
        saleItems: selectors.getSaleItems(state),
        coupons: selectors.getCoupons(state),
        seats: selectors.getSeats(state),
    }
}

export default connect(mapStateToProps)(withRouter(CheckoutTicketContent));
