import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './AllEventsContent.scss';
// import Utils from '../../../../services/utils';

import { connect } from 'react-redux';
import * as selectors from '../../../../store/home/reducer';


class AllEventsContent extends Component {

    /*-----------------------------------------------------------*/
    // Action Functions

    handleBookTicket(event) {
        console.log("Event to book", event)
        let listId = event['ListId'];
        this.props.history.push(`/event/${listId}`)
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { events } = this.props;

        return (
            <div className="event-content-container">
                <div className="event-content">
                    <div className="main-title">ALL EVENTS</div>
                    <div className="event-list">
                        {
                            events.map((event, idx) => {
                                console.log(event['EventDate'])
                                return (
                                    <div key={idx} className="event-item" onClick={() => this.handleBookTicket(event)}>
                                        <div className="image-panel">
                                            <img src={event.MobileImgUrl} alt="IMG-PLACEHOLDER" />
                                            <div className="action-pane">
                                                <div className="event-title">{event.Title}</div>
                                                <div className="row flex-center">
                                                    <div className="event-time">
                                                        {event['EventDate']}
                                                        {/* {Utils.getTimeStringOf(event['EventDate'])}
                                                    <span className="fa fa-circle" />
                                                    {Utils.getDateStringOf(event['EventDate'])} */}
                                                    </div>
                                                </div>

                                                <div className="button book-button">Book Tickets</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        loginState: selectors.getLoginState(state),
        events: selectors.getAllEvents(state),
    }
}

export default connect(mapStateToProps)(withRouter(AllEventsContent));
