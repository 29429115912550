import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './ReportContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
// import { Chart }                from 'react-charts';


class ReportContent extends Component {

    /*-----------------------------------------------------------*/
    // Action Functions

    handleDownload() {
        // Print as PDF
    }

    handleClose() {
        this.props.dispatch(actions.setShowReport(false));
    }

    
    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { reportState } = this.props;

        return (
            <div className="report-dialog" style={{display: reportState ? 'flex' : 'none'}}>
                
                <div className="report-content">

                    <div className="report-title">
                        <div className="title">Report</div>
                        {/* <span className="fa fa-close" /> */}
                        <img src={require('../../../../assets/images/ic_close_dark_gray.png')} 
                            onClick={() => this.handleClose()} alt="LOGO-IMG" />
                    </div>

                    <div style={{flex: 1, padding: 20}}>

                        {/* <Chart
                            data={[{
                                label: "Series 1",
                                data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]],
                                type: 'area',
                            }, {
                                label: "Series 2",
                                data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]],
                                type: 'area',
                            }]}
                            series={{type: 'area'}}
                            axes={[
                                { primary: true, type: "linear", position: "bottom" },
                                { type: "linear", position: "left" }
                            ]} /> */}
                    </div>

                    <div className="action-pane">
                        <div style={{flex: 1}} />
                        <div className="button download-button" 
                            onClick={() => this.handleDownload()}>Download as PDF</div>
                    </div>

                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        reportState:    selectors.getShowReport(state),
    }
}

export default connect(mapStateToProps)(withRouter(ReportContent));
