import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import './MainView.scss';

import { connect } from 'react-redux';
import * as selectors from '../../../store/home/reducer';
import * as actions from '../../../store/home/actions';
import RouteConfig from './route.config';

import Utils from '../../../services/utils';
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import Login from './Login/Login';
import HomeContent from './HomeContent';
import AllEventsContent from './AllEventsContent';
import AboutContent from './AboutContent';
import BookTicketContent from './BookTicketContent';
import CheckoutTicketContent from './CheckoutTicketContent';
import ConfirmCheckoutContent from './ConfirmCheckoutContent';
import ContactUsContent from './ContactUsContent';
import EmptyContent from './EmptyContent';
import SeatMapContent from './SeatMapContent';
import TermsContent from './TermsContent';
import BookHistoryContent from './BookHistoryContent';
import TicketContent from './TicketContent/TicketContent';
import PastEventsContent from './PastEventsContent/PastEventsContent';
import FAQContent from './FAQContent/FAQContent';


class MainView extends Component {

    /*-----------------------------------------------------------*/
    // Initialize

    constructor(props) {
        super(props);

        let url = props.location.pathname.toLowerCase();
        let isMatched = Utils.nestedUrlMatch(url, RouteConfig);
        if (!isMatched) this.props.history.push('/home');
    }

    componentDidMount() {
        // Load categories and subcategories
        this.props.dispatch(actions.loadCategories());
        this.props.dispatch(actions.loadSubCategories(() => this.loadEvents()));

        if (this.props.location && this.props.location.search === '?mode=select') {
            let flag = localStorage.getItem('login_flag');
            if (flag) this.props.dispatch(actions.setLoginState(true));
        }
    }

    loadEvents() {
        this.props.dispatch(actions.loadEvents());
        this.props.dispatch(actions.loadAllEvents());
        this.props.dispatch(actions.loadPastEvents());
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        return (
            <div className="App-Content">

                <MainHeader {...this.props} />

                <div className="main-content">

                    <Switch>

                        <Route exact path="/home" render={(props) => <HomeContent {...props} />} />

                        <Route exact path="/events" render={(props) => <AllEventsContent {...props} />} />
                        <Route exact path="/event/:id" render={(props) => <BookTicketContent {...props} />} />
                        <Route exact path="/event/:id/seatmap" render={(props) => <SeatMapContent {...props} />} />
                        <Route exact path="/event/:id/checkout" render={(props) => <CheckoutTicketContent {...props} />} />
                        <Route exact path="/confirm/:id" render={(props) => <ConfirmCheckoutContent {...props} />} />

                        <Route exact path="/past" render={(props) => <PastEventsContent {...props} />} />
                        <Route exact path="/faq" render={(props) => <FAQContent {...props} />} />

                        <Route exact path="/order/:listId/:id/:index" render={(props) => <TicketContent {...props} />} />
                        <Route exact path="/order/:listId/:id" render={(props) => <TicketContent {...props} />} />

                        <Route exact path="/about" render={(props) => <AboutContent {...props} />} />
                        <Route exact path="/contact" render={(props) => <ContactUsContent {...props} />} />
                        <Route exact path="/sitemap" render={(props) => <EmptyContent {...props} />} />
                        <Route exact path="/privacy" render={(props) => <EmptyContent {...props} />} />
                        <Route exact path="/terms" render={(props) => <TermsContent {...props} />} />
                        <Route exact path="/mytickets" render={(props) => <BookHistoryContent {...props} />} />

                    </Switch>

                    <MainFooter {...this.props} />

                </div>

                <Login />

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        loginState: selectors.getLoginState(state),
    }
}

export default connect(mapStateToProps)(withRouter(MainView));
