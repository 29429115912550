import firebaseConfig  from '../config/firebase.config'
import firebase from 'firebase/app' 

require("firebase/firestore")

firebase.initializeApp(firebaseConfig)
const firAuth = firebase.auth()
const firestore = firebase.firestore()

export default firebase
export {firAuth, firestore}
