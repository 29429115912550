import React, { Component } from 'react'
import './InputForm.scss'

class InputForm extends Component {

    constructor(props) {
        super(props)

        if(this.props.value) {
            this.state = {
                value: this.props.value
            }
        } else if(this.props.defaultValue) {
            this.state = {
                value: this.props.defaultValue
            }
        } else {
            this.state = {
                value: '',
            }    
        }
    }

    onChange(evt) {
        if(this.props.onChange) {
            this.props.onChange(evt.target.value)
        }

        this.setState({value: evt.target.value})
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.value !== this.state.value) {
            this.setState({value: nextProps.value})
        }
    }

    styleFor(style, defStyle) {
        let actualStyle = {}
        if(!style) {
            actualStyle = defStyle
        } else {
            actualStyle = {
                ...defStyle,
                ...style,
            }
        }

        return actualStyle
    }

    render() {
        let {style, label, description, placeholder, labelStyle, inputStyle, disabled} = this.props

        // console.log(label, disabled)

        style = this.styleFor(style, {})
        labelStyle = this.styleFor(labelStyle, {flex: 1, marginRight: 10})
        inputStyle = this.styleFor(inputStyle, {flex: 4})

        return (
            <div style={style} className="input-form">
                <div style={labelStyle}>
                    <div style={{fontSize: 14}}>{label}</div>
                    { description && <div style={{fontSize: 11, color: '#AAA'}}>{description}</div> }
                </div>

                {
                    this.props.children ? 
                        this.props.children : 
                        <input style={inputStyle} 
                            disabled={disabled}
                            className="form-control" 
                            value={this.state.value} 
                            placeholder={placeholder}
                            onChange={evt => this.onChange(evt)} />
                }
            </div>
        )
    }
}

export default InputForm
