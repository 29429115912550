import React, { Component } from 'react'
import './InputForm.css'

class InputForm extends Component {

    onChange(evt) {
        if(this.props.onChange) {
            this.props.onChange(evt.target.value)
        }
    }

    render() {

        let {style, label, defaultValue} = this.props
        if(!style) style = {}

        return (
            <div style={style} className="input-form">
                <div style={{fontSize: 16, flex: 1}}>{label}</div>
                <input style={{flex: 4}} className="form-control" defaultValue={defaultValue} onChange={evt => this.onChange(evt)} />
            </div>
        )
    }
}

export default InputForm
