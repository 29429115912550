const PaymentListHeader = [{
    title: 'Payment ID',
    key: 'PaymentId',
    type: 'text',
}, {
    title: 'Name',
    key: 'DisplayName',
    type: 'text',
}, {
    title: 'Email',
    key: 'Email',
    type: 'text',
}, {
    title: 'Phone',
    key: 'PhoneNumber',
    type: 'text',
}, {
    title: 'Payment Result',
    key: 'Status',
    type: 'boolean',
}, {
    title: 'Coupon Code',
    key: 'CouponCode',
    type: 'text',
}, {
    title: 'Notes',
    key: 'Notes',
    type: 'text',
}, {
    title: 'Date',
    key: 'Date',
    type: 'text',
}, {
    title: 'Amount e.g =30 <30 >30',
    key: 'PaymentTotal',
    type: 'number',
}, {
    title: 'Notes to seller',
    key: 'Notes',
    type: 'items',
}, {
    title: 'Items',
    key: 'items',
    type: 'items',
}, {
    title: 'Order Status',
    key: 'items',
    type: 'items-status',
}];

export default PaymentListHeader;