const CardTypes = [{
    title: 'Master Card',
    key: 'master',
}, {
    title: 'Visa',
    key: 'visa',
}, {
    title: 'Other',
    key: 'other',
}];

export default CardTypes;