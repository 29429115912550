import * as types from './actionTypes';
import * as selectors from './reducer';
import firebase, { firestore } from '../../services/firebase.service';


export function setLoading(value) {

    return (dispatch) => {
        dispatch({ type: types.LOADING_SET, value: value });
    }

}

export function setError(value) {

    return (dispatch) => {
        dispatch({ type: types.ERROR_SET, value: value });
    }

}

export function setAuthChangedState(value) {

    return (dispatch) => {
        dispatch({ type: types.AUTH_CHANGED_STATE_SET, value: value });
    }

}

export function setHostEventTapped(value) {

    return (dispatch) => {
        dispatch({ type: types.HOST_EVENT_TAP_SET, value: value });
    }

}

export function setTryLogin(value) {

    return (dispatch) => {
        dispatch({ type: types.TRY_LOGIN_SET, value: value });
    }

}

export function setUserInfo(value) {

    return (dispatch) => {
        dispatch({ type: types.USER_INFO_SET, value: value });
    }

}

export function setShowReport(value) {

    return (dispatch) => {
        dispatch({ type: types.SHOW_REPORT_SET, value: value });
    }

}

export function loadHostedEventsWithDetails() {

    return (dispatch, getState) => {

        let userInfo = selectors.getUserInfo(getState());
        dispatch({ type: types.LOADING_SET, value: true });

        firestore.collection('Lists')
            .where('AdminIds', 'array-contains' ,userInfo['UserId'])
            .get()
            .then(snapshot => {
                let items = snapshot.docs.map(doc => doc.data())
                console.log("Loaded Hosted Events", items);
                dispatch({ type: types.HOSTED_EVENTS_SET, value: items });
                dispatch({ type: types.LOADING_SET, value: false });

                items.forEach(item => dispatch(loadEventDetails(item['ListId'])));
            })
            .catch(error => {
                dispatch({ type: types.LOADING_SET, value: false });
                console.log("Error on load hosted event", error)
            })
    }
}

function loadEventDetails(listId) {

    return (dispatch) => {

        firestore.collection('Payments')
            .where('ListId', '==', listId)
            .where('Status', '==', true)
            .get()
            .then(snapshot => {
                let items = snapshot.docs.map(doc => doc.data())
                console.log("Loaded hosted event content", items)
                dispatch({ type: types.DASHBOARD_CONTENT_SET, event: listId, value: items });
            })
            .catch(error => {
                console.log("Error on load hosted event content", error)
                dispatch({ type: types.DASHBOARD_CONTENT_SET, event: listId, value: null });
            })
    }
}

export function loadUser() {

    return (dispatch) => {

        let user = firebase.auth().currentUser;
        firestore.collection('Users').doc(user.uid).get()
            .then(snapshot => {
                let data = snapshot.data();

                if(data) {
                    dispatch({ type: types.USER_INFO_SET, value: data });
                    dispatch({ type: types.AUTH_CHANGED_STATE_SET, value: true });
                }
            })
            .catch(error => {
                console.log("Error on load user", error)
                dispatch({ type: types.AUTH_CHANGED_STATE_SET, value: true });
            });
    }
}

export function loadCoupons() {

    return (dispatch, getState) => {

        let userInfo = selectors.getUserInfo(getState());
        let { Coupons } = userInfo;

        if(!Coupons) {
            dispatch({ type: types.ERROR_SET, value: 'You does not have coupon collection!' });
            return;
        }

        dispatch({ type: types.LOADING_SET, value: true });

        firestore.collection(Coupons)
            .get()
            .then(snapshot => {
                let items = snapshot.docs.map(doc => doc.data())
                console.log("Loaded coupons", items)
                dispatch({ type: types.COUPONS_SET, value: items });
                dispatch({ type: types.LOADING_SET, value: false });
            })
            .catch(error => {
                console.log("Error on load coupons", error)
                dispatch({ type: types.COUPONS_SET, value: null });
                dispatch({ type: types.ERROR_SET, value: 'Failed to load coupons!' });
                dispatch({ type: types.LOADING_SET, value: false });
            })
    }
}
export function signOut() {

    return (dispatch) => {

        firebase.auth().signOut();
        localStorage.clear();
        dispatch({ type: types.USER_INFO_SET, value: null });
        
    }
}
