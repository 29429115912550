import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import './BookTicketContent.scss';

import AppConfig                from '../../../../config/app.config';
import { NumericSpinner }       from '../../../compnents';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';
import * as actions             from '../../../../store/home/actions';
import Utils                    from '../../../../services/utils';
import { toast }                from 'react-toastify';
import firebase                 from '../../../../services/firebase.service';


class BookTicketContent extends Component {

    headers = ['HOME', 'BUY TICKETS'];

    shareItems = [{
        icon: require('../../../../assets/images/facebook-round@3x.png'),
        link: AppConfig[AppConfig.environment].facebook,
    }, {
        icon: require('../../../../assets/images/twitter-round@3x.png'),
        link: AppConfig[AppConfig.environment].twitter,
    }, {
        icon: require('../../../../assets/images/instagram-round-link.png'),
        link: AppConfig[AppConfig.environment].instagram,
    }, {
        icon: require('../../../../assets/images/link.png'),
        link: AppConfig[AppConfig.environment].facebook,
    }];

    state = {
        tabIndex: 0,
        move: false,
    };

    constructor(props) {
        super(props)
        let listId = props.match.params['id'];
        this.props.dispatch(actions.loadListDetail(listId, result => this.refreshView(result)));
        this.props.dispatch(actions.loadEventDetail(listId, result => this.reloadItems(result)));

        this.dataRef = [];
        this.saleItems = [];
        this.detailItems = [];
        this.detailHeaders = [];

        this.headerItems = [{
            title: 'Home',
            link: '/home',
        } , {
            title: 'All Events',
            link: '/events',
        }];
    }

    refreshView(result) {
        if(!result) {
            this.props.history.push('/');
        }
    }

    reloadItems(result) {
        if(!result) {
            this.props.history.push('/');
            return;
        }

        let { listDetail, eventDetail } = this.props;
        eventDetail = eventDetail ? eventDetail : {};

        let eventItems = [...eventDetail['Items']];

        let items = this.sortItemBySection(eventItems);
        this.saleItems = Utils.findNodes(items, 'ItemType', 'SALE');
        this.detailItems = Utils.findNodes(items, 'ItemType', 'SALE', true);

        this.saleItems = this.saleItems.map(item => {
            return {
                ...item,
                SelectedSeats: [],
            }
        });

        this.detailHeaders = this.headerMenu(this.detailItems);

        let { Visit, ListId } = listDetail;
        if(!Visit) Visit = 1;
        else Visit += 1;

        firebase.firestore().collection('Lists').doc(ListId).update({Visit})
        .then(result => console.log("Visit Increase Result", result))
        .catch(error => console.log("Error Visit Increase", error));

        this.forceUpdate();
    }

    componentWillReceiveProps(nextProps) {
        let { loginInfo } = nextProps;

        if(loginInfo && this.state.move) {
            let { listDetail } = this.props;

            console.log("MOVE TO SEAT")

            if(this.book) {
                this.props.history.push(`/event/${this.SeatsEventId}/seatmap`);
            } else {
                let { ListId } = listDetail;
                this.props.history.push(`/event/${ListId}/checkout`);
            }
        }
    }

    /*-----------------------------------------------------------*/
    // Action Functions

    handleHeaderItem(index) {
        this.setState({tabIndex: index});

        let ref;

        if(index === 0)         { ref = this.homeRef }
        else if(index === 1)    { ref = this.ticketRef }
        else                    { ref = this.dataRef[index - 2] }

        ref.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }

    handleNext() {
        let { loginInfo, listDetail } = this.props;

        // Move to next
        let bNext = false;
        let saleItems = [];

        for(let i = 0; i < this.saleItems.length; i++) {
            let saleItem = this.saleItems[i];
            if(saleItem['ItemSelected'] > 0) {
                bNext = true;
                saleItems.push({
                    ...saleItem,
                });
            }
        }

        console.log("Sale Items", saleItems);

        if(!bNext) {
            this.showToast("Please select at least one ticket!");
            return;
        }

        this.props.dispatch(actions.setSaleItems(saleItems));

        this.book = false;

        if(!loginInfo) {
            this.setState({move: true});
            this.props.dispatch(actions.setLoginState(true));
        } else {
            let { ListId } = listDetail;
            this.props.history.push(`/event/${ListId}/checkout`);
        }
    }

    handleChangeTicket(index, value) {
        this.saleItems[index] = {
            ...this.saleItems[index],
            ItemSelected: value,
        };
    }

    handleBook(event) {
        if(event.SoldOut) { return }

        let { Seats } = event;
        this.props.dispatch(actions.setSeats(Seats));
        this.props.dispatch(actions.setEventId(event));

        let { loginInfo } = this.props;

        let { SeatsEventId } = event;

        if(!loginInfo) {
            this.book = true;
            this.SeatsEventId = SeatsEventId;
            this.setState({move: true});
            this.props.dispatch(actions.setLoginState(true));
        } else {
            this.props.history.push(`/event/${SeatsEventId}/seatmap`);
        }
    }


    /*-----------------------------------------------------------*/
    // General Functions

    sortItemBySection(items) {
        if(!items) return [];

        let sortItems = [...items];

        for(let i = 0; i < sortItems.length - 1; i++) {
            for(let j = i + 1; j < sortItems.length; j++) {
                let itemA = sortItems[i];
                let secOrderA = itemA['SectionOrder'];
                let itemB = sortItems[j];
                let secOrderB = itemB['SectionOrder'];

                if(secOrderA > secOrderB) {
                    let item = sortItems[i];
                    sortItems[i] = sortItems[j];
                    sortItems[j] = item;
                }
            }
        }

        return sortItems;
    }

    headerMenu(additionalItems) {
        let headers = [...this.headers];
        additionalItems.forEach(item => {
            let title = this.sectionTitle(item['SectionOrder']);
            headers.push(title.toUpperCase());
        })

        return headers;
    }

    sectionTitle(sectionOrder) {
        let { eventDetail } = this.props;
        if(!eventDetail) return '';

        let sections = eventDetail['Sections'];
        if(!sections) return '';

        let section = sections[sectionOrder];
        if(!section) return '';

        let title = section['SectionTitle'];
        if(!title) return '';

        return title;
    }


    showToast(text) {
        toast.error(text);
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { loading, listDetail, eventDetail } = this.props;
        listDetail = listDetail ? listDetail : {};
        eventDetail = eventDetail ? eventDetail : {};
        let { SeatSelection, ListId } = listDetail;
        let { Title, SubTitle, WebImgUrl } = eventDetail;
        if(!WebImgUrl) WebImgUrl = listDetail['WebImgUrl'];
        let headerItems = [...this.headerItems, {title: Title, link: `/event/${ListId}`}];

        return (
            <div className="book-content-container">

                <div className="panel book-header">
                {
                    this.detailHeaders.map((header, index) => {
                        let clsName = "menu-item "
                        let { tabIndex } = this.state
                        if(index === tabIndex) {
                            clsName += 'active'
                        }

                        return (
                            <div key={index} className={clsName}
                                onClick={() => this.handleHeaderItem(index)}>{header}</div>
                        )
                    })
                }
                </div>

                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }


                <div className="book-nav-header">
                    <img src={require('../../../../assets/images/ic_home.png')} alt="HOME-ICON" />
                    <div className="path">
                    {
                        headerItems.map((item, index) => {

                            let style = {};
                            if(index === headerItems.length - 1) {
                                style = {color: '#be2927'}
                            }

                            if(index === 0) {
                                return (
                                    <div key={index} style={{marginRight: 10, ...style}}>
                                        <Link to={item.link}>{item.title}</Link>
                                    </div>
                                )
                            } else if (index === headerItems.length - 1) {
                                return (
                                    <div key={index} style={{marginRight: 10, ...style}}>
                                        <span style={{marginRight: 10, color: 'black'}} className="fa fa-angle-right"/>
                                        {item.title}
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} style={{marginRight: 10, ...style}}>
                                        <span style={{marginRight: 10, color: 'black'}} className="fa fa-angle-right"/>
                                        <Link to={item.link}>{item.title}</Link>
                                    </div>
                                )
                            }
                        })
                    }
                    </div>
                </div>

                <div className="book-content">

                    <div className="banner-img" ref={ref => this.homeRef = ref}>
                        <div className="left-banner-panel">
                            <div className="img-contain-view">
                                <img src={WebImgUrl} alt="IMG-PLACEHOLDER" />
                            </div>

                            <div className="book-detail">
                                <div className="panel-title" dangerouslySetInnerHTML={{__html: Title}} />
                                <div className="detail-text" dangerouslySetInnerHTML={{__html: SubTitle}} />
                            </div>
                        </div>

                        <div className="share-panel">
                        </div>
                    </div>

                    <div className="ticket-list" ref={ref => this.ticketRef = ref}>
                        <div className="list">
                            <div className="list-header">TICKETS</div>
                            <div className="list-content">
                            {
                                this.saleItems.map((item, index) => {
                                    let cls = item.SoldOut ? 'disabled' : ''
                                    let max = item.Maximum > 0 ? item.Maximum : undefined;

                                    let { ItemSelected } = item;

                                    if(SeatSelection) {
                                        return (
                                            <div key={index} className={"ticket-list-item " + cls}>
                                                <h4>{item.ItemTitle}{item.SoldOut && <span className="mark">SOLD OUT</span>}</h4>
                                                <span>${Number(item.ItemValue).toFixed(2)}</span>
                                                { ItemSelected > 0 ? <span> X {ItemSelected}</span> : '' }
                                                <div style={{marginLeft: 10}} className="button" onClick={() => this.handleBook(item, index)}>Book Now</div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={index} className={"ticket-list-item " + cls}>
                                                <h4>{item.ItemTitle}{item.SoldOut && <span className="mark">SOLD OUT</span>}</h4>
                                                <span>${Number(item.ItemValue).toFixed(2)}</span> X
                                                <NumericSpinner disabled={item.SoldOut} min={0} max={max} onChange={value => this.handleChangeTicket(index, value)} />
                                            </div>
                                        )
                                    }
                                })
                            }
                            </div>
                            {
                                !SeatSelection &&
                                <div className="list-footer">
                                    <div className="button" onClick={() => this.handleNext()}>Next</div>
                                </div>
                            }
                        </div>

                        <div className="contact-host">
                        </div>
                    </div>

                    {
                        this.detailItems.map((item, index) => {
                            if(item['ItemType'] === 'SPONSOR') {
                                return (
                                    <div key={index} className="overview" ref={ref => this.dataRef.push(ref)}>
                                        <div className="panel-title">{this.sectionTitle(item['SectionOrder'])}</div>
                                        <div className="img-line">
                                        {
                                            item['SponsorUrls'].map((url, urlIndex) => {
                                                let links = item['ClickUrls'];
                                                links = links ? links : {};
                                                let link = links[urlIndex];

                                                return (
                                                    <a key={urlIndex}
                                                        href={link}
                                                        target="__blank"
                                                        className="img-contain-view">
                                                        <img src={url} alt="LOGO" />
                                                    </a>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <div key={index} className="overview" ref={ref => this.dataRef.push(ref)}>
                                    <div className="panel-title">{this.sectionTitle(item['SectionOrder'])}</div>
                                    <div className="panel-detail" dangerouslySetInnerHTML={{__html: item['ItemTitle']}} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        loginState:     selectors.getLoginState(state),
        loginInfo:      selectors.getLoginInfo(state),
        bookEvent:      selectors.getBookEvent(state),
        listDetail:     selectors.getListDetail(state),
        eventDetail:    selectors.getEventDetail(state),
        seats:          selectors.getSeats(state),
        event:          selectors.getEventId(state),
    }
}

export default connect(mapStateToProps)(withRouter(BookTicketContent));
