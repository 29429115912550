export default [{
    nested: '/dashboard',
    count: 1,
}, {
    nested: '/dashboard',
    count: 2,
}, {
    nested: '/dashboard',
    count: 3,
}];
