export default class Utils {

    static isMobile() {
        return window.innerWidth <= 700
    }

    static getDateTime(date) {
        let part1 = date.toLocaleDateString()
        part1 = part1.replace(/\//g, '-')
        let part2 = date.toLocaleTimeString()
        part2 = part2.replace(' AM', '')
        part2 = part2.replace(' PM', '')

        return part1 + '_' + part2
    }

    static getDateStringOf(date) {
        if(Object.prototype.toString.call(date) !== '[object Date]') {
            date = new Date();
        }

        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let part0 = date.getDate()
        let month = date.getMonth()
        let part1 = months[month]

        part1 = this.numberWithZero(part0) + ' ' + part1
        part1 = part1 + ' ' + date.getFullYear()
        return part1
    }

    static getTimeStringOf(date) {
        if(Object.prototype.toString.call(date) !== '[object Date]') {
            date = new Date();
        }

        let hr = date.getHours()
        let min = date.getMinutes()

        let am = hr > 11 ? 'PM' : 'AM'
        hr = hr > 12 ? hr - 12 : hr

        let str = this.numberWithZero(hr) + ':' + this.numberWithZero(min) + ' ' + am
        return str
    }

    static getInputStringOf(obj) {
        let date = new Date(obj)
        let day = this.numberWithZero(date.getDate())
        let month = this.numberWithZero(date.getMonth() + 1)
        let year = date.getFullYear()

        let hr = this.numberWithZero(date.getHours())
        let min = this.numberWithZero(date.getMinutes())

        return year + '/' + month + '/' + day + ' ' + hr + ':' + min
    }

    static getTimeId(date) {
        let month = this.numberWithZero(date.getMonth() + 1);
        let day = this.numberWithZero(date.getDate());
        let hr = this.numberWithZero(date.getHours());
        let min = this.numberWithZero(date.getMinutes());
        let sec = this.numberWithZero(date.getSeconds());
        return `${day}.${month}.${hr}.${min}.${sec}`;
    }

    static getTime(date) {
        let part2 = date.toLocaleTimeString()
        part2 = part2.toLowerCase();
        part2 = part2.replace(' am', '')
        part2 = part2.replace(' pm', '')
        let part0 = date.getDate()
        let part1 = date.getMonth() + 1

        part2 = this.numberWithZero(part0) + '.' + this.numberWithZero(part1) + '.' + part2
        return part2
    }

    static getDateOf(date) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let part0 = date.getDate()
        let month = date.getMonth()
        let part1 = months[month]

        return this.numberWithZero(part0) + ' ' + part1
    }

    static getTimeOf(date) {
        let hr = date.getHours()
        let min = date.getMinutes()

        let ap = hr >= 12 ? 'PM' : 'AM'
        hr = hr > 12 ? hr - 12 : hr

        return hr + ':' + this.numberWithZero(min) + ' ' + ap
    }

    static getTimezoneOf(date) {
        let offset = date.getTimezoneOffset()
        let sign = offset > 0 ? '+' : '-'
        return sign + ' ' + Math.abs(offset) / 60 + ':00 GMT'
    }

    static generateParams(params) {
        let paramsString = ''
        Object.keys(params).forEach(key => {
            paramsString += '&' + key + '=' + params[key]
        })

        return paramsString.substring(1)
    }

    static numberWithZero(num) {
        if(num < 10) {
            return '0' + num
        } else {
            return '' + num
        }
    }

    static urlMatch(url, matches) {
        for(let i = 0; i < matches.length; i++) {
            if(url === matches[i]) {
                return true
            }
        }

        return false
    }

    static nestedUrlMatch(url, matches) {
        for(let i = 0; i < matches.length; i++) {
            if(url.indexOf(matches[i].nested) > -1) {
                let nestedUrls = url.split('/')
                if(nestedUrls.length === matches[i].count + 1) {
                    return true
                }
            }
        }

        return false
    }
    
    static findNode(ary, field, value) {
        for(let i = 0; i < ary.length; i++) {
            let item = ary[i];
            if(item[field] === value) return item;
        }

        return null;
    }
    
    static findNodes(ary, field, value, reverseCondition) {
        let nodes = [];

        for(let i = 0; i < ary.length; i++) {
            let item = ary[i];
            if(reverseCondition) {
                if(item[field] !== value) nodes.push(item);
            } else {
                if(item[field] === value) nodes.push(item);
            }
        }

        return nodes;
    }

    static printNumberWithDelimiter(value, delimiter) {
        if(!delimiter) delimiter = ',';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    }

    static isEmptyString(str) {
        return !str || str.length === 0;
    }

    static validateEmail(email) {
        if(this.isEmptyString(email)) return false;

        let splits = email.split("@");
        if(splits.length < 2) return false;

        if(splits[1].length < 2) return false;

        return true;
    }
    
    static validatePhone(phone) {
        if(this.isEmptyString(phone)) return false;
        phone = phone.replace(/ /g, '');
        phone = phone.replace(/-/g, '');
        phone = phone.replace(/\+/g, '');

        if(phone.length < 10) return false;

        if(isNaN(phone)) return false;

        return true;
    }
}