import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import './DashboardView.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../store/dashboard/reducer';
import * as actions             from '../../../store/dashboard/actions';
import RouteConfig              from './route.config';

import Utils                    from '../../../services/utils';
import MainHeader               from './MainHeader';
import MainFooter               from './MainFooter';
import Login                    from './Login/Login';
import HomeContent              from './HomeContent';
import CouponContent            from './CouponContent';
import EmptyContent             from './EmptyContent';
import ReportContent            from './ReportContent';
import PaymentsContent          from './PaymentsContent';
import TicketsContent           from './TicketsContent';


class DashboardView extends Component {
    
    /*-----------------------------------------------------------*/
    // Initialize

    constructor(props) {
        super(props);

        let url = props.location.pathname.toLowerCase();
        let isMatched = Utils.nestedUrlMatch(url, RouteConfig);
        if(!isMatched) this.props.history.push('/home');

    }

    componentWillReceiveProps(props) {
        let { authState, userInfo } = props;        
        if(authState && !userInfo) {
            this.props.dispatch(actions.setTryLogin(true));
        }
    }

    componentDidMount() {
    }

    loadEvents() {
        // this.props.dispatch(actions.loadEvents());
        // this.props.dispatch(actions.loadAllEvents());
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { authState, userInfo } = this.props;

        return (
            <div className="App-Content">

                <MainHeader {...this.props} />

                {
                    !authState || !userInfo ?
                    <div className="main-content" style={{minHeight: 500}}>
                        <div className="loading-container">
                            <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                        </div>
                    </div> :
                    <div className="main-content">

                        <Switch>

                            <Route exact path="/dashboard"                  render={() => (<Redirect to="/dashboard/summary"/>)} />
                            <Route exact path="/dashboard/summary"          render={(props) => <HomeContent {...props} />} />
                            <Route exact path="/dashboard/:listId/payment"  render={(props) => <PaymentsContent {...props} />} />
                            <Route exact path="/dashboard/:listId/ticket"   render={(props) => <TicketsContent {...props} />} />
                            <Route exact path="/dashboard/:listId/coupons"  render={(props) => <CouponContent {...props} />} />
                            <Route exact path="/dashboard/history"          render={(props) => <EmptyContent {...props} />} />

                        </Switch>

                        <MainFooter {...this.props} />

                    </div>
                }

                <Login />

                <ReportContent />

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        authState:      selectors.getAuthChangedState(state),
        userInfo:       selectors.getUserInfo(state),
    }
}

export default connect(mapStateToProps)(withRouter(DashboardView));
