export const EventType = {
    SIGN_IN_ACTION_EVENT: 'sign_in_event',
    ON_BACK_EVENT: 'back_button_event',
    ON_HOME_EVENT: 'home_button_event',
    ON_BACK_BUTTON_STATE_CHANGED: 'back_button_state_changed',
}

export const AuthEventType = {
    AUTH_CHANGED: 'auth_state_changed',
}

export const StoreEventType = {
    BANNER_UPDATED: 'banner_updated',
    CATEGORY_UPDATED: 'category_updated',
    SUB_CATEGORY_UPDATED: 'sub_category_updated',
}