import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './FAQContent.scss';
import firebase from 'firebase';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';


class FAQContent extends Component {
    
    events = [];

    constructor() {
        super();
        this.loadData();
    }

    loadData() {
        this.events = [];
        firebase.firestore().collection('Faq')
        .orderBy('OrderNo', 'asc')
        .get()
        .then(snap => {
            snap.docs.forEach(doc => {
                let data = doc.data();
                this.events.push({
                    ...data,
                    expanded: false,
                });
            });

            this.forceUpdate();
        })

    }

    /*-----------------------------------------------------------*/
    // Action Functions

    handleExpand(index) {
        this.events[index].expanded = !this.events[index].expanded;
        this.forceUpdate();
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        return (
            <div className="faq-content-container">

                <div className="event-content">
                
                    <div className="main-title">FAQ</div>

                    <div className="faq-list tx-content">
                    {
                        this.events.map((event, idx) => {

                            return (
                                <div key={idx} className="faq-item" onClick={() => this.handleExpand(idx)}>
                                    {
                                        !event.expanded ?
                                            <div className="plus"><span className="fa fa-plus"></span></div> :
                                            <div className="plus"><span className="fa fa-minus"></span></div>
                                    }
                                    <div>
                                        <div className="title">{event.Question}</div>
                                        { event.expanded && <div className="content" dangerouslySetInnerHTML={{__html: event.Answer}}></div> }                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    }
}

export default connect(mapStateToProps)(withRouter(FAQContent));
