import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './TicketsContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
import { toast }                from 'react-toastify';
import Utils                    from '../../../../services/utils';
import AppConfig                from '../../../../config/app.config';
import { firestore }            from '../../../../services/firebase.service';


class TicketsContent extends Component {

    constructor(props) {
        super(props);
        this.emails = [];
        this.names = [];
        this.payments = [];
        this.blocks = {};

        this.loadData();
    }

    componentDidUpdate() {
        let { search } = this.props.location;
        search = search.replace('?', '');

        let queries = search.split('&');
        for(let i = 0; i < queries.length; i++) {
            if(queries[i].indexOf('ref=') > -1) {
                // Move to the ref..
                let ref = queries[i].replace('ref=', '');
                let block = this.blocks[ref];
                if(block) {
                    window.scrollTo(0, block.offsetTop);
                }

                break;
            }
        }
    }

    loadData() {
        let { listId } = this.props.match.params;

        this.props.dispatch(actions.setLoading(true));
        firestore.collection('Payments')
            .where('ListId', '==', listId)
            .orderBy('LastUpdated', 'desc')
            .get()
            .then(snapshot => {
                this.payments = snapshot.docs.map(doc => doc.data())
                console.log("Loaded hosted event content", this.payments)

                this.emails = [];
                this.names = [];
                this.payments.forEach((payment) => {
                    let { Items } = payment;
                    let items = [];
                    Items.forEach(() => {
                        items.push("")
                    });

                    this.emails.push([...items]);
                    this.names.push([...items]);
                });

                this.props.dispatch(actions.setLoading(false));
            })
            .catch(error => {
                console.log("Error on load hosted event content", error)
                this.props.history.push('/dashboard/summary');
                this.props.dispatch(actions.setLoading(false));
            })
    }


    /*-----------------------------------------------------------*/
    // Action Functions

    handleViewTicket(index, payItem) {
        let id = payItem['paymentId'];
        let { listId } = this.props.match.params;

        this.props.history.push(`/order/${listId}/${id}/${index + 1}`)
    }

    handleSendSMS(bigIdx, smallIdx, payment) {

        let { hostedEvents } = this.props;
        let paymentConfirm = null;

        let email = this.emails[bigIdx][smallIdx];
        let name = this.names[bigIdx][smallIdx];
        if(!Utils.validatePhone(email)) {
            this.showToast("Please input valid phone number");
            return;
        }

        for(let i = 0; i < hostedEvents.length; i++) {
            if(hostedEvents[i]['ListId'] === payment['ListId']) {
                paymentConfirm = hostedEvents[i];
                break;
            }
        }

        if(!paymentConfirm) {
            this.showToast("Please input valid card details");
            return;
        }

        let params = {
            collection: paymentConfirm['OrderCollection'],
            paymentId: `${payment['PaymentId']}_${smallIdx + 1}`,
            name,
            phone: email,
        }

        let info = {
            ListId: payment['ListId'],
            PaymentId: payment['PaymentId'],
            UserId: payment['UserId'],
            SharedName: name ? name : '',
            SharedEmail: '',
            SharedSMS: email,
            LastUpdated: new Date(),
        }

        firestore
            .collection('SharedUsers')
            .add(info);

        this.props.dispatch(actions.setLoading(true));
        let url = AppConfig[AppConfig.environment].sms_api_url;;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
        }).then(result => {
            console.log("Send SMS Result", result);
            this.props.dispatch(actions.setLoading(false));
            if(result.status === 200) {
                this.showToast("SMS sent successfully");
            } else {
                this.showToast("SMS sent failed");
            }
        }).catch(error => {
            this.showToast("SMS sent failed");
            console.log("Send SMS Error", error);
            this.props.dispatch(actions.setLoading(false));
        });
    }

    handleSendEmail(bigIdx, smallIdx, payment) {

        let { hostedEvents } = this.props;
        let paymentConfirm = null;

        let email = this.emails[bigIdx][smallIdx];
        let name = this.names[bigIdx][smallIdx];
        if(!Utils.validateEmail(email)) {
            this.showToast("Please input valid email");
            return;
        }

        for(let i = 0; i < hostedEvents.length; i++) {
            if(hostedEvents[i]['ListId'] === payment['ListId']) {
                paymentConfirm = hostedEvents[i];
                break;
            }
        }

        if(!paymentConfirm) {
            this.showToast("Please input valid card details");
            return;
        }

        let params = {
            collection: paymentConfirm['OrderCollection'],
            paymentId: `${payment['PaymentId']}_${smallIdx + 1}`,
            email,
            name,
        }

        let info = {
            ListId: payment['ListId'],
            PaymentId: payment['PaymentId'],
            UserId: payment['UserId'],
            SharedName: name ? name : '',
            SharedEmail: email,
            SharedSMS: '',
            LastUpdated: new Date(),
        }

        firestore
            .collection('SharedUsers')
            .add(info);

        this.props.dispatch(actions.setLoading(true));
        let url = AppConfig[AppConfig.environment].email_api_url;;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
        }).then(result => {
            console.log("Send Email Result", result);
            this.props.dispatch(actions.setLoading(false));
            if(result.status === 200) {
                this.showToast("Email sent successfully");
            } else {
                this.showToast("Email sent failed");
            }
        }).catch(error => {
            this.showToast("Email sent failed");
            console.log("Send Email Error", error);
            this.props.dispatch(actions.setLoading(false));
        });
    }

    handleShare(link) {
        window.open(link, '_blank');
    }

    handleChange(type, bigIdx, smallIdx, evt) {
        let value = evt.target.value;
        if(type === 'email') {
            this.emails[bigIdx][smallIdx] = value;
        } else {
            this.names[bigIdx][smallIdx] = value;
        }
        this.forceUpdate();
    }


    /*-----------------------------------------------------------*/
    // General Functions

    paymentItems(payment) {
        let payItems = [];
        let { Items, PaymentId } = payment;

        Items.forEach(item => {
            for (let i = 0; i < item.ItemSelected; i++) {
                if(this.SelectedSeats) {
                }

                payItems.push({
                    ...item,
                    SelectedSeat: item.SeatsEventId && item.SelectedSeats  ? item.SelectedSeats[i] : '',
                    SeatSelection: item.SeatsEventId && item.SelectedSeats ? true : false,
                    ItemSelected: 1,
                    paymentId: PaymentId,
                })
            }
        });

        return payItems;
    }

    showToast(text) {
        toast.error(text);
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { loading } = this.props;

        return (
            <div className="tickets-content-container">

                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="content top-padding" style={{marginBottom: 50}}>
                {
                    this.payments.map((payment, bigIdx) => {

                        let lastUpdated = new Date(payment['LastUpdated'].seconds * 1000);
                        let payItems = this.paymentItems(payment);
                        let paymentId = payment['PaymentId'];

                        return (
                            <div key={bigIdx} className="payment-confirm bg-gray flex-full" style={{marginTop: 20}}
                                ref={ref => this.blocks[paymentId] = ref}>

                                <div className="panel-title">
                                    <span className="fa fa-check-circle"/> {payment['ListTitle']}
                                </div>

                                <div className="book-confirm-content" style={{flex: 1}}>
                                    <div className="block row" style={{alignItems: 'center'}}>
                                        <img src={require('../../../../assets/images/ticket_red.png')} alt="LOGO" />

                                        <div className="block-title">
                                            <div>{payment['BuyerUser']['DisplayName']} tickets were booked successfully with reference number</div>
                                            <div className="red">{payment['PaymentId']}</div>
                                            <div className="red">
                                                Paid at {Utils.getDateStringOf(lastUpdated)} {Utils.getTimeStringOf(lastUpdated)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="block">
                                        <div className="block-title medium-height">Get your tickets here.</div>
                                        <div className="block-item border-top">
                                            {
                                                payItems && payItems.map((payItem, smallIdx) => {
                                                    let cls = smallIdx === payItems.length - 1 ? 'row' : 'row border-bottom';
                                                    return (
                                                        <div className={cls} key={smallIdx}>
                                                            <div style={{flex: 1}}>{smallIdx + 1}. {payItem['ItemTitle']}{payItem['SeatSelection'] && ' (' + payItem['SelectedSeats'][smallIdx] + ')'}</div>
                                                            <input type="text" placeholder="Enter Name" style={{width: 150}} className="email-input" onChange={evt => this.handleChange('name', bigIdx, smallIdx, evt)} value={this.names[bigIdx][smallIdx]} />
                                                            <input type="email" placeholder="Email or Phone Number" className="email-input" onChange={evt => this.handleChange('email', bigIdx, smallIdx, evt)} value={this.emails[bigIdx][smallIdx]} />
                                                            <div className="btn-row" style={{padding: 0}}>
                                                                <img className="img-btn"
                                                                    src={require('../../../../assets/images/email.png')}
                                                                    onClick={() => this.handleSendEmail(bigIdx, smallIdx, payment)} alt="EMAIL" />
                                                                <img className="img-btn"
                                                                    src={require('../../../../assets/images/sms.png')}
                                                                    onClick={() => this.handleSendSMS(bigIdx, smallIdx, payment)} alt="SMS" />
                                                                <img className="img-btn"
                                                                    src={require('../../../../assets/images/view.png')}
                                                                    onClick={() => this.handleViewTicket(smallIdx, payItem)} alt="VIEW" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:            selectors.getLoading(state),
        error:              selectors.getError(state),
        hostedEvents:       selectors.getHostedEvents(state),
        dashboardContent:   selectors.getDashboardContent(state),
    }
}

export default connect(mapStateToProps)(withRouter(TicketsContent));
