import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './ContactUsContent.scss'
import firebase from 'firebase';
import { toast } from 'react-toastify';


class ContactUsContent extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        content: '',
        processing: false,
    }


    /*-----------------------------------------------------------*/
    // Action Functions

    onChange(key, value) {
        let state = this.state
        state[key] = value
        this.setState(state)
    }

    onSubmitTapped() {
        if(this.state.processing) {
            return
        }

        if(!this.isValid(this.state.email) || !this.isValidEmail(this.state.email)) {
            this.showToast("Please input valid email!")
            return
        }

        if(!this.state.content || this.state.content.length === 0) {
            this.showToast("Please input what you need to help!")
            return
        }

        let info = {
            Name: this.state.name,
            Email: this.state.email,
            Phone: this.state.phone,
            Notes: this.state.content,
        }

        this.setState({processing: true})

        firebase.firestore().collection('ContactUs').add(info).then(() => {

            this.setState({
                name: '',
                email: '',
                phone: '',
                content: '',
                processing: false,
            })
            this.showToast("Thank you! We will get in touch with you soon!")

        }).catch(() => {

            this.setState({
                processing: false,
            })
            this.showToast("Something went wrong! Try again!")

        })
    }

    /*-----------------------------------------------------------*/
    // General Functions

    isValid(text) {
        return text && text.length > 0
    }

    isValidEmail(mail)
    {
        let splits = mail.split("@")
        if (splits.length > 1 && splits[1].length > 1) {
            return true
        }

        return false
    }

    showToast(text) {
        toast.error(text)
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        return (
            <div className="contact-content-container">

                <div className="event-content contact-content">
                    <div className="main-title">CONTACT US</div>

                    <div className="panel-content tx-content">
                        <div className="panel-description">
                        We'd love to help. Please give us a few details and we'll get back to you soon.
                        </div>

                        <input placeholder="Name"
                            onChange={evt => this.onChange('name', evt.target.value)}
                            value={this.state.name} />

                        <input placeholder="Email Address"
                            onChange={evt => this.onChange('email', evt.target.value)}
                            value={this.state.email} />

                        <input placeholder="Phone Number"
                            onChange={evt => this.onChange('phone', evt.target.value)}
                            value={this.state.phone} />

                        <textarea placeholder="Tell us how we can help you"
                            onChange={evt => this.onChange('content', evt.target.value)}
                            value={this.state.content} />

                        <div className="button" onClick={() => this.onSubmitTapped()}>Submit</div>

                    </div>


                    <div className="contact-info">

                        <div className="contact-info-item">
                            <img src={require('../../../../assets/images/contact-phone.png')} alt="PHONE" />
                            1300 229 227
                        </div>

                        <div className="contact-info-item">
                            <img src={require('../../../../assets/images/contact-email.png')} alt="EMAIL" />
                            contactus@primetickets.com.au
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default withRouter(ContactUsContent)
