import React from 'react'
import './Stepper.scss'

class Stepper extends React.Component {

    constructor(props) {
        super(props);
        
        let { min, value } = props
        min = min ? min : 0
        value = value ? value : 0
        
        if(value < min) value = min

        this.state = {
            step: value
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({step: nextProps.value})
    }
  
    handleChange(isUp) {

        if(this.props.stop) {
            return
        }

        let { step } = this.state
        let { min, max } = this.props

        if(isUp) {

            if(max && max <= step) return
            step++
        } else {
            if(min && min >= step) return
            if(step === 0) return
            step--
        }

        this.setState({step})

        if(this.props.onChange) {
            this.props.onChange(step)
        }
    };
  
    render() {
        let { style } = this.props;
        let { step } = this.state;

        if(!style) style = {}

        return (
            <div className="stepper" style={style}>
                <div className="minus step" onClick={() => this.handleChange(false)} alt="MINUS" />
                <div className="value">{step}</div>
                <div className="plus step" onClick={() => this.handleChange(true)} alt="PLUS" />
            </div>
        );
    }
}
  
export default Stepper
