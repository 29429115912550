import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MainFooter.scss';
import AppConfig        from '../../../../config/app.config';


class MainFooter extends Component {

    render() {
        let env = AppConfig.environment || "DEVELOPMENT";
        let facebook = AppConfig[env].facebook;
        let twitter = AppConfig[env].twitter;
        let instagram = AppConfig[env].instagram;

        return (
            <footer className="main-footer">

                {/* <div className="alarm-panel">
                    <div className="item">
                        <img src={require('../../../../assets/images/discount@3x.png')} alt="IMG-PLACEHOLDER" />
                        Need Help? Contact Us.
                    </div>

                    <div className="item">
                        <img src={require('../../../../assets/images/discount@3x.png')} alt="IMG-PLACEHOLDER" />
                        Give us your feedback.
                    </div>

                    <div className="item">
                        <img src={require('../../../../assets/images/discount@3x.png')} alt="IMG-PLACEHOLDER" />
                        Advertise with us.
                    </div>

                    <div className="item">
                        <img src={require('../../../../assets/images/discount@3x.png')} alt="IMG-PLACEHOLDER" />
                        Host your event with us.
                    </div>
                </div> */}

                <div className="main-footer-content">

                    <div className="like-panel social-icons">

                        {/* <a href={facebook} target="__blank" className="row"> */}
                        <a href="#" className="row">
                            <span className="fa fa-facebook-square" /> 
                            {/* Like us on Facebook */}
                        </a>

                        {/* <a href={twitter} target="__blank" className="row"> */}
                        <a href="#" className="row">
                            <span className="fa fa-twitter" /> 
                            {/* Follow us on twitter */}
                        </a>

                        {/* <a href={instagram} target="__blank" className="row"> */}
                        <a href="#" className="row">
                            <span className="fa fa-instagram" /> 
                            {/* Follow us on Instagram */}
                        </a>

                    </div>

                    <div className="link-panel">
                        {/* <Link to="/privacy">Privacy Policy</Link>
                        <span className="fa fa-circle" /> */}
                        <Link to="/about">About Us</Link>
                        <span className="fa fa-circle" />
                        {/* <Link to="/sitemap">Sitemap</Link>
                        <span className="fa fa-circle" /> */}
                        <Link to="/terms">Terms & Conditions</Link>
                        <span className="fa fa-circle" />
                        <Link to="/terms">Privacy Policy</Link>
                    </div>

                    <div className="line" />

                    <div className="bottom-text">
                        <span className="fa fa-copyright" />&copy; 2021 primetickets.com.au
                    </div>


                </div>

            </footer>
        )
    }
}

export default MainFooter;
