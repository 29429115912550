export const LOADING_SET = 'home.LOADING_SET'
export const ERROR_SET = 'home.ERROR_SET'
export const USER_STATE_SET = 'home.USER_STATE_SET'
export const LOGIN_SET = 'home.LOGIN_SET'
export const LOGIN_INFO_SET = 'home.LOGIN_INFO_SET'
export const BANNERS_SET = 'home.BANNERS_SET'
export const CATEGORIES_SET = 'home.CATEGORIES_SET'
export const SUB_CATEGORIES_SET = 'home.SUB_CATEGORIES_SET'
export const EVENTS_SET = 'home.EVENTS_SET'
export const ALL_EVENTS_SET = 'home.ALL_EVENTS_SET'
export const PAST_EVENTS_SET = 'home.PAST_EVENTS_SET'
export const BOOK_EVENT_SET = 'home.BOOK_EVENT_SET'
export const EVENT_DETAIL_SET = 'home.EVENT_DETAIL_SET'
export const LIST_DETAIL_SET = 'home.LIST_DETAIL_SET'
export const SALE_ITEM_SET = 'home.SALE_ITEM_SET'
export const COUPON_SET = 'home.COUPON_SET'
export const CONFIRM_PAYMENT_SET = 'home.CONFIRM_PAYMENT_SET'
export const DASHBOARD_CONTENT_SET = 'home.DASHBOARD_CONTENT_SET'
export const REPORT_STATE_SET = 'home.REPORT_STATE_SET'
export const HOST_EVENT_TAP_SET = 'home.HOST_EVENT_TAP_SET'
export const ADS_SET = 'home.ADS_SET'
export const SEATS_SET = 'home.SEATS_SET'
export const EVENT_ID_SET = 'home.EVENT_ID_SET'
