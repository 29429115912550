export default {
    environment: 'PRODUCTION', // PRODUCTION DEVELOPMENT

    DEVELOPMENT: {
        logo: 'logo2.png',
        title: 'Prime Tickets Australia |  Tickets for Sport, Concerts, Theatre, Arts, Family Events, Comedy, Festivals',
        email_url: '',
        appstore: 'https://itunes.apple.com/us/app/ibazaar/id1406375198?ls=1&mt=8',
        playstore: 'https://play.google.com/store/apps/details?id=au.com.ibazaar.android.prod',
        google: 'https://plus.google.com',
        facebook: 'https://www.facebook.com/ibazaar.com.au',
        twitter: 'https://www.twitter.com',
        instagram: 'https://instagram.com/ibazaar.aus',
        normal: 'https://instagram.com/ibazaar.aus',
        email_api_url: 'https://us-central1-ibazaarfirebase.cloudfunctions.net/DEVsendEmail',
        sms_api_url: 'https://us-central1-ibazaarfirebase.cloudfunctions.net/DEVsendSMS',
    },

    PRODUCTION: {
        logo: 'logo2.png',
        title: 'Prime Tickets Australia |  Tickets for Sport, Concerts, Theatre, Arts, Family Events, Comedy, Festivals',
        email_url: '',
        appstore: 'https://itunes.apple.com/us/app/ibazaar/id1406375198?ls=1&mt=8',
        playstore: 'https://play.google.com/store/apps/details?id=au.com.ibazaar.android.prod',
        google: 'https://plus.google.com',
        facebook: 'https://www.facebook.com/ibazaar.com.au',
        twitter: 'www.twitter.com',
        instagram: 'https://instagram.com/ibazaar.aus',
        normal: 'https://instagram.com/ibazaar.aus',
        email_api_url: 'https://us-central1-ibazaarprod.cloudfunctions.net/sendEmail',
        sms_api_url: 'https://us-central1-ibazaarprod.cloudfunctions.net/sendSMS',
    },
}
