import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './PastEventsContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';


class PastEventsContent extends Component {
    
    /*-----------------------------------------------------------*/
    // Action Functions

    handleBookTicket(event) {
        console.log("Event to book", event)
        let listId = event['ListId'];
        this.props.history.push(`/event/${listId}`)
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { events } = this.props;

        return (
            <div className="past-content-container">

                <div className="event-content">
                
                    <div className="main-title">PAST EVENTS</div>

                    <div className="event-list">
                    {
                        events.map((event, idx) => {
                            return (
                                <div key={idx} className="event-item">
                                    <div className="image-panel">
                                        <img src={event.MobileImgUrl} alt="IMG-PLACEHOLDER" />
                                        <div className="action-pane">
                                            <div className="event-title">{event.Title}</div>
                                            <div className="row flex-center">
                                                <div className="event-time">
                                                    {event['EventDate']}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        loginState: selectors.getLoginState(state),
        events: selectors.getPastEvents(state),
    }
}

export default connect(mapStateToProps)(withRouter(PastEventsContent));
