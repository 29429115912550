import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './BookHistoryContent.scss';
import firebase from 'firebase';

import { connect } from 'react-redux';
import * as selectors from '../../../../store/home/reducer';
import * as actions from '../../../../store/home/actions';
import { toast } from 'react-toastify';
import Utils from '../../../../services/utils';
import AppConfig from '../../../../config/app.config';


class BookHistoryContent extends Component {

    constructor(props) {
        super(props);

        let { loginInfo } = props;

        if (loginInfo) {
            let { UserId } = loginInfo;
            this.loadOrders(UserId);
        }

        this.orderList = {};
        this.panel = {};
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.loginInfo);
        if (nextProps.loginInfo) {
            let { UserId } = nextProps.loginInfo;
            this.loadOrders(UserId);
        }
    }


    /*-----------------------------------------------------------*/
    // Load data

    loadOrders(UserId) {
        firebase.firestore().collection("Payments")
            .where("UserId", '==', UserId)
            .where("Status", '==', true)
            .orderBy("LastUpdated", 'desc')
            .onSnapshot(snapshot => {

                snapshot.docChanges().forEach(change => {
                    let key = change.doc.id;
                    let data = change.doc.data();

                    if (change.type === "added" || change.type === "modified") {
                        this.orderList[key] = data;
                    }

                    if (change.type === "removed") {
                        delete this.orderList[key];
                    }
                });

                this.forceUpdate();
            });
    }

    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }

    /*-----------------------------------------------------------*/
    // Action Callback

    formatDate(date) {
        let dateObj = new Date(date)
        return dateObj.toDateString()
    }

    formatTime(date) {
        let dateObj = new Date(date)
        if (dateObj === 'Invalid Date') {
            return 'Invalid Date'
        }

        return dateObj.toTimeString()
    }

    onPrintTapped(divRef) {
        console.log(window.html2canvas)
        window.html2canvas(document.querySelector(divRef)).then(canvas => {
            let wndNew = window.open()
            wndNew.document.body.appendChild(canvas)
            wndNew.focus()
            wndNew.print()
            wndNew.close()
        });
    }

    handleShare(link) {
        window.open(link, '_blank');
    }

    handleChange(index, evt) {
        let value = evt.target.value;
        this.emails[index] = value;
        this.forceUpdate();
    }

    handleViewTicket(index, payment) {
        let id = payment['PaymentId'];

        window.open(`/order/${payment['ListId']}/${id}/${index + 1}`, '__blank');
    }

    handleSendEmail(idx, index, payment) {
        let emailDom = document.getElementById('email' + idx + '-' + index);
        let email = emailDom.value;

        let nameDom = document.getElementById('name' + idx + '-' + index);
        let name = nameDom.value;

        if (!Utils.validateEmail(email)) {
            this.showToast("Please input valid email!");
            return;
        }

        this.props.dispatch(actions.setLoading(true));
        firebase.firestore().collection("Lists").doc(payment['ListId'])
            .get()
            .then(result => {
                console.log("Result", result);
                let data = result.data();
                let params = {
                    collection: data.OrderCollection,
                    paymentId: payment.PaymentId + '_' + (index + 1),
                    name,
                    email,
                }

                let info = {
                    ListId: payment['ListId'],
                    PaymentId: payment['PaymentId'],
                    UserId: payment['UserId'],
                    SharedName: name ? name : '',
                    SharedEmail: email,
                    SharedSMS: '',
                    LastUpdated: new Date(),
                }

                firebase.firestore()
                    .collection('SharedUsers')
                    .add(info);

                let url = AppConfig[AppConfig.environment].email_api_url;
                return fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(params),
                })
            })
            .then(result => {
                console.log("Send Email Result", result);
                this.props.dispatch(actions.setLoading(false));
                if (result.status === 200) {
                    this.showToast("Email sent successfully");
                } else {
                    this.showToast("Email sent failed");
                }
            })
            .catch(error => {
                this.showToast("Email sent failed");
                console.log("Send Email Error", error);
                this.props.dispatch(actions.setLoading(false));
            });
    }

    handleSendSMS(idx, index, payment) {
        let emailDom = document.getElementById('email' + idx + '-' + index);
        let email = emailDom.value;

        let nameDom = document.getElementById('name' + idx + '-' + index);
        let name = nameDom.value;

        if (!Utils.validatePhone(email)) {
            this.showToast("Please input valid phone!");
            return;
        }

        this.props.dispatch(actions.setLoading(true));
        firebase.firestore().collection("Lists").doc(payment['ListId'])
            .get()
            .then(result => {
                console.log("Result", result);
                let data = result.data();
                let params = {
                    collection: data.OrderCollection,
                    paymentId: payment.PaymentId + '_' + (index + 1),
                    phone: email,
                    name,
                }

                let info = {
                    ListId: payment['ListId'],
                    PaymentId: payment['PaymentId'],
                    UserId: payment['UserId'],
                    SharedName: name ? name : '',
                    SharedSMS: email,
                    SharedEmail: '',
                    LastUpdated: new Date(),
                }

                firebase.firestore()
                    .collection('SharedUsers')
                    .add(info);

                let url = AppConfig[AppConfig.environment].sms_api_url;
                return fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(params),
                })
            })
            .then(result => {
                console.log("Send Phone Result", result);
                this.props.dispatch(actions.setLoading(false));
                if (result.status === 200) {
                    this.showToast("SMS sent successfully");
                } else {
                    this.showToast("SMS sent failed");
                }
            })
            .catch(error => {
                this.showToast("SMS sent failed");
                console.log("Send Phone Error", error);
                this.props.dispatch(actions.setLoading(false));
            });
    }

    /*-----------------------------------------------------------*/
    // Render

    paymentItems(payment) {
        let payItems = [];

        let { Items, PaymentId } = payment;

        Items.forEach(item => {
            for (let i = 0; i < item.ItemSelected; i++) {
                payItems.push({
                    ...item,
                    SelectedSeat: item.SeatsEventId ? item.SelectedSeats[i] : '',
                    SeatSelection: item.SeatsEventId ? true : false,
                    ItemSelected: 1,
                    paymentId: PaymentId,
                })
            }
        });

        return payItems;
    }

    renderOrder(payment, idx) {
        let paymentTotal = payment.PaymentTotal
        let items = this.paymentItems(payment)
        if (!paymentTotal) {
            paymentTotal = 0
        } else {
            paymentTotal = Number(paymentTotal)
        }

        return (
            <div key={idx} className="book-confirm-content" style={{ flex: 1 }}>
                <div className="block row" style={{ alignItems: 'center' }}>
                    <img src={require('../../../../assets/images/ticket_red.png')} alt="LOGO" />

                    <div className="block-title">
                        <div>{payment.BuyerUser.DisplayName}, </div>
                        <div>Your tickets were booked successfully with reference number</div>
                        <div className="red">{payment.PaymentId}</div>
                    </div>
                </div>

                <div className="block row">
                    <div style={{ flex: 1 }}>
                        <div className="block-title medium-height">Get your tickets here.</div>
                        <div className="block-item border-top">
                            {
                                items && items.map((payItem, index) => {
                                    let cls = index === items.length - 1 ? 'row' : 'row border-bottom';
                                    return (
                                        <div className={cls} key={index}>
                                            <div style={{ flex: 1 }}>{index + 1}. {payItem['ItemTitle']}{payItem['SeatSelection'] && '(' + payItem['SelectedSeats'][index] + ')'}</div>
                                            <input placeholder="Enter Name" style={{ width: 150 }} id={'name' + idx + '-' + index} type="name" className="email-input" />
                                            <input placeholder="Email or Phone Number" id={'email' + idx + '-' + index} type="email" className="email-input" />
                                            <div className="btn-row" style={{ padding: 0 }}>
                                                <img className="img-btn"
                                                    src={require('../../../../assets/images/email.png')}
                                                    onClick={() => this.handleSendEmail(idx, index, payment)} alt="EMAIL" />
                                                <img className="img-btn"
                                                    src={require('../../../../assets/images/sms.png')}
                                                    onClick={() => this.handleSendSMS(idx, index, payment)} alt="SMS" />
                                                <img className="img-btn"
                                                    src={require('../../../../assets/images/view.png')}
                                                    onClick={() => this.handleViewTicket(index, payment)} alt="VIEW" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let orderKeys = Object.keys(this.orderList);
        let { loading } = this.props;

        return (
            <div className="book-detail-container">
                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                {
                    orderKeys.map((key, index) => {
                        let payment = this.orderList[key]
                        return this.renderOrder(payment, index)
                    })
                }
                {
                    orderKeys.length === 0 &&
                    <div className="event-content">
                        <div className="main-title">TICKETS NOT FOUND</div>
                    </div>
                }
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        loginInfo: selectors.getLoginInfo(state),
    }
}

export default connect(mapStateToProps)(withRouter(BookHistoryContent));
