import React, { Component } from 'react'
import './FeatureList.css'

class FeatureList extends Component {

    onBannerImgClick(item) {
        if(this.props.bannerImgTapped) {
            let imgs = item.ImgUrls
            this.props.bannerImgTapped(imgs)
        }
    }

    onBuyItemClick(item) {
        if(this.props.buyItemTapped) {
            this.props.buyItemTapped(item)
        }
    }

    render() {
        let {items} = this.props

        let itemWidth = 300
        let width = (itemWidth + 60) * items.length + 10

        return (
            <div style={styles.container}>
                <div style={{...styles.itemContainer, width: width}}>
                {
                    items.map((item, index) => {
                        return (
                            <div style={styles.item} key={index}>
                                <div className="banner-img-container" style={{width: itemWidth}} onClick={() => this.onBannerImgClick(item)}>
                                    <img src={item.ImgUrls[0]} style={{maxWidth: '100%', maxHeight: 200}} alt="BANNER" />
                                </div>
                                <h4>{item.Title}</h4>
                                <div style={{marginBottom: 10}} dangerouslySetInnerHTML={{__html: item.SubTitle}}></div>
                                <div style={{flex: 1}}></div>
                                <div style={{width: '100%'}}>
                                    <button className="btn btn-primary" style={styles.buy} onClick={() => this.onBuyItemClick(item)}>Buy Now</button>
                                </div>
                            </div>    
                        )
                    })
                }
                </div>
                {
                    items.length === 0 && <h4>NO ITEM!</h4>
                }
            </div>
        )
    }
}

export default FeatureList

const styles = {
    container: {
        width: '100%',
        overflow: 'auto',
        boxShadow: '0px 0px 3px 0px #9a9a9a',
        background: '#f4f4f4',
        marginBottom: 20,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    item: {
        padding: 20,
        margin: 10,
        border: '1px solid #DDD',
        background: 'white',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    buy: {
        background: '#ce3737',
        borderColor: '#ce3737',
        width: '100%',
    }
}