import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../store/home/reducer';
import { withRouter, Link } from 'react-router-dom';

class NotFound extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.events != this.props.events) {
            console.log(this.props)
            var path = window.location.pathname;
            path.indexOf(1);
            path.toLowerCase();
            var firstpath = path.split("/")[1];

            var isEventFound = false;
            this.props.events.forEach((ev) => {
                if (ev.ListId == firstpath.toLowerCase()) {
                    isEventFound = true;
                    this.props.history.push('/event/' + firstpath);
                }
            });
            if (!isEventFound)
                this.props.history.push('/home');
        }
    }

    render() {
        return (
            <div style={{ padding: 30 }}>
                <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>
        )
    }
}

// export default NotFound
function mapStateToProps(state) {
    return {
        events: selectors.getAllEvents(state),
    }
}

export default connect(mapStateToProps)(withRouter(NotFound));