import React, { Component } from 'react'
import './Dropdown.scss'


class Dropdown extends Component {

    state = {
        selIndex: 0,
        dropdown: false,
    }

    componentDidMount() {
        this.canUpdate = 1
        this.opened = 0
        
        this.clickFunc = () => {
            if(this.opened === 0 && this.canUpdate === 1) {
                this.setState({dropdown: false})
            }

            this.opened = 0
        }

        window.addEventListener('click', this.clickFunc)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickFunc)
    }

    updateView() {
        if(this.canUpdate === 1) {
            this.forceUpdate()
        }
    }

    onDropdownTapped(evt) {
        this.opened = 1
        this.setState({dropdown: !this.state.dropdown})
    }

    onTabTapped(tab, index) {
        this.setState({selIndex: index})

        if(this.props.onItemTapped) {
            this.props.onItemTapped(tab, index)
        }
    }


    render() {
        let { selIndex, dropdown } = this.state
        let { tabs, prefix, style, captionStyle, itemStyle } = this.props

        if(!tabs) tabs = [{title: 'NO ITEM'}];

        return (
            <div className="dropdown-container" style={style}>

                <div className="caption" style={captionStyle} 
                    onClick={(evt) => this.onDropdownTapped(evt)}>

                    <span className="title">
                        {prefix ? prefix + ': ' : ''}{tabs[selIndex].title}
                    </span>
                    
                    <span className="caret"></span>
                    
                </div>

                <ul style={{display: dropdown ? 'block' : 'none'}}>
                {
                    tabs.map((item, index) => {

                        return (
                        
                            <li key={index} style={itemStyle} 
                                onClick={() => this.onTabTapped(item, index)}>
                                <span>{item.title}</span>
                            </li>

                        )
                    })
                }
                </ul>

            </div>
        )
    }
}

export default Dropdown
