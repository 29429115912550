export default {
    DEVELOPMENT: {
        key: 'pk_test_T117zZ8Xjg8sL1tMLD3XIX94',
        charge_url: 'https://us-central1-ibazaarfirebase.cloudfunctions.net/DEVcharge',
    },
    PRODUCTION: {
        key: 'pk_live_jvDfvI9rGYI4zjmo5bHGgZdy',
      //  key: 'pk_test_T117zZ8Xjg8sL1tMLD3XIX94',
        charge_url: 'https://us-central1-ibazaarprod.cloudfunctions.net/charge',
    },
}
