import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './ConfirmCheckoutContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';
import * as actions             from '../../../../store/home/actions';
import { toast }                from 'react-toastify';
import Utils                    from '../../../../services/utils';
import AppConfig                from '../../../../config/app.config';
import { firestore }            from '../../../../services/firebase.service';
var QRCode = require('qrcode.react');


class ConfirmCheckoutContent extends Component {

    state = {
        shareTicket: false,
    }

    shareItems = [{
        icon: require('../../../../assets/images/facebook-round@3x.png'),
        link: AppConfig[AppConfig.environment].facebook,
    }, {
        icon: require('../../../../assets/images/twitter-round@3x.png'),
        link: AppConfig[AppConfig.environment].twitter,
    }, {
        icon: require('../../../../assets/images/instagram-round-link.png'),
        link: AppConfig[AppConfig.environment].instagram,
    }, {
        icon: require('../../../../assets/images/link.png'),
        link: AppConfig[AppConfig.environment].normal,
    }];

    constructor(props) {
        super(props);

        let { saleItems, paymentConfirm } = this.props;

        if(!paymentConfirm || !saleItems) {
            this.props.history.push('/home');
            return;
        }

        this.emails = [];
        this.names = [];
        saleItems.forEach(() => {
            this.emails.push("");
            this.names.push("");
        });
    }


    /*-----------------------------------------------------------*/
    // Action Functions

    handleViewTicket(index) {
        let { paymentConfirm } = this.props;
        let id = paymentConfirm['id'];

        let link = `/order/${paymentConfirm['listId']}/${id}/${index + 1}`;
        window.open(link, '_blank');
    }

    handleSendEmail(index) {
        let { paymentConfirm } = this.props;

        let email = this.emails[index];
        let name = this.names[index];

        if(!Utils.validateEmail(email)) {
            this.showToast("Please input valid email!");
            return;
        }

        let params = {
            collection: paymentConfirm['collection'],
            paymentId: `${paymentConfirm['id']}_${index + 1}`,
            name,
            email,
        }

        let info = {
            ListId:  paymentConfirm['listId'],
            PaymentId: paymentConfirm['id'],
            UserId: paymentConfirm['userId'],
            SharedName: name ? name : '',
            SharedEmail: email,
            SharedSMS: '',
            LastUpdated: new Date(),
        }

        firestore
            .collection('SharedUsers')
            .add(info);

        this.props.dispatch(actions.setLoading(true));
        let url = AppConfig[AppConfig.environment].email_api_url;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
        }).then(result => {
            console.log("Send Email Result", result);
            this.props.dispatch(actions.setLoading(false));
            if(result.status === 200) {
                this.showToast("Email sent successfully");
            } else {
                this.showToast("Email sent failed");
            }
        }).catch(error => {
            this.showToast("Email sent failed", error);
            this.props.dispatch(actions.setLoading(false));
        });
    }

    handleSendSMS(index, payItem) {
        let { paymentConfirm } = this.props;

        let email = this.emails[index];
        let name = this.names[index];

        if(!Utils.validatePhone(email)) {
            this.showToast("Please input valid phone");
            return;
        }

        let params = {
            collection: paymentConfirm['collection'],
            paymentId: `${paymentConfirm['id']}_${index + 1}`,
            phone: email,
            name,
        }

        let info = {
            ListId:  paymentConfirm['listId'],
            PaymentId: paymentConfirm['id'],
            UserId: paymentConfirm['userId'],
            SharedName: name ? name : '',
            SharedSMS: email,
            SharedEmail: '',
            LastUpdated: new Date(),
        }

        firestore
            .collection('SharedUsers')
            .add(info);

        this.props.dispatch(actions.setLoading(true));
        let url = AppConfig[AppConfig.environment].sms_api_url;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
        }).then(result => {
            console.log("Send Phone Result", result);
            this.props.dispatch(actions.setLoading(false));
            if(result.status === 200) {
                this.showToast("SMS sent successfully");
            } else {
                this.showToast("SMS sent failed");
            }
        }).catch(error => {
            this.showToast("SMS sent failed");
            console.log("Send Phone Error", error);
            this.props.dispatch(actions.setLoading(false));
        });
    }

    handleShare(link) {
        window.open(link, '_blank');
    }

    handleChange(index, evt, type) {
        let value = evt.target.value;
        if(type === 'name') {
            this.names[index] = value;
        } else {
            this.emails[index] = value;
        }
        this.forceUpdate();
    }

    handleInnisaiFood(){
        window.open('/event/innisaifood', '_blank');
    }

    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }

    validText(text) {
        return text && text.length > 0;
    }

    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { paymentConfirm, loading } = this.props;

        if(!paymentConfirm) return (<div />)

        let { payItems } = paymentConfirm;

        let bodyText = 'You are required to scan the ticket at the door to be permitted entry. Mobile or printed tickets will be accepted.';

        let paymentRef =  'Payment reference number: '+ paymentConfirm['id'] +'';
        let emailText = '';

        if(this.validText(paymentConfirm.buyerEmail) && this.validText(paymentConfirm.buyerPhone)) {
            emailText = 'Tickets are sent to your email ' + paymentConfirm.buyerEmail + ' and a confirmation SMS to +' + paymentConfirm.buyerPhone

        } else if(this.validText(paymentConfirm.buyerEmail)) {
            emailText = 'Confirmation ticket(s) are sent to your email ' + paymentConfirm.buyerEmail

        } else if(this.validText(paymentConfirm.buyerPhone)) {
            emailText = 'Confirmation ticket(s) links are sent as SMS to your mobile ' + paymentConfirm.buyerPhone
        }

        let listId = paymentConfirm['listId']
        let showFood = listId === 'innisai'
        let spamText = '\n If you do not receive the message within a few minutes, please check your spam folder just in case the email got delivered there instead of your inbox. If so, select the confirmation message and click Not Spam, which will allow future messages to get through.'
        let shareText = 'Events are better when your friends are there too. So, who\'s coming with you?'

        return (
            <div className="confirm-content-container">

                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="row content top-padding" style={{marginTop: 20, marginBottom: 50}}>

                    <div className="payment-confirm bg-gray panel-left">

                        <div className="panel-title">
                            <span className="fa fa-check-circle"/> {paymentConfirm.title}
                        </div>
                        <div className="row tx-saved-msg">Your ticket(s) has been saved to My Tickets</div>

                        {
                            !paymentConfirm.groupTicket &&
                            <div className="book-confirm-content" style={{flex: 1}}>
                                <div className="block row" style={{alignItems: 'center'}}>
                                    <img src={require('../../../../assets/images/ticket_red.png')} alt="LOGO" />

                                    <div className="block-title">
                                        <div>Hi {paymentConfirm.buyer}, </div>
                                        <div>{bodyText} </div>
                                        <div>&nbsp;</div>
                                        {/* <div className="red">{paymentConfirm['id']}</div> */}
                                        <div >{emailText}</div>
                                        <div>&nbsp;</div>
                                        <div style={{fontSize: 12}}>{paymentRef}</div>
                                        <div>&nbsp;</div>
                                        <div style={{display: 'flex'}}>
                                            <div className="button" style={{width: 'unset'}}
                                                onClick={() => this.setState({shareTicket: !this.state.shareTicket})}>
                                                {this.state.shareTicket ? 'Hide Share Tickets' : 'Share Tickets'}
                                            </div>
                                            <div>&nbsp;</div>
                                            <div>&nbsp;</div>
                                            <div>&nbsp;</div>
                                            {showFood &&
                                             <div className="button" style={{width: 'unset'}}
                                             onClick={() => this.handleInnisaiFood()}>
                                                Pre Order your food
                                            </div>
                                            }
                                        </div>
                                    </div>

                                </div>

                                {
                                    this.state.shareTicket &&
                                    <div className="block row">
                                        <div style={{flex: 1}}>
                                            <div className="block-title medium-height">{shareText} </div>
                                            <div className="block-item border-top">
                                                {
                                                    payItems && payItems.map((payItem, index) => {
                                                        let cls = index === payItems.length - 1 ? 'row' : 'row border-bottom';
                                                        return (
                                                            <div className={cls} key={index}>
                                                                <div style={{flex: 1}}>{index + 1}. {payItem['ItemTitle']}{payItem['SeatSelection'] && ' (' + payItem['SelectedSeats'][index] + ')'}</div>
                                                                <input placeholder="Name" type="name" className="email-input" onChange={evt => this.handleChange(index, evt, 'name')} value={this.names[index]} />
                                                                <input style={{width: 240}} placeholder="Email or Phone Number" type="email" className="email-input" onChange={evt => this.handleChange(index, evt)} value={this.emails[index]} />

                                                                <div className="btn-row" style={{padding: 0}}>
                                                                    <img className="img-btn"
                                                                        src={require('../../../../assets/images/email.png')}
                                                                        onClick={() => this.handleSendEmail(index)} alt="EMAIL" />
                                                                    <img className="img-btn"
                                                                        src={require('../../../../assets/images/sms.png')}
                                                                        onClick={() => this.handleSendSMS(index)} alt="SMS" />
                                                                    <img className="img-btn"
                                                                        src={require('../../../../assets/images/view.png')}
                                                                        onClick={() => this.handleViewTicket(index)} alt="VIEW" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div>&nbsp;</div>
                                            <div>{spamText}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            paymentConfirm.groupTicket &&
                            <div className="book-confirm-content" style={{flex: 1}}>
                                <div className="block row" style={{alignItems: 'center'}}>
                                    <img src={require('../../../../assets/images/ticket_red.png')} alt="LOGO" />

                                    <div className="block-title">
                                        <div>{paymentConfirm.buyer}, </div>
                                        <div style={{display: 'flex'}}>
                                            <div>Your receipt number :</div>
                                            <div className="red">{paymentConfirm['id']}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="block row">
                                    <div style={{flex: 1, textAlign: 'center'}}>
                                        <QRCode id="qr-code" value={paymentConfirm.id ? paymentConfirm.id : 'NO_CODE'} size={200} />
                                        <div className="row">
                                            <div class="detail-title"><b>Ticket Title:</b></div>
                                            <div class="detail-description">
                                            {
                                                paymentConfirm.itmes.map((item, index) => {
                                                    return (
                                                        <div key={index}>{item.ItemSelected} X {item.ItemTitle}</div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="detail-title"><b>Payment Amount:</b></div>
                                            <div className="detail-description">${paymentConfirm.total}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="payment-summary bg-gray panel-right">
                        <div className="panel-title">SUMMARY</div>
                        <div className="content-panel block">
                            <div className="title"><span className="fa fa-check-circle"/> Payment method</div>
                            <div className="item">${paymentConfirm.total.toFixed(2)}</div>
                        </div>
                        <div className="content-panel block">
                            <div className="title"><span className="fa fa-check-circle"/> Name</div>
                            <div className="item">{paymentConfirm.buyer}</div>
                        </div>
                        {paymentConfirm.buyerEmail != null &&
                            <div className="content-panel block">
                                <div className="title"><span className="fa fa-check-circle"/> Email</div>
                                <div className="item">{paymentConfirm.buyerEmail}</div>
                            </div>
                        }
                        {paymentConfirm.buyerPhone != null &&
                            <div className="content-panel block">
                                <div className="title"><span className="fa fa-check-circle"/> Phone Number</div>
                                <div className="item">{paymentConfirm.buyerPhone}</div>
                            </div>
                        }
                        <div style={{textAlign: 'center', padding: 20}}>
                            <img src={require('../../../../assets/images/secure.png')} alt="LOGO" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        saleItems:      selectors.getSaleItems(state),
        paymentConfirm: selectors.getPaymentConfirm(state),
        // saleItems: SaleItems,
        // paymentConfirm: PaymentConfirm,
    }
}

export default connect(mapStateToProps)(withRouter(ConfirmCheckoutContent));
