import React, { Component } from 'react'
import './RadioButton.scss'

class RadioButton extends Component {

    onClick() {
        if(this.props.onClick) {
            this.props.onClick()
        }
    }

    render() {
        let { style, title, checked } = this.props

        return (
            <div style={style} className="radio-button" onClick={() => this.onClick()}>
                <div className="check"><div className={checked ? 'checked' : ''} /></div>
                <div className="title">{title}</div>
            </div>
        )
    }
}

export default RadioButton
