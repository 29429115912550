import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';
import './Login.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
import * as loginActions        from '../../../../store/home/actions';
import StyledFirebaseAuth       from 'react-firebaseui/StyledFirebaseAuth';
import { toast }                from 'react-toastify';


class Login extends Component {

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                defaultCountry: 'AU',
            }
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => {
                console.log("Auth Success")
                this.props.dispatch(actions.setTryLogin(false));
                this.processLogin()
                return false
            },
        }
    };

    /*-----------------------------------------------------------*/
    // General Functions

    processLogin() {
        let user = firebase.auth().currentUser

        if(!user) {
            this.showToast('Can not process login!');
            return;
        }

        firebase.firestore().collection('Users')
            .doc(user.uid)
            .get()
            .then(snapshot => {

                let info = snapshot.data();

                if(!info) {

                    let params = {
                        Active: true,
                        AppType: 'Web',
                        BusinessGST: false,
                        CreatedBy: 'Web',
                        DisplayName: user.displayName ? user.displayName : '',
                        Email: user.email ? user.email : '',
                        LastUpdated: new Date(),
                        PhoneNumber: user.phoneNumber ? user.phoneNumber : '',
                        UserId: user.uid,
                        UserType: 'Buyer',
                    }

                    firebase.firestore().collection('Users')
                        .doc(user.uid)
                        .set(params);

                    let location = localStorage.getItem('login_redirect');
                    this.props.history.push(location);

                    this.props.dispatch(actions.setUserInfo(params));
                    this.props.dispatch(loginActions.setLoginInfo(params));

                } else {

                    let userData = snapshot.data();
                    this.props.dispatch(actions.setUserInfo(userData));
                    this.props.dispatch(loginActions.setLoginInfo(userData));

                }

            })
            .catch(error => {
                console.log("Login error", error);
                this.showToast("Unknown error orccurred!");
            })
    }

    showToast(text) {
        toast.error(text);
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { tryLogin } = this.props;

        if(!tryLogin) {
            return <div />
        }

        return (
            <div className="sign-in-dialog" style={{display: 'flex'}}>
                <div style={{padding: 20, borderRadius: 8, background: 'white', minWidth: 250, minHeight: 200}}>
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        tryLogin:       selectors.getTryLogin(state),
    }
}

export default connect(mapStateToProps)(withRouter(Login));
