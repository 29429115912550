import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './AboutContent.scss'

// import HostContent              from './host-content.config'
import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';
import * as actions             from '../../../../store/home/actions';

// const logo = 'https://storage.googleapis.com/ibazaarprod.appspot.com/images/banner/Web.jpg';

class AboutContent extends Component {


    /*-----------------------------------------------------------*/
    // Load data


    componentWillReceiveProps(nextProps) {
        if(this.props.hostEventState !== nextProps.hostEventState && nextProps.hostEventState) {
            this.props.dispatch(actions.setHostEventTapped(false));
            this.hostRef.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    /*-----------------------------------------------------------*/
    // Action Functions

    onCloseAlarmTapped() {
        this.setState({alarm: false});
    }

    onContactUsTapped() {
        this.props.history.push('/contact');
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        return (
            <div className="about-content-container">

                <div className="event-content about-content">

                    {/* <div className="carousel" style={{padding: 0}}>
                        <img src={logo} className="page-image" alt="IMG-PLACEHOLDER" />
                    </div> */}

                    <div className="about">
                        <div className="main-title">ABOUT US</div>
                        <div className="tx-content about-description">
                            PrimeTickets is the latest and most innovative app on the market space!.
                            With a goal to make events more fun, stress free, we set out to develop the perfect app - for both businesses and users.
                            Built on the latest technology, our app is developed to give the user a fast and crash-free experience - from browsing to checkout!
                            At PrimeTickets, our team consists of bright minds made up of individuals who are constantly building on their key strengths to ensure that your experience with PrimeTickets is nothing short of amazing.
                        </div>
                    </div>

                    {/* <iframe className="youtube" title="ABOUT VIDEO"
                        src="https://www.youtube.com/embed/M7lc1UVf?autoplay=1&origin=http://example.com" />

                    <div className="host-event-panel" ref={ref => this.hostRef = ref}>

                        <div className="left-panel">


                            <div className="event-title">HOST YOUR NEXT EVENT WITH US, COMPLETELY FREE.</div>

                            {
                                HostContent.map((content, index) => {
                                    return (
                                        <div key={index} className="panel-item">

                                            <img src={require('../../../../assets/images/events@3x_active.png')} alt="IMG-PLACEHOLDER" />
                                            <div>
                                                <div className="item-title">
                                                    {content.title}
                                                    <div className="bottom-indicate" />
                                                </div>
                                                <div className="item-content">{content.detail}</div>

                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="ctrl-panel">
                                <div className="button host-button" onClick={() => this.onContactUsTapped()}>Host Your Event</div>
                                <div className="text">Get started now. It's completely free.</div>
                            </div>
                        </div>

                        <div className="right-panel">
                            <img src={logo} className="panel-logo" alt="IMG-PLACEHOLDER" />
                        </div>

                    </div> */}

                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        hostEventState: selectors.getHostEventTappedState(state),
    }
}

export default connect(mapStateToProps)(withRouter(AboutContent));
