import React, { Component } from 'react'
import './MainHeader.scss'

import HeaderMenu                   from './header-menu.config';
import AppConfig                    from '../../../../config/app.config';
import AuthService                  from '../../../../services/auth.service';

import { connect }                  from 'react-redux';
import * as selectors               from '../../../../store/dashboard/reducer';
import * as actions                 from '../../../../store/dashboard/actions';


const MenuItems = HeaderMenu.menuItems;
const UserMenuItems = HeaderMenu.userMenuItems;


class MainHeader extends Component {

    constructor(props) {
        super(props)
        let index = this.setSelectedTab(props, true)
        
        this.state = {
            selIndex: index,
            showMenu: false,
            userMenu: false,
        }
        
        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    componentDidMount() {
        this.canUpdate = 1
        this.clickListener = () => {
            this.setState({
                showMenu: false,
                userMenu: false,
            })
        }

        this.unlisten = this.props.history.listen(() => {
            // console.log("Route Change");
            setTimeout(() => window.scrollTo(0, 0), 100);
        });

        window.addEventListener('click', this.clickListener)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickListener)
        if(this.unlisten) this.unlisten();
    }

    componentWillReceiveProps(nextProps) {
        this.setSelectedTab(nextProps)
        // console.log("Change Route", nextProps.location.pathname, this.props.location.pathname);
        if(nextProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    
    /*------------------------------------------------------------------*/
    // Check url for active menu icon

    setSelectedTab(props, first) {
        // Select active item index
        let url = props.location.pathname.toLowerCase()
        let index = url.indexOf('/');
        let segment = '';

        if(index > -1) {
            segment = url.substring(index);
        }

        let selIndex = -1
        let orgIndex = this.state ? this.state.selIndex : 0

        MenuItems.forEach((item, index) => {
            if(item.link.toLowerCase() === segment.toLowerCase()) {
                selIndex = index
            }
        })
        // console.log("Matched Index", selIndex, segment, url)

        if(!first && selIndex !== orgIndex) {
            this.setState({selIndex})
        }

        return selIndex
    }
    
    /*------------------------------------------------------------------*/
    // Action Callback

    onLogoTapped() {
        this.props.history.push('/home')
    }

    handleProfileTapped(evt) {
        evt.stopPropagation()
        let userMenu = !this.state.userMenu
        this.setState({
            userMenu,
            showMenu: false,
        })
    }

    handleMobileMenuItemTapped(evt) {
        evt.stopPropagation()
        this.setState({
            showMenu: !this.state.showMenu,
            userMenu: false,
        })
    }

    handleMenuItemTapped(item) {
        this.props.history.push(item.link)
        this.setState({showMenu: false})
    }

    handleUserMenuItemTapped(item) {
        this.setState({userMenu: false})

        if(item.link === 'LOG_OUT') {
            this.props.dispatch(actions.signOut());
        } else {
            this.props.history.push(item.link)
        }
    }

    onAppstoreTapped() {
        window.open(AppConfig[AppConfig.environment].appstore)
        this.setState({showMenu: false})
    }

    onPlaystoreTapped() {
        window.open(AppConfig[AppConfig.environment].playstore)
        this.setState({showMenu: false})
    }

    handleLogin() {
        this.props.dispatch(actions.setTryLogin(true));
    }

    handleHost() {
        let { location } = this.props.history;
        let { pathname } = location;

        if(pathname === '/about' || pathname === '/home') {
            this.props.dispatch(actions.setHostEventTapped(true));
        } else {
            this.props.history.push('/contact');
        }
    }

    handleFacebook() {
        window.open(AppConfig[AppConfig.environment].facebook)
    }


    /*------------------------------------------------------------------*/
    // Render

    renderMenu() {
        let { selIndex } = this.state

        return (
            <div style={{display: 'flex', height: '100%'}}>
                <ul>
                {
                    MenuItems.map((item, index) => {
                        return (
                            <li key={index} 
                                className={index === selIndex ? 'active': ''}
                                onClick={() => this.handleMenuItemTapped(item)}>
                                {item.title}
                            </li>
                        )
                    })
                }
                </ul>

            </div>
        )
    }

    renderUserMenu() {
        let menuItems = []
        let isSignIn = AuthService.isLoggedIn()

        UserMenuItems.forEach(item => {
            if(item.filter === isSignIn) {
                menuItems.push(item)
            }
        })

        return (
            <ul>
            {
                menuItems.map((item, index) => {
                    return (
                        <li key={index} onClick={() => this.handleUserMenuItemTapped(item)}>
                            {item.title}
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderUser() {
        let { userInfo } = this.props;
        if(!userInfo) {
            return null;
        }

        let { DisplayName } = userInfo;

        let { UserId } = userInfo;
        let logo;
        if(UserId === 'guest') {
            logo = require('../../../../assets/images/guest.png');
        } else {
            logo = require('../../../../assets/images/user.png');
        }

        return (
            <div className="user-info">
                <img src={logo} alt="USER-PROFILE" onClick={(evt) => this.handleProfileTapped(evt)} />
                {
                    this.state.userMenu &&
                    <div className="user-menu">
                        <div className="user-profile"><img src={logo} alt="USER-PROFILE" /> {DisplayName}</div>
                        {
                            UserMenuItems.map((item, index) => {
                                return (
                                    <li key={index} onClick={() => this.handleUserMenuItemTapped(item)}>
                                        <span className={"fa fa-" + item.icon} />
                                        {item.title}
                                    </li>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }

    render() {
        let config = AppConfig[AppConfig.environment];
        let { showMenu } = this.state;

        let appLogo = config.logo;
        let logo = require('../../../../assets/images/logo/' + appLogo);

        let { userInfo } = this.props;

        return (
            <header className="main-header">

                <div className="app-logo" title={config.title} onClick={() => this.onLogoTapped()}>
                    <img src={logo} alt="Prime Tickets Australia" className="site-logo-img"/>
                    <span className="sr-only">{config.title}</span>
                </div>

                <div className="header-menu">{this.renderMenu()}</div>
                
                <div style={{flex: 1}} />

                <div className="host-panel">
                    {/* <div className="button host-button" onClick={() => this.handleHost()}>Host Your Event</div> */}
                    { !userInfo && <div className="button login-button" onClick={() => this.handleLogin()}>Login</div> }
                    { userInfo && this.renderUser() }
                </div>

                {/* <div className="facebook">
                    <div className="button fb-like-button" onClick={() => this.handleFacebook()}>
                        <span className="fa fa-thumbs-up"></span> Like
                    </div>
                </div> */}
                
                <div className="mobile-only">
                    <div className="menu-icon" onClick={(evt) => this.handleMobileMenuItemTapped(evt)}>
                        <img src={require('../../../../assets/images/ic_menu_white.png')} alt="MENU_ICON" />
                    </div>
                    { showMenu && this.renderMenu() }
                </div>

            </header>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        userInfo:       selectors.getUserInfo(state),
    }
}

export default connect(mapStateToProps)(MainHeader);
