import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CarouselSlider from "react-carousel-slider";
import { toast } from 'react-toastify';
import './BannerSlider.scss';

class BannerSlider extends Component {

    state = {
        loading: false,
    };

    manner = {
        autoSliding: {interval: "5s"},
        duration: "2s"
    };

    dotsSettings = {
        style: {
            dotSize: "12px",
            currDotColor: '#FF0000',
            dotColor: '#888',
            marginTop: "0",
        }
    };

    itemsStyle = {
        width: '100%',
        height: '100%',
        margin: '1px 0',
    }

    buttonSetting = {
        placeOn: "middle-inside",
        hoverEvent: true,
        style: {
            left: {
                height: "50px",
                width: "50px",
                color: "#E0F0E8",
                background: "rgba(0, 0, 0, 0.8)",
                borderRadius: "50%"
            },
            
            right: {
                height: "50px",
                width: "50px",
                color: "#E0F0E8",
                background: "rgba(0, 0, 0, 0.8)",
                borderRadius: "50%"
            }
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////
    // Handle Functions

    componentWillReceiveProps(nextProps) {
        if(nextProps['items'].length !== this.props['items'].length) {
            this.setState({loading: true}, () => {
                setTimeout(() => this.setState({loading: false}), 100);
            });
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // Handle Functions

    handleItemTapped(item) {
        if(this.props.handleItemTapped) {
            this.props.handleItemTapped(item)
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // General Functions

    showToast(text) {
        toast.error(text);
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // Render Functions

    renderCarouselSlider(items) {
        if(this.props.cellRender) {
            return this.props.cellRender();
        } else {
            return items.map((item, index) => {
                return (
                    <div className="slider-item" key={index} onClick={() => this.handleItemTapped(item)}>
                        <img style={{cursor: 'pointer'}} src={item.imgSrc} alt="BANNER-IMG"></img>
                    </div>
                )
            });
        }
    }

    render() {
        let { items, manner } = this.props;
        let customCells = this.renderCarouselSlider(items);

        if(!manner) {
            manner = this.manner;
        }

        return (
            <div className="banner-container">
                <div className="banner-content">
                    {
                        items.length > 0 && !this.state.loading &&
                        <CarouselSlider 
                            slideCpnts={customCells} 
                            manner={manner} 
                            sliderBoxStyle={this.sliderBoxStyle}
                            itemsStyle={this.itemsStyle}
                            dotsSetting={this.dotsSettings}
                            buttonSetting={this.buttonSetting} />
                    }
                    { this.state.loading &&
                        <div className="banner-loader"><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i></div> }
                </div>
            </div>
        )
    }
}


BannerSlider.defaultProps = {
    items: [],
};
  
BannerSlider.propTypes = {
    items: PropTypes.array,
    manner: PropTypes.object,
    cellRender: PropTypes.func,
    handleItemTapped: PropTypes.func,
}


export default BannerSlider;
