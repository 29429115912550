import AppConfig from './app.config'

const config = {
    PRODUCTION: {
        apiKey: "AIzaSyB0ybA5qVOxQF48Ms2w-Q3oigMS18OYK5Q",
        authDomain: "ibazaarprod.firebaseapp.com",
        databaseURL: "https://ibazaarprod.firebaseio.com",
        projectId: "ibazaarprod",
        storageBucket: "ibazaarprod.appspot.com",
        messagingSenderId: "220911341539"
    },
    
    DEVELOPMENT: {
        apiKey: "AIzaSyCHZfuo4N6k17UyVJAy6YNvxftcL7tx1xA",
        authDomain: "ibazaarfirebase.firebaseapp.com",
        databaseURL: "https://ibazaarfirebase.firebaseio.com",
        projectId: "ibazaarfirebase",
        storageBucket: "ibazaarfirebase.appspot.com",
        messagingSenderId: "515197094179"
    },

    // DEVELOPMENT: {
    //     apiKey: "AIzaSyBwgeeSBw8gwH74bdwmCMrnRKbTPwtcgWg",
    //     authDomain: "profit-pullout-pro.firebaseapp.com",
    //     databaseURL: "https://profit-pullout-pro.firebaseio.com",
    //     projectId: "profit-pullout-pro",
    //     storageBucket: "profit-pullout-pro.appspot.com",
    //     messagingSenderId: "87168954493",
    //     appId: "1:87168954493:web:6e7b0c16ed52e60b"
    // },
}

export default config[AppConfig.environment || 'DEVELOPMENT']
