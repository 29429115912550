import React, { Component } from 'react'
import './ImageView.css'


class ImageView extends Component {

    onClose() {
        if(this.props.onClose) {
            this.props.onClose()
        }
    }

    onImageView(evt) {
        evt.stopPropagation()
    }

    render() {

        let {style, src} = this.props
        if(!style) style = {}
        
        let containerStyle = {}
        if(this.props.show) {
            containerStyle = 'visible'
        } else {
            containerStyle = ''
        }

        return (
            <div className={"img-backdrop " + containerStyle} onClick={() => this.onClose()}>
                <div style={style} className="image-view">
                    <div className="view-container">
                        <img className="img-container" 
                            src={src} 
                            alt="FULL-SIZE"
                            onClick={(evt) => this.onImageView(evt)} />
                        <div className="img-close-button"><img src={require('../../../assets/images/close.png')} alt="CLOSE-ICON" /></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImageView
