import React, { Component } from 'react'
import './IconTab.scss'
import { ClipLoader } from 'react-spinners'


class IconTab extends Component {

    state = {
        selIndex: 0,
    }

    onTabTapped(tab, index) {
        this.setState({selIndex: index})

        if(this.props.onTapped) {
            this.props.onTapped(tab, index)
        }
    }


    render() {
        let { selIndex } = this.state
        let { tabs, loading } = this.props

        return (
            <div className="tab-container">

                <ul>
                {
                    tabs.map((item, index) => {

                        let icon = index === selIndex ? 
                                    require('../../../assets/images/' + item.icon + '_active.png') :
                                    require('../../../assets/images/' + item.icon + '.png')
                        
                        return (
                        
                            <li key={index}
                                onClick={() => this.onTabTapped(item, index)}
                                className={index === selIndex ? 'active': ''}>

                                <img src={icon} alt="TAB-ICON" />
                                <span style={{marginRight: 5}}>{item.title}</span>
                                {
                                    loading && index === selIndex &&
                                    <ClipLoader sizeUnit={"px"} size={16} color="#ea2027" loading={true} />
                                }
                            </li>

                        )
                    })
                }
                </ul>

            </div>
        )
    }
}

export default IconTab
