export const LOADING_SET = 'dashboard.LOADING_SET'
export const ERROR_SET = 'dashboard.ERROR_SET'
export const TRY_LOGIN_SET = 'dashboard.TRY_LOGIN_SET'
export const HOST_EVENT_TAP_SET = 'dashboard.HOST_EVENT_TAP_SET'
export const AUTH_CHANGED_STATE_SET = 'dashboard.AUTH_CHANGED_STATE_SET'
export const USER_INFO_SET = 'dashboard.USER_INFO_SET'
export const HOSTED_EVENTS_SET = 'dashboard.HOSTED_EVENTS_SET'
export const DASHBOARD_CONTENT_SET = 'dashboard.DASHBOARD_CONTENT_SET'
export const SHOW_REPORT_SET = 'dashboard.SHOW_REPORT_SET'
export const COUPONS_SET = 'dashboard.COUPONS_SET'
