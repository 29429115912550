import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CarouselSlider from "react-carousel-slider";
import { toast } from 'react-toastify';
import './AdsSlider.scss';

class AdsSlider extends Component {

    state = {
        loading: false,
    };

    manner = {
        // autoSliding: {interval: "5s"},
        duration: "2s"
    };

    buttonSetting = {
        placeOn: "middle-inside",
        hoverEvent: true,
        style: {
            left: {
                display: 'none',
                height: "50px",
                width: "50px",
                color: "#929393",
                background: "rgba(0, 0, 0, 0.8)",
                borderRadius: "50%"
            },
            
            right: {
                display: 'none',
                height: "50px",
                width: "50px",
                color: "#929393",
                background: "rgba(0, 0, 0, 0.8)",
                borderRadius: "50%"
            }
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////
    // Handle Functions

    componentWillReceiveProps(nextProps) {
        if(nextProps['items'].length !== this.props['items'].length) {
            this.setState({loading: true}, () => {
                setTimeout(() => this.setState({loading: false}), 100);
            });
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // Handle Functions

    handleItemTapped(item) {
        if(this.props.handleItemTapped) {
            this.props.handleItemTapped(item)
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // General Functions

    showToast(text) {
        toast.error(text);
    }


    //////////////////////////////////////////////////////////////////////////////////////////
    // Render Functions

    renderCarouselSlider(items) {
        if(this.props.cellRender) {
            return this.props.cellRender();
        } else {
            return items.map((item, index) => {
                return (
                    <div className="ads-slider-item" key={index} onClick={() => this.handleItemTapped(item)}>
                        <img style={{cursor: 'pointer'}} src={item} alt="BANNER-IMG"/>
                    </div>
                )
            });
        }
    }

    render() {
        let { items, manner } = this.props;
        let customCells = this.renderCarouselSlider(items);

        if(!manner) {
            manner = this.manner;
        }

        return (
            <div className="ads-slider">
                {
                    items.length > 0 && !this.state.loading &&
                    <CarouselSlider 
                        slideCpnts={customCells} 
                        manner={manner} 
                        buttonSetting={this.buttonSetting} />
                }
                { this.state.loading &&
                    <div className="banner-loader"><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i></div> }
            </div>
        )
    }
}


AdsSlider.defaultProps = {
    items: [],
};
  
AdsSlider.propTypes = {
    items: PropTypes.array,
    manner: PropTypes.object,
    cellRender: PropTypes.func,
    handleItemTapped: PropTypes.func,
}


export default AdsSlider;
