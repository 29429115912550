import React, { Component } from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import './AppRouter.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase';

import NotFound from '../views/pages/NotFound';
import MainView from '../views/pages/Main';
import DashboardView from '../views/pages/Dashboard';

import { connect }              from 'react-redux';
import * as homeSelectors       from '../store/home/reducer';
import * as homeActions         from '../store/home/actions';
import * as dashboardSelectors  from '../store/dashboard/reducer';
import * as dashboardActions    from '../store/dashboard/actions';

class App extends Component {

    constructor(props) {
        super(props);

        this.props.dispatch(homeActions.loadAds());

        this.path = window.location.pathname;
         
        firebase.auth().onAuthStateChanged(user => {
            console.log("AUTH CHANGE ON ROUTER", user)
            
            setTimeout(() => {
                let { userInfo } = this.props;

                if(user && !userInfo) {
                    this.props.dispatch(homeActions.loadUser());
                    this.props.dispatch(dashboardActions.loadUser());
                } else {
                    this.props.dispatch(dashboardActions.setAuthChangedState(true));
                }    
            }, 100);
        });
    }

    componentDidMount() {        
        this.props.dispatch(homeActions.loadCategories());
        this.props.dispatch(homeActions.loadSubCategories(() => this.loadEvents()));
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userState !== this.props.userState && nextProps.userState === true) {
            console.log(nextProps)
            // this.props.dispatch(actions.signOut())
            // toast("You should create your account!", { autoClose: 3000 })
        }
    }

    loadEvents() {        
        this.props.dispatch(homeActions.loadAllEvents());
        console.log(this.props)
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">

                    <Switch>
                        <Route path="/home"         render={(props) => <MainView {...props} />} />                        
                        <Route path="/event"        render={(props) => <MainView {...props} />} />
                        <Route path="/events"       render={(props) => <MainView {...props} />} />
                        <Route path="/past"         render={(props) => <MainView {...props} />} />
                        <Route path="/faq"          render={(props) => <MainView {...props} />} />
                        <Route path="/confirm"      render={(props) => <MainView {...props} />} />
                        <Route path="/about"        render={(props) => <MainView {...props} />} />
                        <Route path="/contact"      render={(props) => <MainView {...props} />} />
                        <Route path="/privacy"      render={(props) => <MainView {...props} />} />
                        <Route path="/sitemap"      render={(props) => <MainView {...props} />} />
                        <Route path="/terms"        render={(props) => <MainView {...props} />} />
                        <Route path="/terms"        render={(props) => <MainView {...props} />} />
                        <Route path="/mytickets"    render={(props) => <MainView {...props} />} />
                        <Route path="/order"        render={(props) => <MainView {...props} />} />

                        <Route path="/dashboard"    render={(props) => <DashboardView {...props} />} />

                        <Route render={(props) => <NotFound {...props} />} />
                    </Switch>

                    <ToastContainer position="top-center" hideProgressBar pauseOnHover={false} />

                </div>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return {
        userState:          homeSelectors.getUserState(state),
        authChangedState:   dashboardSelectors.getAuthChangedState(state),
        userInfo:           homeSelectors.getLoginInfo(state),
    }
}

export default connect(mapStateToProps)(App);
