export default [{
    nested: '/home',
    count: 1,
}, {
    nested: '/events',
    count: 1,
}, {
    nested: '/past',
    count: 1,
}, {
    nested: '/faq',
    count: 1,
}, {
    nested: '/event',
    count: 2,
}, {
    nested: '/event',
    count: 3,
}, {
    nested: '/confirm',
    count: 2,
}, {
    nested: '/dashboard',
    count: 1,
}, {
    nested: '/about',
    count: 1,
}, {
    nested: '/contact',
    count: 1,
}, {
    nested: '/terms',
    count: 1,
}, {
    nested: '/privacy',
    count: 1,
}, {
    nested: '/mytickets',
    count: 1,
}, {
    nested: '/order',
    count: 4,
}, {
    nested: '/order',
    count: 3,
}];
