import Emitter from 'tiny-emitter'
const emitter = new Emitter()

export default class EventService {

    static setListener(type, callback) {
        if (callback) {
            emitter.on(type, callback)
        }
    }
    
    static removeListener(type, callback) {
        if (callback) {
            emitter.off(type, callback)
        }
    }

    static emitChange(type) {
        emitter.emit(type)
    }
}