import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './PaymentsContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
import { toast }                from 'react-toastify';
import Utils                    from '../../../../services/utils';
import { firestore }            from '../../../../services/firebase.service';
import PaymentListHeader        from './header.list';


class PaymentsContent extends Component {

    constructor(props) {
        super(props);

        this.payments = [];
        this.results = [];
        this.total = 0;

        this.search = PaymentListHeader.map(header => {
            if(header.type === 'number') {
                return {
                    ...header,
                    value: 0,
                    condition: 'NC',
                }
            } else {
                return {
                    ...header,
                    value: '',
                    condition: 'CONTAIN',
                }
            }
        });

        this.loadData();
    }

    loadData() {
        let { listId } = this.props.match.params;
        
        this.props.dispatch(actions.setLoading(true));
        firestore.collection('Payments')
            .where('ListId', '==', listId)
            .where('Status', '==', true)
            .orderBy('LastUpdated', 'desc')
            .get()
            .then(snapshot => {
                this.payments = snapshot.docs.map(doc => {
                    let data = doc.data();
                    let { LastUpdated } = data;
                    let dateAndTIme = `${this.formatDate(LastUpdated.seconds * 1000)}`;

                    return {
                        ...data,
                        DisplayName: data.BuyerUser.DisplayName,
                        Email: data.BuyerUser.Email,
                        PhoneNumber: data.BuyerUser.PhoneNumber,
                        DateTime: dateAndTIme,
                    }
                });
                this.results = this.payments.map(payment => payment);
                console.log("Loaded hosted event content", this.payments)
                
                this.total = 0;
                this.payments.forEach(item => {
                    let { PaymentTotal } = item;
                    let total = !PaymentTotal ? 0 : Number(PaymentTotal);
                    this.total += total;
                });
                
                this.props.dispatch(actions.setLoading(false));
            })
            .catch(error => {
                console.log("Error on load hosted event content", error);
                this.props.history.push('/dashboard/summary');
                this.props.dispatch(actions.setLoading(false));
            })        
    }

    handleSearch(field, evt) {
        // console.log(field, evt.target.value);

        let value = evt.target.value;
        field.value = value.toLowerCase();

        this.results = [];
        this.payments.forEach(payment => {
            let objValue = '';
            if(payment[field.key]) {
                if(field.type === 'text') {
                    objValue = payment[field.key].toLowerCase();
                } else if(field.type === 'number') {
                    objValue = Number(payment[field.key]);
                } else {
                    objValue = payment[field.key];
                }
            }

            if(field.type === 'text') {
                if(objValue.includes(field.value)) {
                    this.results.push(payment);
                }
            } else if(field.type === 'boolean') {
                if(objValue.toString() === field.value || field.value === 'all') {
                    this.results.push(payment);
                }
            } else if(field.type === 'number') {
                let operator = 
                    field.value.includes('>=') ? 'GE' :
                    field.value.includes('<=') ? 'LE' :
                    field.value.includes('>') ? 'GT' :
                    field.value.includes('<') ? 'LT' :
                    field.value.includes('=') ? 'EQ' :
                    'NONE';

                let value = field.value;
                value = value.replace(/=/g, '');
                value = value.replace(/>/g, '');
                value = value.replace(/</g, '');
                value = value.trim();

                if(value === '') operator = 'NONE';
                if(isNaN(value)) operator = 'NONE';

                value = Number(value);

                if(operator === 'NONE') {
                    this.results.push(payment);
                } else if(operator === 'GE' && objValue >= value) {
                    this.results.push(payment);
                } else if(operator === 'LE' && objValue <= value) {
                    this.results.push(payment);
                } else if(operator === 'GT' && objValue > value) {
                    this.results.push(payment);
                } else if(operator === 'LT' && objValue < value) {
                    this.results.push(payment);
                } else if(operator === 'EQ' && objValue === value) {
                    this.results.push(payment);
                }
            } else if(field.type === 'items') {
                let value = field.value.toLowerCase();

                for(let i = 0; i < payment.Items.length; i++) {
                    let item = payment.Items[i];
                    let title = item.ItemTitle.toLowerCase();

                    if(title.includes(value)) {
                        this.results.push(payment);
                        break;
                    }

                    if(item.SelectedSeats) {
                        for(let j = 0; j < item.SelectedSeats.length; j++) {
                            let seat = item.SelectedSeats[j].toLowerCase();

                            if(seat.includes(value)) {
                                this.results.push(payment);
                                return;
                            }        
                        }
                    }
                }
            } else if(field.type === 'items-status') {
                let value = field.value.toLowerCase();

                for(let i = 0; i < payment.Items.length; i++) {
                    let item = payment.Items[i];
                    let title = item.Status.toLowerCase();

                    if(title.includes(value)) {
                        this.results.push(payment);
                        break;
                    }
                }
            }
        });

        this.forceUpdate();
    }

    handleShowPayments(paymentId) {
        console.log("Payment Id", paymentId);
        let { listId } = this.props.match.params;
        this.props.history.push(`/dashboard/${listId}/ticket?ref=${paymentId}`);
    }


    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }

    /*-----------------------------------------------------------*/
    // Action Callback

    formatDate(date) {
        let dateObj = new Date(date)
        return `${Utils.getDateStringOf(dateObj)} ${Utils.getTimeStringOf(dateObj)}`;
    }

    onPrintTapped(divRef) {
        console.log(window.html2canvas)
        window.html2canvas(document.querySelector(divRef)).then(canvas => {
            let wndNew = window.open()
            wndNew.document.body.appendChild(canvas)
            wndNew.focus()
            wndNew.print()
            wndNew.close()
        });
    }


    /*-----------------------------------------------------------*/
    // Render

    safeString(str) {
        if(!Utils.isEmptyString(str)) return str;
        return ' - ';
    }

    renderOrder(payment, idx) {

        let { PaymentTotal, BuyerUser, Items, PaymentId, Status, DateTime } = payment;
        let { DisplayName, Email, PhoneNumber } = BuyerUser;

        if(!PaymentTotal) {
            PaymentTotal = 0
        } else {
            PaymentTotal = Number(PaymentTotal)
        }

        let info = [DisplayName, Email, PhoneNumber, Status ? 'Success' : 'Failed', payment.CouponCode, payment.Notes, DateTime, `${PaymentTotal.toFixed(2)}`, payment.Notes];

        return ( 
            <tr key={idx}>
                <td><div className="link" onClick={() => this.handleShowPayments(PaymentId)}>{this.safeString(PaymentId)}</div></td>
                { info.map((item, index) => <td key={index}>{this.safeString(item)}</td>) }
                <td>
                { 
                    Items.map((item, idxItem) => {
                        if (item['SelectedSeats']) {
                            return (
                                <div key={idxItem}>
                                    { item['SelectedSeats'] && item['SelectedSeats'].map((item, idxSeat) => <div key={idxSeat}>{item}</div>) }
                                    { item['ItemSelected']} X {item['ItemTitle'] }
                                </div>
                            )
                        } else {
                            return (<div>{ item['ItemSelected']} X {item['ItemTitle'] }</div>)
                        }
                    })
                }
                </td>
                <td>
                { 
                    Items.map((item, idxItem) => {
                        return (
                            <div key={idxItem}>{ item['Status']}</div>
                        )
                    })
                }
                </td>
            </tr>
        )
    }

    render() {
        let payments = this.results;
        if(!payments) payments = [];
        let { loading } = this.props;

        return (
            <div className="payments-container">
                {
                    loading && 
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }
                {
                    this.payments.length === 0 &&
                    <div style={{fontSize: 24, padding: 20, paddingTop: 100}}>No Orders Yet!</div>
                }
                { this.payments.length > 0 && <div className="total-amount">Total Payment : ${this.total.toFixed(2)}</div> }
                <div className="table-container">
                {
                    this.payments.length > 0 &&
                    <table style={{width: '100%', overflowX: 'auto'}}><tbody style={{width: '100%', overflowX: 'auto'}}>
                        <tr className="header">{ this.search.map((item, index) => <td key={index}>{item.title}</td>) }</tr>
                        <tr className="search">
                            {
                                this.search.map((item, index) => {
                                    if(item.type === 'boolean') {
                                        return (
                                            <td style={{padding: 5}} key={index}>
                                                <select className="search-input" onChange={evt => this.handleSearch(item, evt)}>
                                                    <option value="all">All</option>
                                                    <option value="true">Success</option>
                                                    <option value="false">Failed</option>
                                                </select>
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td style={{padding: 5}} key={index}>
                                                <input type="text" className="search-input" 
                                                    onChange={(evt) => this.handleSearch(item, evt)} />
                                            </td>
                                        )
                                    }
                                })
                            }
                        </tr>
                        { payments.length > 0 && payments.map((payment, index) => this.renderOrder(payment, index)) }
                        { payments.length === 0 && <tr><td colSpan={10}>No Result!</td></tr> }
                    </tbody></table>
                }
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:            selectors.getLoading(state),
        error:              selectors.getError(state),
        dashboardContent:   selectors.getDashboardContent(state),
    }
}

export default connect(mapStateToProps)(withRouter(PaymentsContent));
