import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './TicketContent.scss';
import firebase from 'firebase';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';
import { toast }                from 'react-toastify';
import Utils                    from '../../../../services/utils';
import { TicketTemplate }       from '../../../compnents';


class TicketContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            doc: null,
            loading: true,
        }

        this.loadOrder();
    }


    /*-----------------------------------------------------------*/
    // Load data

    loadOrder() {
        let { listId, id, index } = this.props.match.params;
        console.log(listId, id, index)

        this.setState({loading: true})
        
        firebase.firestore().collection("Lists")
            .doc(listId)
            .get()
            .then(snapshot => {
                let doc = snapshot.data();
                let { OrderCollection } = doc;

                if (!OrderCollection) {
                    this.collection = 'Payments';
                } else {
                    this.collection = OrderCollection;
                }

                console.log("List Detail", doc);
                let orderId = index ? `${id}_${index}` : id;

                return firebase.firestore().collection(this.collection).doc(orderId).get();
            })
            .then(snapshot => {
                this.paymentObject = snapshot.data();
                console.log("Payment Object", this.paymentObject);
                return firebase.firestore().collection(this.collection).doc(id + '_' + index).get();
            })
            .then(snapshot => {
                let doc = snapshot.data();
                console.log("DOC", doc);
                this.setState({
                    loading: false,
                    doc
                });
            })
            .catch(err => {
                console.log("Error on load order", err);
                this.showToast(err.toString());
            });
    }

    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }

    /*-----------------------------------------------------------*/
    // Action Callback

    formatDate(date) {
        let dateObj = new Date(date.seconds * 1000)
        if(dateObj === 'Invalid Date') {
            return 'Invalid Date'
        }

        return Utils.getDateStringOf(dateObj);
    }

    formatTime(date) {
        let dateObj = new Date(date.seconds * 1000)
        if(dateObj === 'Invalid Date') {
            return 'Invalid Date'
        }

        return Utils.getTimeStringOf(dateObj);
    }

    onPrintTapped(divRef) {
        console.log(window.html2canvas)
        window.html2canvas(document.querySelector(divRef)).then(canvas => {
            let wndNew = window.open()
            wndNew.document.body.appendChild(canvas)
            wndNew.focus()
            wndNew.print()
            wndNew.close()
        });
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {
        let { loading } = this.state;

        return (
            <div className="ticket-detail-container">
            { 
                !loading ? 
                <div id='order-div' className="ticket-container" ref={ref => this.panel = ref}>
                    {/* <img className="print-icon" alt="PRINT" 
                        src={require('../../../../assets/images/print.png')} 
                        onClick={() => this.onPrintTapped('#order-div')} /> */}
    
                    <TicketTemplate object={this.paymentObject} />
                </div> : 
                <div className="loading-view">
                    <div>Ticket is loading..</div>
                    <div style={{display: 'inline-block'}}><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>
                </div>
            }
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        loginInfo:      selectors.getLoginInfo(state),
    }
}

export default connect(mapStateToProps)(withRouter(TicketContent));
