export default [{
    icon: require('../../../../assets/images/handpoint.png'),
    title: 'Easy, Intuitive and Quick',
    detail: `PRIMETickets is the latest and most innovative app on the market space!
    With a goal to make events more fun, stress free, we set out to develop the perfect app - for both businesses and users.
    Built on the latest technology, our app is developed to give the user a fast and crash-free experience - from browsing to checkout!`,
}, {
    icon: require('../../../../assets/images/seat.png'),
    title: 'Dynamic Seating Editor',
    detail: 'Deliver an outstanding ticket buying experience. We make reserved seating easy for you. Draw large venues in a few hours. No coding required.',
}, {
    icon: require('../../../../assets/images/charts.png'),
    title: 'Charts, Trends and Analytics',
    detail: 'Manage smarter with 24/7 access to data. Track sales in real time from any device with 24/7 access to more than 15 different reports and chart views.',
}]
