import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './CouponContent.scss';

import {
    InputForm,
    CheckBox,
    Modal,
} from '../../../compnents';
import DatePicker               from 'react-date-picker';
import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
import Utils                    from '../../../../services/utils';
import { toast }                from 'react-toastify';
import { firestore }            from '../../../../services/firebase.service';


class CouponContent extends Component {

    constructor(props) {
        super(props)

        this.coupons = {};

        this.state = {
            Code: '',
            EndDate: '',
            StartDate: '',
            Value: 0,
            process: false,
            error: '',
            update: '',

            item: '',

            change: '',
            showUpdate: false,
        }

        this.items = [{title: '', index: 0, checked: false}];

        this.loadDetails();
        this.loadData();
    }


    /*-----------------------------------------------------------*/
    // Load data

    loadData() {

        let { listId } = this.props.match.params;
        this.props.dispatch(actions.setLoading(true));

        firestore.collection('Lists')
            .doc(listId)
            .get()
            .then(snapshot => {
                let item = snapshot.data();
                this.CouponCollection = item.CouponCollection;
                console.log("Loaded list content", item);
                this.props.dispatch(actions.setLoading(false));
                this.loadCoupons();
            })
            .catch(error => {
                console.log("Error on load coupons", error)
                this.props.dispatch(actions.setLoading(false));
            })

    }

    loadDetails() {

        let { listId } = this.props.match.params;
        this.props.dispatch(actions.setLoading(true));

        firestore.collection('Details')
            .where('ListId', '==', listId)
            .get()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    let data = doc.data();
                    this.items = [];
                    data.Items.forEach(item => {
                        if(item['ItemType'] === 'SALE') {
                            this.items.push({
                                type: item.DocId,
                                index: this.items.length,
                                title: item.ItemTitle,
                                checked: false,
                            });
                        }
                    });
                });

                this.props.dispatch(actions.setLoading(false));
            })
            .catch(error => {
                console.log("Error on load coupons", error)
                this.props.dispatch(actions.setLoading(false));
            })
    }

    loadCoupons() {
        if(Utils.isEmptyString(this.CouponCollection)) {
            this.props.dispatch(actions.setLoading(false));
            return;
        }

        firestore.collection(this.CouponCollection)
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    let key = change.doc.id
                    let doc = change.doc.data()
                    let data = {
                        ...doc,
                        docId: key,
                    }

                    if (change.type === "added" || change.type === "modified") {
                        this.coupons[key] = data
                    }

                    if (change.type === "removed") {
                        delete this.coupons[key];
                    }

                    this.forceUpdate();
                })
            })
    }

    showToast(text) {
        toast.error(text)
    }

    /*-----------------------------------------------------------*/
    // Events

    onChange(key, value) {
        let state = this.state
        state[key] = value
        state['error'] = ''
        this.setState(state)
    }

        onUpdateCoupon(label, description, key, coupon) {
        if(!coupon.Active) {
            this.showToast("Coupon is inactive!")
            return
        }

        this.changeLabel = label
        this.changeDescription = description

        this.updateCoupon = {
            key,
            coupon
        }

        let value = coupon[key]

        if(key === 'StartDate' || key === 'EndDate') {
            this.type = 'DATE'
            value = value.toDate();
        } else {
            this.type = 'NO_DATE'
        }

        console.log(value, this.type);

        this.setState({
            change: value,
            showUpdate: true,
        })
    }

    onCouponChange(key, value, coupon) {
        console.log(key, value, coupon)

        let change
        if(key === 'StartDate' || key === 'EndDate') {
            change = new Date(value)

            if(isNaN(change.getTime())) {
                this.showToast("Enter a valid value!")
                return
            }
        } else if(key === 'Active') {
            change = value
        } else {
            change = Number(value)
            if(isNaN(change)) {
                this.showToast("Enter a valid value!")
                return
            }
        }

        if(this.state.update.length > 0) {
            this.showToast("Now in updating!")
            return
        }

        let { userInfo } = this.props;
        let { UserId } = userInfo;

        let data = {
            LastUpdated: new Date(),
            LastUpdatedBy: UserId,
        }
        data[key] = change

        this.setState({
            update: coupon.Code,
            showUpdate: false,
        })

        firestore.collection(this.CouponCollection)
            .doc(coupon.docId)
            .update(data)
            .then(() => {
                this.setState({update: ''})
            })
            .catch(err => {
                this.showToast("Error on updating!")
                console.log("Error updating", err)
            })
    }

    onSelectItem(item) {
        console.log("Selected Item", item);
        this.setState({item: item.type});
    }

    onCreateCoupon() {
        let start = this.state.StartDate
        let end = this.state.EndDate

        let { userInfo } = this.props
        if(!this.CouponCollection) {
            this.showToast("Please contact admin!")
            return
        }

        let code = this.state.Code
        
        if(!code || code.length === 0) {
            this.showToast("Coupon code should be valid!")
            return
        }

        if(code.length < 3) {
            this.showToast("Coupon code should be at least 3 characters!")
            return
        }

        let coupons = Object.keys(this.coupons).map(key => this.coupons[key]);

        for(let i = 0; i < coupons.length; i++) {
            let coupon = coupons[i]
            if(coupon.Code.toLowerCase() === code.toLowerCase()) {
                this.showToast("Code already exists!")
                return
            }
        }

        if(start === 'Invalid Date' || !start) {
            this.showToast("Please input Valid Start Date!")
            return
        }

        if(end === 'Invalid Date' || !end) {
            this.showToast("Please input Valid End Date!")
            return
        }

        if(this.state.SerialNumStart && isNaN(Number(this.state.SerialNumStart))) {
            this.showToast("Serial Number Start should be valid number!")
            return
        }

        if(this.state.SerialNumEnd && isNaN(Number(this.state.SerialNumEnd))) {
            this.showToast("Serial Number End should be valid number!")
            return
        }

        if(isNaN(Number(this.state.CouponCap))) {
            this.showToast("Total cap should be valid number!")
            return
        }

        if(isNaN(Number(this.state.CouponValue))) {
            this.showToast("Coupon Value should be valid number!")
            return
        }

        if(isNaN(Number(this.state.TicketMax))) {
            this.showToast("Ticket Max should be valid number!")
            return
        }

        let selected = false;
        for(let i = 0; i < this.items.length; i++) {
            let item = this.items[i];
            if(item.checked) {
                selected = true;
                break;
            }
        }

        if(!selected) {
            this.showToast("P;ease select at least one item for coupon!")
            return
        }

        let { UserId } = userInfo

        this.setState({process: true})
        let coupon = {
            Active: true,
            Code: this.state.Code,
            CheckSerialNum: this.state.CheckSerialNum ? this.state.CheckSerialNum : false,
            CouponCap: Number(this.state.CouponCap),
            CouponUsed: 0,
            CouponValue: Number(this.state.CouponValue),
            CouponItem: this.state.item,
            StartDate: start,
            EndDate: end,
            LastUpdated: new Date(),
            LastUpdatedBy: UserId,
            SerialNumEnd: this.state.SerialNumEnd ? Number(this.state.SerialNumEnd) : 0,
            SerialNumStart: this.state.SerialNumStart ? Number(this.state.SerialNumStart) : 0,
            TicketMax: Number(this.state.TicketMax),
            Items: this.items,
        }

        console.log(UserId, this.CouponCollection, coupon, this.state.code)

        firestore.collection(this.CouponCollection)
            .doc(this.state.Code)
            .set(coupon)
            .then(result => {
                this.setState({process: false})
                console.log("Creating coupon result", result)
                this.setState({
                    Code: '',
                    StartDate: null,
                    EndDate: null,
                    CheckSerialNum: false,
                    SerialNumStart: 0,
                    SerialNumEnd: 0,
                    CouponCap: 0,
                    CouponValue: 0,
                    TicketMax: 0,
                });

                for(let i = 0; i < this.items.length; i++) {
                    this.items[i]['checked'] = false;
                }

                this.forceUpdate();
            })
            .catch(error => {
                this.setState({process: false})
                console.log("Error on creating coupon", error)
            })
    }

    onShowOrders(coupon) {
        // let code = coupon.Code
        // this.props.history.push('/dashboard/order/' + code)
    }

    dateOf(timestamp) {
        let date;
        if(timestamp.toDate) {
            date = timestamp.toDate();
        } else {
            date = new Date(timestamp);
        }
        let str = Utils.getDateStringOf(date);
        return str;
    }

    onChangeCheck(index, check) {
        this.items[index]['checked'] = check;
    }


    /*-----------------------------------------------------------*/
    // Render
    render() {
        let { loading } = this.props;
        let coupons = Object.keys(this.coupons).map(key => this.coupons[key]);

        return (
            <div className="coupon-container">
                       
                {
                    loading && 
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="content">

                    <div className="module">
                        <div className="head-title">Manage your promo codes</div>
                        <div className="module-body dash-coupon">
                            <form className="form-horizontal row-fluid">

                                <div className="input-paragraph">
                                    <div style={{display: 'flex'}}>
                                        <InputForm style={{flex: 2}} label="Enter New Code"
                                            onChange={value => this.onChange('Code', value)} 
                                            value={this.state.Code}
                                            labelStyle={{flex: 2, textAlign: 'right'}}
                                            description="Minimum 3 Characters" />

                                        <InputForm style={{flex: 1}} label="Start Date"
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            description="" >

                                            <DatePicker
                                                onChange={value => this.onChange('StartDate', value)} 
                                                value={this.state.StartDate} />

                                        </InputForm>

                                        <InputForm style={{flex: 1}} label="End Date"
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            description="" >

                                            <DatePicker
                                                onChange={value => this.onChange('EndDate', value)} 
                                                value={this.state.EndDate} />

                                        </InputForm>

                                    </div>
                                </div>

                                <div className="input-paragraph" style={{textAlign: 'left'}}>

                                    <CheckBox label="Is Coupon code having serial number?"
                                        checked={this.state.CheckSerialNum}
                                        style={{display: 'inline-block'}}
                                        onChange={checked => this.onChange('CheckSerialNum', checked)} />

                                    <div style={{display: 'flex', alignItems: 'center'}}>

                                        <InputForm style={{flex: 1}} label="Serial Start"
                                            disabled={!this.state.CheckSerialNum}
                                            onChange={value => this.onChange('SerialNumStart', value)} 
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            value={this.state.SerialNumStart}
                                            description="Number" />

                                        <InputForm style={{flex: 1}} label="Serial End"
                                            disabled={!this.state.CheckSerialNum}
                                            onChange={value => this.onChange('SerialNumEnd', value)} 
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            value={this.state.SerialNumEnd}
                                            description="Number" />

                                    </div>
                                </div>

                                <div className="input-paragraph">
                                    <div style={{display: 'flex'}}>

                                        <InputForm style={{flex: 1}} label="Total Cap"
                                            onChange={value => this.onChange('CouponCap', value)} 
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            value={this.state.CouponCap}
                                            description="Number" />

                                        <InputForm style={{flex: 1}} label="Value ($)"
                                            onChange={value => this.onChange('CouponValue', value)} 
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            value={this.state.CouponValue}
                                            description="Number" />

                                        <InputForm style={{flex: 1}} label="Max Per Transaction"
                                            onChange={value => this.onChange('TicketMax', value)} 
                                            labelStyle={{flex: 3, textAlign: 'right'}}
                                            value={this.state.TicketMax}
                                            description="Number" />

                                    </div>
                                </div>


                                <div className="input-paragraph" style={{textAlign: 'left'}}>
                                    <div style={{fontSize: 14}}>Select your item</div>
                                    {
                                        this.items.map((item, index) => {
                                            return (
                                                <CheckBox key={index} 
                                                    label={item.title}
                                                    checked={item.checked}
                                                    style={{display: 'inline-block', marginLeft: 20}}
                                                    onChange={checked => this.onChangeCheck(index, checked)} />
                                            )
                                        })
                                    }
                                </div>

                                { !this.state.error && <div style={{marginTop: 10}}>{this.state.error}</div> }

                                <div className="control-group" style={{marginBottom: 10}}>
                                    <div className="controls">
                                        <div style={{width: 160, margin: 'auto'}} className="button" onClick={() => this.onCreateCoupon()}>
                                            { this.state.process && <i className="fa fa-refresh fa-spin fa-1x fa-fw"></i> }
                                            Create
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <p style={{marginTop: 10}}>
                            <strong>
                                Issued Promo codes
                                {this.state.update.length > 0 && <i className="fa fa-spinner fa-spin fa-1x fa-fw"></i>}
                            </strong>
                        </p>
                        <table className="table table-bordered">
                            <thead style={{textAlign: 'center'}}>
                                <tr>
                                <th>#</th>
                                <th>Status</th>
                                <th>Code</th>
                                <th>Serial</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Used</th>
                                <th>Toal Cap</th>
                                <th>Value (%)</th>
                                <th>Ticket Max</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                coupons.map((coupon, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="active-value" 
                                                    onClick={() => this.onCouponChange('Active', !coupon.Active, coupon)}>
                                                    {coupon.Active ? 'Active' : 'Inactive'}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="active-value" style={{color: 'green'}}
                                                    onClick={() => this.onShowOrders(coupon)}>
                                                    {coupon.Code}
                                                </div>
                                            </td>
                                            <td>
                                            {
                                                coupon.CheckSerialNum ? 
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <div className="active-value" 
                                                            onClick={() => this.onUpdateCoupon('Serial Start Number', 'Number', 'SerialNumStart', coupon)}>
                                                            {coupon.SerialNumStart}
                                                        </div>
                                                        &nbsp;~&nbsp;
                                                        <div className="active-value"
                                                            onClick={() => this.onUpdateCoupon('Serial End Number', 'Number', 'SerialNumEnd', coupon)}>
                                                            {coupon.SerialNumEnd}
                                                        </div>
                                                    </div> :
                                                    'NO'
                                            }
                                            </td>
                                            <td>
                                            {
                                                coupon.StartDate ? 
                                                    <div className="active-value" 
                                                        onClick={() => this.onUpdateCoupon('Start Date', 'yyyy/mm/dd', 'StartDate', coupon)}>
                                                        {this.dateOf(coupon.StartDate)}
                                                    </div> :
                                                    ''
                                            }
                                            </td>
                                            <td>
                                            {
                                                coupon.EndDate ? 
                                                <div className="active-value" 
                                                    onClick={() => this.onUpdateCoupon('End Date', 'yyyy/mm/dd', 'EndDate', coupon)}>
                                                    {this.dateOf(coupon.EndDate)}
                                                </div> :
                                                ''
                                            }
                                            </td>
                                            <td>{coupon.CouponUsed}</td>
                                            <td>
                                                <div className="active-value" 
                                                    onClick={() => this.onUpdateCoupon('CouponCap', 'Number', 'CouponCap', coupon)}>
                                                    {coupon.CouponCap}
                                                </div>
                                            </td>
                                            <td>{coupon.CouponValue}</td>
                                            <td>
                                                <div className="active-value" 
                                                    onClick={() => this.onUpdateCoupon('TicketMax', 'Number', 'TicketMax', coupon)}>
                                                    {coupon.TicketMax}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                        <br />
                        
                        <br />
                    </div>
                </div>

                <Modal show={this.state.showUpdate} onClose={() => this.setState({showUpdate: false})}>
                    {
                        this.type === 'DATE' ?
                            <InputForm label={this.changeLabel} 
                                labelStyle={{flex: 4}}
                                description={this.changeDescription} >

                                <DatePicker
                                    onChange={value => this.onChange('change', value)}
                                    value={this.state.change} />

                            </InputForm> :
                            <InputForm label={this.changeLabel} 
                                labelStyle={{flex: 4}}
                                description={this.changeDescription} 
                                value={this.state.change} 
                                onChange={value => this.onChange('change', value)} />
                    }

                    <div style={{width: 160, margin: 'auto'}} className="button"
                        onClick={() => this.onCouponChange(this.updateCoupon.key, this.state.change, this.updateCoupon.coupon)}>
                        Update
                    </div>
                </Modal>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:            selectors.getLoading(state),
        error:              selectors.getError(state),
        userInfo:           selectors.getUserInfo(state),
    }
}

export default connect(mapStateToProps)(withRouter(CouponContent));
