export default {
    menuItems: [{
        title: 'Summary',
        link: '/dashboard/summary',
    }, {
        title: 'Home',
        link: '/home',
    }],

    userMenuItems: [{
        title: 'My Tickets',
        link: '/mytickets',
        icon: 'book',
    }, {
        title: 'Sign out',
        link: 'LOG_OUT',
        icon: 'sign-out',
    }]
}