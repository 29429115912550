import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './EmptyContent.scss';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';


class EmptyContent extends Component {

    /*-----------------------------------------------------------*/
    // Action Functions


    
    /*-----------------------------------------------------------*/
    // Render

    render() {

        return (
            <div className="empty-content" >
                
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
    }
}

export default connect(mapStateToProps)(withRouter(EmptyContent));
