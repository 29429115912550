import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './TermsContent.scss'
import firebase from 'firebase';
import { toast } from 'react-toastify';
import termsText from './terms';


class TermsContent extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        content: '',
        processing: false,
    }


    /*-----------------------------------------------------------*/
    // Action Functions

    onChange(key, value) {
        let state = this.state
        state[key] = value
        this.setState(state)
    }

    onSubmitTapped() {
        if (this.state.processing) {
            return
        }

        if (!this.isValid(this.state.email) || !this.isValidEmail(this.state.email)) {
            this.showToast("Please input valid email!")
            return
        }

        if (!this.state.content || this.state.content.length === 0) {
            this.showToast("Please input what you need to help!")
            return
        }

        let info = {
            Name: this.state.name,
            Email: this.state.email,
            Phone: this.state.phone,
            Notes: this.state.content,
        }

        this.setState({ processing: true })

        firebase.firestore().collection('ContactUs').add(info).then(() => {

            this.setState({
                name: '',
                email: '',
                phone: '',
                content: '',
                processing: false,
            })
            this.showToast("Thank you! We will get in touch with you soon!")

        }).catch(() => {

            this.setState({
                processing: false,
            })
            this.showToast("Something went wrong! Try again!")

        })
    }

    /*-----------------------------------------------------------*/
    // General Functions

    isValid(text) {
        return text && text.length > 0
    }

    isValidEmail(mail) {
        if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        }

        return false
    }

    showToast(text) {
        toast.error(text)
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        return (
            <div className="terms-content-container">
                <div className="terms-content">
                    <div className="main-title">TERMS AND CONDITIONS</div>
                    <div className="panel-content tx-content">
                        <div className="panel-description" dangerouslySetInnerHTML={{ __html: termsText }} />
                    </div>
                    <div className="terms-info">
                        <div className="terms-info-item">
                            <img src={require('../../../../assets/images/contact-phone.png')} alt="PHONE" />
                            1300 229 227
                        </div>
                        <div className="terms-info-item">
                            <img src={require('../../../../assets/images/contact-email.png')} alt="EMAIL" />
                            contactus@primetickets.com.au
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TermsContent)
