import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    loading: false,
    errorText: '',
    authChanged: false,
    userInfo: null,
    tryLogin: false,
    hostEventTap: false,
    hostedEvents: null,
    dashboard: {},
    coupons: null,
    showReport: false,
});

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        
        case types.LOADING_SET:
            return state.merge({
                loading: action.value,
            });

        case types.ERROR_SET:
            return state.merge({
                errorText: action.value,
            });

        case types.TRY_LOGIN_SET:
            return state.merge({
                tryLogin: action.value,
            });

        case types.AUTH_CHANGED_STATE_SET:
            return state.merge({
                authChanged: action.value,
            });

        case types.USER_INFO_SET:
            return state.merge({
                userInfo: action.value,
            });

        case types.HOSTED_EVENTS_SET:
            return state.merge({
                hostedEvents: action.value,
            });

        case types.DASHBOARD_CONTENT_SET:
            let dashboard = {...state.dashboard};
            dashboard[action.event] = action.value;

            return state.merge({
                dashboard,
            });

        case types.HOST_EVENT_TAP_SET:
            return state.merge({
                hostEventTap: action.value,
            });

        case types.SHOW_REPORT_SET:
            return state.merge({
                showReport: action.value,
            });

        case types.COUPONS_SET:
            return state.merge({
                coupons: action.value,
            });

        default:
            return state;
    }
}

export function getLoading(state) {
    return state.dashboard.loading;
}

export function getError(state) {
    return state.dashboard.errorText;
}

export function getAuthChangedState(state) {
    return state.dashboard.authChanged;
}

export function getUserInfo(state) {
    return state.dashboard.userInfo;
}

export function getHostedEvents(state) {
    return state.dashboard.hostedEvents;
}

export function getDashboardContent(state) {
    return state.dashboard.dashboard;
}

export function getTryLogin(state) {
    return state.dashboard.tryLogin;
}

export function getShowReport(state) {
    return state.dashboard.showReport;
}

export function getCoupons(state) {
    return state.dashboard.coupons;
}
