export default {
    menuItems: [{
        title: 'All Events',
        link: '/events',
    }, {
        title: 'Past Events',
        link: '/past',
    }, {
        title: 'About Us',
        link: '/about',
    }, {
        title: 'FAQ',
        link: '/faq',
    }, {
        title: 'Contact Us',
        link: '/contact',
    }],

    userMenuItems: [{
        title: 'My Tickets',
        link: '/mytickets',
        icon: 'book',
    }, {
        title: 'Sign out',
        link: 'LOG_OUT',
        icon: 'sign-out',
    }]
}