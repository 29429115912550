import React from 'react'
import './CheckBox.scss'

class CheckBox extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            checkbox: false
        }
    }

    componentWillReceiveProps(nextProps) {
        let checked = false

        if(nextProps.checked === undefined) {
            return
        }

        if(nextProps.checked) {
            checked = nextProps.checked
        }

        this.setState({checkbox: checked})
    }
  
    onChange() {
        let checked = false
        if(!this.state.checkbox) {
            checked = true
        }

        if(this.props.onChange) {
            this.props.onChange(checked)
        }

        this.setState({checkbox: checked})
    };
  
    render() {
        let { disabled, label, style } = this.props;
        let { checkbox } = this.state

        if(!style) style = {}

        return (
            <div className="check-box" style={style}>
                <label>
                    <input type="checkbox"
                        className="check-box-input"
                        checked={checkbox}
                        disabled={disabled}
                        onChange={(evt) => this.onChange(evt)} />
                    <span className="check-box-span" />
                    <div>{label}</div>
                </label>
            </div>
        );
    }
}
  
export default CheckBox
