export default `
<p><strong>Part A: General Rules</strong></p>
<h2>1. Introduction</h2>
<p>1.1 These terms and conditions (&ldquo;Agreement&rdquo;) constitute a legal agreement between the User and&nbsp;Prime Tickets Pty Ltd (ABN 61 643 493 216)&nbsp;(the &ldquo;Company&rdquo;). In order to use the e-commerce related services as set forth in Clause 16 of this Agreement (&ldquo;Prime Tickets Services&rdquo;) as provided in our website, mobile application and/or any other platforms, that are provided to the User by the Company (&ldquo;Prime Tickets Platform&rdquo;), the User is advised to read this Agreement carefully as they affect the User&rsquo;s rights and liabilities under the applicable laws and regulations in Australia. If the User does not agree to or fall within this Agreement and wish to discontinue using the Prime Tickets Services, please do not continue using the Prime Tickets Platform.</p>
<h2>2. Definitions and interpretation</h2>
<p>2.1 Definitions</p>
<p>The following terms shall have the following respective meanings:</p>
<p>(a) &ldquo;Applicant&rdquo; means a non-User who intends to be registered as a User;</p>
<p>(b) &ldquo;Business Day&rdquo; means a day on which banks are open for business in NSW, Australia</p>
<p>(c) &ldquo;Full Purchase Price&rdquo; means the Item Price with or without delivery charges;</p>
<p>(d) &ldquo;GST&rdquo; means the goods and services tax pursuant to the Goods and Services Tax Act being whatever rate of tax that may be chargeable or applicable under the said Act in respect of any supply of goods or services prescribed under the said Act and any prevailing GST guide issued by ASIC from time to time;</p>
<p>(e) &ldquo;Identity Verification Proceeding&rdquo; means the act of confirming whether the information given by the Applicant as set forth in Clause 6 of this Agreement, in each case, matches the information recorded at the time the relevant Applicant becomes a User:</p>
<p>(i) the identity of the Shopper is to be verified through social media account or e-mail account authentication; and</p>
<p>(ii) the identity of the Seller is to be verified through Social Media account or e-mail account authentication and any other additional information and evidentiary materials, including but not limited to:</p>
<ol>
<li>the Seller&rsquo;s business or company addresses, bank details, registration number and registration certificates</li>
<li>any document or forms obtained from the ASIC regarding the Seller&rsquo;s company or business information;</li>
<li>the shipping address and/or store location of the Seller for shipping purposes; and</li>
<li>any other information which may be requested by the Company;</li>
</ol>
<p>(f) &ldquo;Intellectual Property Rights&rdquo; includes any patent, copyright, registered design, trade mark, right in design, service mark, right under licence or other industrial or intellectual property right, whether or not any of them are registered and including applications for registration of any of the foregoing and all forms of protection of a similar nature or having similar effect which may subsist in Australia and/or anywhere in the world;</p>
<p>(g) &ldquo;Item Price&rdquo; includes the price of the Products and/or Services displayed on the Seller&rsquo;s shop, GST, any other taxes and/or applicable charges;</p>
<p>(h) &ldquo;Privacy Policy&rdquo; means the Privacy Policy issued by the Company to the Shopper and the Individual Seller, which can be found at http://www.Prime Tickets.com/privacy-policy/;</p>
<p>(i) &ldquo;Product&rdquo; means any good or item registered with the Company that are displayed, advertised and/or sold to the Shoppers by the Sellers using the Prime Tickets Services;</p>
<p>(j) &ldquo;Service&rdquo; means any service registered with the Company that are displayed to, advertised to and/or subscribed by the Shoppers by the Seller using the Prime Tickets Services;</p>
<p>(k) &ldquo;User&rdquo; means any individual or corporate who has been registered to become a registered user of the Prime Tickets Platform according to registration process established by the Company and who is eligible to use the Prime Tickets Services. Users consist of Shoppers and Sellers:</p>
<p>(i) &ldquo;Shopper&rdquo; means any individual User who is of the age of eighteen (18) or more and who is eligible to use the Prime Tickets Services for the sole purpose of purchasing and/or subscribing to the Products and/or Services; and</p>
<p>(ii) &ldquo;Seller&rdquo; means any User who is eligible to use the Prime Tickets Services for the sole purpose of selling and/or providing the Products and/or Services and who can legally sell and/or provide Products and/or Services based on the relevant business or company registration documentation, and any applicable licence, pass, permit, certificate, or legal authorisation obtained from or issued by the relevant authorities on the sale and/or provision of the Products and/or Services. The Sellers consist of Individual Sellers, who are sole traders or sole proprietors, and Corporate Sellers, who are Sellers other than Individual Sellers; and</p>
<p>(l) &ldquo;User Registration&rdquo; means the registration to become a User of the Prime Tickets Platform in order to use the Prime Tickets Services.</p>
<p>2.2 Interpretation</p>
<p>In this Agreement, headings and boldings are only for convenience and do not affect interpretation and, unless the context requires otherwise:</p>
<p>(a) words in the singular include the plural and the other way around;</p>
<p>(b) words of one gender include any gender;</p>
<p>(c) a reference to a party to this Agreement includes that party&rsquo;s executors, administrators, successors and permitted assigns;</p>
<p>(d) a reference to &ldquo;clauses&rdquo; are to clauses of this Agreement;</p>
<p>(e) a reference to a right includes a remedy, power, authority, discretion or benefit;</p>
<p>(f) a reference to legislation includes any amendment to that legislation, any consolidation or replacement of it, and any subordinate legislation made under it and any rules, regulations and guidelines issued under that legislation or subsidiary legislation;</p>
<p>(g) a reference to a document, including this Agreement, includes any amendment or supplement to, or replacement or novation of, that document or this Agreement, as the case may be;</p>
<p>(h) if a word or phrase is defined, another grammatical form of that word or phrase has a corresponding meaning; and</p>
<p>(i) examples are descriptive only and not exhaustive.</p>
<h2>3. Effect</h2>
<p>3.1 By clicking on the relevant &ldquo;Sign Up&rdquo; or &ldquo;OK&rdquo; buttons provided on the User Sign Up Screen, the User has agreed to be bound by this Agreement, and any future amendments and additions to this Agreement as may be published by the Company from time to time through the Prime Tickets Platform.</p>
<p>3.2 The Company is entitled to amend this Agreement when there are reasonable reasons, so long as such amendment does not violate any laws and regulations in Australia. In the event this Agreement is amended, the Company shall notify the User of such amendment via the Prime Tickets Platform and/or the Company&rsquo;s website www.Prime Tickets.com.au&nbsp;(&ldquo;Website&rdquo;).</p>
<p>3.3 The Company shall not be responsible for any damages suffered or sustained by any Users in connection with their failure to learn the information on the amended Agreement.</p>
<p>3.4 The User&rsquo;s continued use of this Prime Tickets Platform following the posting of the amended terms and conditions to this Agreement constitutes the User&rsquo;s acceptance to abide by and be bound by those amendments.</p>
<p>3.5 The User shall comply with this Agreement. The User shall be solely responsible for all losses and damages arising out of or in connection with its breach or failure to comply the terms of this Agreement.</p>
<p>3.6 This Agreement shall prevail over any separate terms put forward by Users or between Sellers and Shoppers. Any conditions that the User submits, proposes or stipulates in whatever form and at whatever time, whether in writing, by e-mail or orally, are expressly waived and excluded.&nbsp;</p>
<h2>4. Restrictions</h2>
<p>4.1 By using the Prime Tickets Platform and/or the Prime Tickets Services, the User expressly represents and warrants that he / she is legally entitled to accept and agree to this Agreement and that he / she is at least eighteen (18) years old. Without limiting the generality of the foregoing, the Prime Tickets Services are not available to persons under the age of eighteen (18) or such persons that are forbidden for any reason whatsoever to enter into a contractual relationship.</p>
<p>4.2 The User is personally responsible for the use of the Prime Tickets Platform and/or the Prime Tickets Services, including obtaining the necessary data network access to use the Prime Tickets Platform and/or the Prime Tickets Services. The User&rsquo;s network&rsquo;s data usage charges and/or messaging fees may apply in using the Prime Tickets Platform and/or the Prime Tickets Services and the User shall be responsible for such charges and fees.</p>
<p>4.3 The User uses the Prime Tickets Platform and/or the Prime Tickets Services at the User&rsquo;s own risk. The Company shall not be liable for damage to, or viruses or other code that may affect, any hardware or device, software, data or other property as a result of the User&rsquo;s access to or use of the Prime Tickets Platform and/or the Prime Tickets Services.</p>
<p>4.4 The User may only access the Prime Tickets Platform and/or the Prime Tickets Services using authorised means. It is the User&rsquo;s responsibility to check and ensure that the User has downloaded and/or updated the correct software for the hardware or device. The Company does not guarantee that the Prime Tickets Platform and/or the Prime Tickets Services, or any portion thereof, will function on any particular hardware or device. The Company is not liable if the User does not have a compatible hardware or device or if the User has downloaded the wrong version of the software on the User&rsquo;s hardware or device. The Company reserves the right not to permit the User to use the Prime Tickets and/or Prime Tickets Services should the User uses the Prime Tickets Platform and/or the Prime Tickets Services with an incompatible or unauthorised hardware or device, or for purposes other than which the Prime Tickets Platform and/or the Prime Tickets Services are intended to be used.</p>
<p>4.5 The User shall use the Prime Tickets Platform and/or the Prime Tickets Services in accordance with any instructions for use of the Prime Tickets Platform and/or the Prime Tickets Services which the Company makes from time to time, the terms and conditions of this Agreement and any laws and regulations at the time being in force in Australia</p>
<p>4.6 The User shall not in any way use the Prime Tickets Platform and/or the Prime Tickets Services anything which in any respect:</p>
<p>(a) is fraudulent, criminal or unlawful;</p>
<p>(b) is false, inaccurate or misleading;</p>
<p>(c) may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political;</p>
<p>(d) impersonates any other person or body or misrepresents a relationship with any person or body;</p>
<p>(e) manipulates the price of any item or interferes with other User&rsquo;s listings;</p>
<p>(f) may infringe or breach the Intellectual Property Rights of any third party;</p>
<p>(g) may be contrary to the interests of the Company;</p>
<p>(h) is contrary to any specific rule or requirement that the Company stipulates on the Prime Tickets Platform in relation to a particular part of the Prime Tickets Platform or the Prime Tickets Platform generally;</p>
<p>(i) contains any computer viruses and/or other computer programming routines such as any viruses, unsolicited e-mails, trojan horses, trap doors, back doors, easter eggs, worms, time bombs or cancelbots that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information;</p>
<p>(j) creates liability for the Company or causes the Company to lose, in whole or in part, the services of its internet service providers or other suppliers; or</p>
<p>(k) is in breach of any laws or regulations in Australia, including but not limited to the Content Code.</p>
<p>4.7 The Seller is fully responsible for listing and/or advertising any Product and/or Services in the Prime Tickets Platform. By listing and/or advertising any Product and/or Services in the Prime Tickets Platform, the Seller warrants that:</p>
<p>(a) it is the valid seller and/or service provider of Products and/or Services listed;</p>
<p>(b) the Products and/or Services strictly conform to the descriptions, specifications, drawings, performance criteria and/or other information relating to the Products and/or Services referred or provided by the Seller in the Prime Tickets Platform;</p>
<p>(c) the Products are free from defects in terms of materials, performance, operation and workmanship;</p>
<p>(d) it shall provide the Services using reasonable skill and care and in accordance with good industry practice and shall provide the Services using appropriately skilled, trained and experienced staff or contractors;</p>
<p>(e) it has and/or shall procure all resources, equipment, consumables and other items and facilities required to provide the Services. The Seller also warrant that any resources, equipment, consumables and other items and facilities it uses in providing the Services shall comply with all relevant all laws and regulations and be fit for its intended purpose;</p>
<p>(f) it has the right and has obtained all required accreditations, licence, pass, permit, certificate and/or legal authorisation from the relevant authorities to sell and/or provide the Products and/or Services online in Australia;</p>
<p>(g) where any act of the Seller requires the notification to and/or approval by any authority, regulatory or other competent body in accordance with any laws and regulations, the Seller shall comply fully with such notification and/or approval requirements; and</p>
<p>(h) the Products and/or Services conform with applicable all laws and regulations, including any relevant health and safety requirements and standards.</p>
<p>4.8 The Seller shall not&nbsp;list and/or advertise in the Prime Tickets Platform and/or advertise for sale in the Prime Tickets Platform any item which:</p>
<p>(a) involves the sale of counterfeit or stolen items, or any other fraudulent act;</p>
<p>(b) infringes the Intellectual Property Rights of any third party;</p>
<p>(c) otherwise causes any harm including but not limited to a product or service that contains a defamatory statement; or</p>
<p>(d) violates or is illegal under any applicable laws or regulations in Australia.</p>
<p>The Company reserves the right to remove any items that it deems to be in violation of the above, at its sole discretion.</p>
<p>4.9 The Company also has the right to remove any content from the Prime Tickets Platform in accordance with the complaints procedure contained in the Content Code.</p>
<h2>5. Availability and accuracy</h2>
<p>5.1 While the Company endeavours to make the Prime Tickets Platform and/or the Prime Tickets Services available 24 hours a day, the Company cannot be liable if for any reason the Prime Tickets Platform and/or the Prime Tickets Services are unavailable for any time or for any period. The Company makes no warranty or guarantee that the User&rsquo;s access to the Prime Tickets Platform and/or the Prime Tickets Services will be uninterrupted or error-free.</p>
<p>5.2 Access to the Prime Tickets Platform and/or the Prime Tickets Services may be suspended or withdrawn to or from the User temporarily or permanently at any time and without notice if such action is&nbsp;deemed necessary&nbsp;by the&nbsp;Company. The Company may temporarily suspend the provision of the Prime Tickets Services due to repair, maintenance, checking, replacement, breakdown of communication facilities or introduce new facilities and functions. The Company shall not be liable for any damages or losses that a User or a third party may suffer or sustain as a result of temporary or permanently suspension of the Prime Tickets Services.</p>
<p>5.3 All information and/or materials provided in the Prime Tickets Platform regarding the Products and/or Services are provided by the Seller. In this regard, no warranty or representation, express or implied, is given by the Company that such information and/or materials are complete, accurate, up-to-date, fit for a particular purpose and, to the extent permitted by law. The Company makes no representation and accept no liability for any errors, omissions or misrepresentation in respect of the information and/or materials about the Products and/or Services provided by the Seller.</p>
<h2>6. User registration</h2>
<p>6.1 In order to use the Prime Tickets Platform and/or the Prime Tickets Services, among others, the Applicant must register to become a User and maintain an active user account (&ldquo;Account&rdquo;).</p>
<p>6.2 The Applicant is required to enable the location service on the Applicant&rsquo;s hardware or device before proceeding to use the Prime Tickets Platform. In order to be registered as a User, the Applicant is required to undertake the Identity Verification Proceeding.</p>
<p>6.3 For any application for which the Identity Verification Proceeding cannot be undertaken by the Company, the Company is entitled to request the Applicant to submit evidentiary materials such as birth date in addition to the mandatory items indicated in Clause 2.1(e) for verification purposes.</p>
<p>6.4 The Applicant shall be deemed to have become the User as of the date on which the Applicant is allowed to use the Prime Tickets Services or the Applicant has received the relevant notification from the Company by e-mail or any other means determined by the Company.</p>
<p>6.5 By creating and owning an Account, the User shall:</p>
<p>(a) only use its unique user identification (&ldquo;User ID&rdquo;) and password of its existing social media account or e-mail account when logging on to the Prime Tickets Platform;</p>
<p>(b) keep all information relating to its Account confidential, including but not limited to its User ID and password;</p>
<p>(c) immediately notify the Company of any unauthorised use of its Account, User ID and/or password; and</p>
<p>(d) ensure that its Account information is accurate and up-to-date.</p>
<p>6.6 The User is fully responsible for all activities that occur under its Account even if such activities or uses were not committed by the User itself. The Company will not be liable for any loss or damage arising from unauthorised use of the User&rsquo;s Account or the User&rsquo;s failure to comply with Clause 6.5.</p>
<h2>7. Privacy Policy</h2>
<p>7.1 In order to use the Prime Tickets Services and to be registered as a User, the Shopper or the Individual Seller, among others, must agree to the privacy police published in the website.</p>
<p>7.2 By agreeing to the Privacy Policy, the Shopper or the Individual Seller has agreed that he or she has read and understood the Privacy Policy regarding the processing of his or her personal data, and he or she has consented to have the Company process his or her personal data for the purposes and to the extent stated in the Privacy Policy.</p>
<h2>8. Approval, rejection or deferring of the User Registration</h2>
<p>8.1 The Company shall approve the Applicant&rsquo;s application for User Registration only when:</p>
<p>(a) all required information set forth in Clause 6 of this Agreement has been accurately provided by the Applicant;</p>
<p>(b) the Applicant has consented to be bound by this Agreement and/or the Privacy Policy; and</p>
<p>(c) the identity of the Applicant has been confirmed by undertaking the Identity Verification Proceeding.</p>
<p>8.2 The Company may refuse to approve the application for User Registration in any of the following cases:</p>
<p>(a) The Applicant is less than eighteen (18) years old;</p>
<p>(b) It has been confirmed during the Identity Verification Proceeding that the Applicant is not the person who is filing the application;</p>
<p>(c) The information provided by the Applicant is identical with those of an existing User;</p>
<p>(d) There is false or omitted information or typos in the application or the registration materials; or</p>
<p>(e) It has been confirmed that the application has been filed in breach of this Agreement or that such application is unlawful or otherwise wrongful.</p>
<p>8.3 The Company may defer any application for User Registration. In that event, the Company shall notify by e-mail the reason for deferring the application, possible date of approval, any additional information or materials that are required for the approval and any other information related to the deferring of the application. The Company may defer the application for User Registration in any of the following cases:</p>
<p>(a) There is lack of facilities;</p>
<p>(b) There are technological problems;</p>
<p>(c) The Company is unable to confirm the identity of the Applicant through the Identity Verification Proceeding; or</p>
<p>(d) The Company has reasonably decided that the deferring of the application for User Registration is necessary.</p>
<h2>9. Management of Users</h2>
<p>9.1 The Company may take the following measures against any User who has breached this Agreement, any related laws and regulations or the general principle of business transactions:</p>
<p>(a) Taking back all or part of the benefits, including any discount coupons, incidentally provided by the Company;</p>
<p>(b) Restricting the use of specific Prime Tickets Services;</p>
<p>(c) Blocking access to the Prime Tickets Platform and/or the Prime Tickets Services;</p>
<p>(d) Terminating this Agreement and the User&rsquo;s Account;</p>
<p>(e) Reporting the User to the relevant authorities; and</p>
<p>(f) Seeking compensation for damages.</p>
<p>9.2 In the event the Company has undertaken the measures set forth in Clause 9.1 above, the Company shall provide the prior notice thereof to the User by e-mail. Under certain inevitable circumstances, such as where the User is out of contact or in the case of emergency circumstances, such measures may be taken prior to the notice and, subsequently, notified to the User.</p>
<p>9.3 In the event the User fails to log in to the Account or the Account remains inactive for eighteen (18) months or more, the Company may consider such Account as dormant account, in which case the Company may place restriction on the use of Prime Tickets Services. Once designated as dormant Account, in order to use the Prime Tickets Services again, the User shall file a fresh application for User Registration as set forth in Clause 6 above.</p>
<h2>10. Cancellation of the User Registration</h2>
<p>10.1 Any User may cancel the User Registration at any time by notifying the Company of its intent to terminate; provided, however, that prior to making such notification, all transactions that are in process must be completed, withdrawn or cancelled, and any negative consequences arising from such withdrawal or cancellation shall be borne by the User. Any negative consequences (including consequences related to costs) arising from the cancellation of the User Registration shall be borne by the User.</p>
<p>10.2 In the event the User&rsquo;s use of the Prime Tickets Services has been suspended due to any breach of this Agreement, the Company shall be entitled to restrict the User&rsquo;s ability to terminate the User Registration until the User has discharged all of its obligations against the Company.</p>
<p>10.3 The use of the Prime Tickets Services by the User and the filing of the applications for the User Registration within one (1) month from the date on which the Shoppers had cancelled the User Registration may be denied.</p>
<p>10.4 The Company may cancel the User Registration of any User in any of the following cases.</p>
<p>(a) The Company may cancel the User Registration of any User in the event any of the following has occurred:</p>
<p>(i) The User has committed an act of compromising legitimate interests (such as rights, honor, etc) of other Users or the Company (including its employees and consultants) or causing violation of the Australian laws and regulations or good public orders and morals;</p>
<p>(ii) The User is engaging or attempting to engage in any of the following activities to interrupt, or that are likely to interrupt, the smooth processing of the Prime Tickets Service:</p>
<p>* Raising any claims in a constant and malicious manner without any reasonable grounds; or</p>
<p>* Constantly cancelling or returning the Products that are not particularly defective after using such Products; or</p>
<p>* Constantly cancelling purchase of or subscription to the Services without any reasonable reason; or</p>
<p>* Breaching the rules of use promulgated by the Company for safe transactions; or</p>
<p>(iii) The User has intentionally interrupted the Company&rsquo;s business or registered the Products and/or Services that are not fit for sale; or</p>
<p>(iv) The User has breached any of the provision under this Agreement; or</p>
<p>(v) The Company, in its reasonable judgment, has decided that there are grounds to refuse the provision of the Prime Tickets Services.</p>
<p>(b) In the event the Company cancels the User Registration of the User, the Company will notify the relevant User of the grounds of cancelling the User Registration by e-mail or other means. The User Registration of the User is cancelled when the Company notifies of its intent to cancel to the User Registration.</p>
<p>(c) Following the cancellation of the User Registration of the User by the Company in accordance with this Clause 10, this Agreement shall continue to apply to any matters related to the completion of any existing sale and purchase agreements entered into prior to the termination.</p>
<p>(d) In the event the User Registration of a User is cancelled in accordance with this Clause 10.4, the Company may cancel any advertisements and transactions related to the relevant Seller without notice, and to the extent any Shopper has paid the Full Purchase Price, such payment shall be cancelled or refunded by the Seller.</p>
<p>(e) In the event the User Registration of a User is terminated in accordance with this Clause 10.4 and the User re-applies for User Registration for the use of the Prime Tickets Services, the Company may refuse to approve such application.</p>
<p>10.5 The User shall be solely responsible for any losses occurring in connection with the cancellation of the User Registration, for which the Company shall not be held responsible in any way whatsoever.</p>
<h2>11. User Rating and Assessment Functions</h2>
<p>11.1 For any Products and/or Services traded and/or provided by using the Prime Tickets Services, the Company may grant rating to the User, based on certain criteria which may be determined by the Company from time to time, according to the result of the User&rsquo;s use of the Prime Tickets Services (&ldquo;User Rating&rdquo;). The details of the criteria in granting the User Rating will be made available on the Website.</p>
<p>11.2 The Company is entitled to amend the criteria in granting the User Rating when there are reasonable reasons, so long as such amendment does not violate the related laws and regulations in Australia. In the event that there is any amendment to the criteria in granting the User Rating, the Company shall notify the User of such amendment through the Website.</p>
<p>11.3 In the event the relevant User files an objection in respect of the element of the rating granted to such User, the Company may adjust all or part of the elements of the rating in consideration of various circumstances such as the explanation given by the relevant User.</p>
<p>11.4 The User Rating shall be utilised only as reference materials when the Products and/or Services are purchased and/or subscribed to, and it does not guarantee the reputation of the User or indicate the financial status of the User.</p>
<p>11.5 The Shopper is entitled to indicate his or her rating or review about a particular Seller, Product or Service by using different rating and/or review functions made available by the Company in the Prime Tickets Platform (&ldquo;Assessment Functions&rdquo;). The Assessment Functions shall include the use of the &ldquo;Like&rdquo; button and &ldquo;Comment&rdquo; column provided on the Seller&rsquo;s shop. The rating and/or review about a particular Seller, Product or Service by using the Assessment Functions can be given by any Shopper, which, in principle, shall be publicly disclosed on the Seller&rsquo;s shop. If the Shopper wishes to communicate with the Seller on a private basis, a direct chat function will be made available between the Shopper and the Seller when the Shopper starts following the Seller&rsquo;s shop.</p>
<p>11.6 The rating and/or review given by the Shopper via the Assessment Functions shall only be given based on the Shopper&rsquo;s fair and reasonable assessment on the Seller&rsquo;s performance in advertising, promoting, explaining and/or selling the relevant Products and/or Services. A Shopper who has given a rating and/or review on any Seller, Product or Service shall be responsible for such assessment, and the Company does not intervene in ascertaining whether any of these is true.</p>
<p>11.7 Any Shopper who has given the rating or review can undo or delete the rating or review by himself or herself. The Seller is also allowed to delete any reviews posted by a Shopper about the Seller, its Product and/or Service at its own discretion. However, in the event a review is deleted, the deleted review will be replaced by a post indicating the identity of the party who deleted the said review.</p>
<p>11.8 In the event it is determined that the review on any Seller, Product or Service and any attached opinion thereto are not appropriate for public disclosure, the Company is entitled to change or delete the relevant review and the attached opinion. When a review is deleted, any opinion attached to such review is also deleted.</p>
<p>11.9 The Company is also entitled to impose sanctions, such as deleting a review given by a Shopper and disqualifying the relevant Shopper from using the Prime Tickets Services if the Shopper has engaged in any activities in contravention of the purpose and objectives of the Assessment Functions.</p>
<p>11.10 The Shopper&rsquo;s rating and/or review given by using the Assessment Functions may be deleted or the relevant Shopper may be disqualified from using the Prime Tickets Services in any of the following cases:</p>
<p>(a) If any assessment of the degree of satisfaction on the performance of any Seller and/or the quality of any Product or Service has been undertaken in order to fabricate the User Rating or to manipulate the increase in the popularity or reputation of the Seller;</p>
<p>(b) If any assessment was performed (and the assessment result thereof), after cash or other compensation was provided and/or promised in return for indication of good rating and/or positive reviews to be given to the Sellers;</p>
<p>(c) If the Shopper engaged in any activities of insulting, causing defamation or damaging credit of a third person by exploiting the use of the Assessment Functions; or</p>
<p>(d) If the use of the Assessment Functions by the Shopper is in contravention of the purpose and objective of such system.</p>
<h2>12. Service fees and commission</h2>
<p>12.1 The Company reserves the right to impose a service fee on the User in connection with the provision of certain specific or all Prime Tickets Services or functions of the Prime Tickets Platform. The User shall pay all fees, as described in the Prime Tickets Platform or the Website in connection with such Prime Tickets Services or functions selected by the User.</p>
<p>12.2 The Company charges the Shopper a fixed rate of commission based on the type of Product and/or Service purchased and/or subscribed by the Shopper from the Seller through the Prime Tickets Platform and/or the Prime Tickets Services (&ldquo;Commission&rdquo;), which will be included into the Item Price. The Company reserves the right to modify the Commission rate from time to time.&nbsp;</p>
<p>12.3 The parties acknowledge and agree that if GST is imposed on any supply of the Prime Tickets Services, including any Commission and service fees imposed by the Company, the Company will charge the User such GST and any other applicable taxes additionally.</p>
<p>12.4 Any fees set forth in this Clause 12 shall be handled in accordance with the relevant clauses under this Agreement.</p>
<h2>13. Discount coupons</h2>
<p>13.1 The Company or the Seller may issue discount coupons to the Shopper, which provide discount of fixed amount or rate to the Shopper in the event the User purchases and/or subscribes to the Products and/or Services by using the Prime Tickets Services.</p>
<p>13.2 The Shopper has the right to use the discount coupon only for his or her own purchases, and the Shopper shall not, under any circumstances, duplicate, sell or transfer of such coupons in any manner to any other person. The Shopper shall not make the discount coupons available to the general public (whether posted to a public form or otherwise).</p>
<p>13.3 The use of the discount coupons may be restricted depending on certain items or amount, and such coupons cannot be used after the expiration of their respective expiry date. The discount coupon may not be reusable after any refund is made due to cancellation or the return of the Products and/or Services using the relevant discount coupon.</p>
<p>13.4 The Company reserves the right to cancel the Shopper&rsquo;s User Registration, terminate his or her Account, and/or require a different means of payment for the Products and/or Services using the discount coupon in the event that the Company determines or believes that the use of the discount coupon was in error, fraudulent, illegal or in violation of any terms and conditions set forth under this Agreement.</p>
<p>13.5 The Company assumes no liability for the loss, theft or illegibility of discount coupon.</p>
<h2>14. Links to third party websites and social media platforms</h2>
<p>14.1 The postings provided and published by other Users using the Prime Tickets Platform may contain links and/or references to other websites and social media platforms (&ldquo;Third Party Websites and Social Media Platforms&rdquo;).</p>
<p>14.2 The Third-Party Websites and Social Media Platforms are not investigated, monitored or checked for accuracy or completeness by the Company.</p>
<p>14.3 The Company shall not be responsible for the contents, accuracy and/or opinions expressed in Third Party Websites and Social Media Platforms, and the inclusion of or reference to the Third-Party Websites and Social Media Platforms does not imply approval or endorsement of those sites and platforms by the Company. The Company will not be involved in, and shall not be responsible for any transactions between the User and a company connected through such Third-Party Websites and Social Media Platforms.</p>
<p>14.4 In the event the User decides to leave the Prime Tickets Platform and access Third Party Websites and Social Media Platforms, the User does so at its own risk.</p>
<h2>15. Intellectual Property Rights</h2>
<p>15.1 The User shall have the Intellectual Property Rights over any postings by the User while using the Prime Tickets Services provided by the Company. The User shall be responsible for any civil and criminal liabilities such as infringement of the Intellectual Property Rights of any third party related to the relevant postings.</p>
<p>15.2 By publishing any postings using the Prime Tickets Platform, the User is deemed to have granted the Company a non-exclusive, royalty-free, perpetual, irrevocable and fully sub-licensable right to copy, display, use, reproduce, publish, distribute, transmit such postings and prepare compilation works and derivative works from such postings throughout the world in any media.</p>
<p>15.3 In this regard, the Company may search, expose to the other websites, or use for free as sales promotion and other materials any postings prepared and produced by the User, and may copy, display, transmit or distribute such posting or prepare compilation works and the derivative works in the Prime Tickets Services provided by the Company; provided, however, that in the event the User, who prepared and produced the relevant postings, requests the Company to stop using such postings by deleting the postings, the Company shall immediately cease from using such postings.</p>
<p>15.4 The Company&rsquo;s right to use the postings prepared and produced by the User set forth in Clauses 15.2 and 15.3 shall remain in full force and effect so long as the Company operates the Prime Tickets Services and the Prime Tickets Platform.</p>
<p>15.5 The Company shall have the ownership over the Intellectual Property Rights relating to any works, including but not limited to text, pictures, video and other content, prepared and published by the Company on the Prime Tickets Platform (&ldquo;Works&rdquo;). The User shall not use, copy, transmit, publish, distribute or broadcast such Works without the express prior permission of the Company.</p>
<p>15.6 The Company may, without notice, delete the postings, restricts or prohibits the relevant User who publishes such postings from using the specific Prime Tickets Services, cancel the User Registration of the User or take any other measures in any of the following cases.</p>
<p>(a) If the contents of the posting violate the provisions of any Australian laws or regulations;</p>
<p>(b) If the posting infringes the rights, honour, credit and any other legitimate profit of the other person;</p>
<p>(c) If the posting contains malignant code or data that may cause malfunction of information technology equipment;</p>
<p>(d) If the posting violates social public order or is destructive to the traditional custom; or</p>
<p>(e) If it is determined that the posting disturbs smooth operation of the Prime Tickets Services of provided by the Company due to material causes similar to those described in Clauses 15.6 (a) &ndash; (d).</p>
<p>15.7 In the event the postings are deleted under Clause 15.6, any other notices related to such postings (such as comments, responses, etc.) shall be deleted as well.</p>
<p><strong>Part B: Prime Tickets Services</strong></p>
<h2>16. Type of Prime Tickets Services</h2>
<p>16.1 The Prime Tickets Services provided by the Company to the User through the Prime Tickets Platform include the services to provide the place of on-line transaction and other related supplementary services to enable the purchase and sale of the Products and/or Services among the Users.</p>
<p>16.2 The type of Prime Tickets Services provided by the Company to the User include:</p>
<p>(a) Business support services related to sales;</p>
<p>(b) Business support services related to purchases;</p>
<p>(c) Payment support services;</p>
<p>(d) Product search information services;</p>
<p>(e) Service search information services; and</p>
<p>(f) Advertisement and promotion services.</p>
<h2>17. Disclaimers</h2>
<p>17.1 As the Company only operates, manages and provides the Prime Tickets Services for the purpose of enabling unconstrained transaction of the Products and/or Services among the Shoppers and the Sellers, the Company does not represent the Shoppers or the Sellers, and the relevant Users shall be directly responsible for all liabilities related to the transaction made among the Users and the information provided by such Users.</p>
<p>17.2 Any liabilities and risks relating transaction undertaken by the Shopper and the Seller shall be borne by and between the relevant Users. The Company shall not be responsible for any loss, damage or liability suffered by the User arising from such transaction.</p>
<p>17.3 The Company does not sell and/or provide the Products and/or Services to the Shopper, nor does it purchase and/or subscribe to the Products and/or Services from the Seller. The Company will take reasonable efforts to develop and provide only the instrument to supplement the safety and reliability of the transaction between the Shopper and the Seller.</p>
<p>17.4 The Company and the Prime Tickets Platform do not transfer legal ownership of the Products from the Seller to the Shopper. Unless the Shopper and Seller agree otherwise, the Shopper will become the Product&rsquo;s lawful owner upon physical receipt of the Products from the Seller.</p>
<p>17.5 The Company does not endorse or recommend, is not an agent, reseller or distributor of, and has no control over any Products and/or Services advertised in the Prime Tickets Platform through the Prime Tickets Services, and the Company hereby expressly disclaims all liabilities and responsibilities arising in relation to any Products and/or Services whether available or advertised via the Prime Tickets Platform through the Prime Tickets Services.</p>
<p>17.6 The Company does not guarantee anything with regard to the transaction undertaken by the Shopper and the Seller, such as:</p>
<p>(a) the existence or veracity of the intent to sell or purchase,</p>
<p>(b) the quality, completeness, safety or legality of the Product and/or Services;</p>
<p>(c) whether such Product and/or Services infringes the right of other person; and</p>
<p>(d) the truthfulness or legality of the statements, offers, information, opinions, materials or content provided by the other Users or any other third parties in the Prime Tickets Platform.</p>
<p>17.7 The Company shall not be responsible for any impairment to use of the Prime Tickets Services caused by the User&rsquo;s fault.</p>
<h2>18. Use of the Prime Tickets Services</h2>
<p>18.1 Before purchasing and/or subscribing to any Products and/or Services, the Shopper must accurately confirm the terms of the transaction and the detailed description of the Products and/or Services prepared by the Seller posted on the Seller&rsquo;s shop. The Shopper shall be responsible for all losses and damages arising out of or in connection with any purchase made without confirming the foregoing.</p>
<p>18.2 The Company does not provide any guarantee on the description of the Products and/or Services and the terms of transaction prepared and posted by the Seller on the Seller&rsquo;s shop. The Shopper shall purchase the Products and/or Services at his or her own risk.</p>
<p>18.3 The Shopper shall be entitled to make payment for the Products and/or Services pursuant to the following payment methods made available to the Shopper by the Company in the Prime Tickets Platform. In addition to any additional terms and conditions contained in the Prime Tickets Platform, the following terms and conditions shall apply to the following type of payment:</p>
<p>(a) Credit card</p>
<p>(i) Credit Card payment option is available for all Shoppers. The Company accepts all Visa and MasterCard credit cards, and is 3D Secure (Verified by Visa and MasterCard Secure) enabled. All credit card information is protected by means of industry leading encryption standards.</p>
<p>(ii) Please take note that additional charges may be incurred if the Shopper is using a non-Australian issues card due to foreign exchange.</p>
<p>(b) Online banking</p>
<p>(i) By choosing this payment method, the Shopper shall transfer the payment for the Products and/or Services to an account of the Company for the Full Purchase Price.</p>
<p>The payment methods provided in Clauses 18.3 (a) and (b) are collectively referred to as the &ldquo;Online Payment Methods&rdquo;)</p>
<p>(c) Cash-On-Delivery</p>
<p>(i) Cash-On-Delivery is available within selected areas. Using this payment method, the Shopper can opt to pay the Full Purchase Price of the Products and/or the Services directly to the delivery provider appointed by the Company when the Products and/or Services are delivered and/or provided to the Shopper personally or any desired delivery address provided by the Shopper when the order was place.</p>
<p>(ii) The Company reserves the right to schedule the delivery time to deliver and/or provide the Products and/or Services to the Shopper personally or any desired delivery address provided by the Shopper when the order was placed. The Products and/or Services will only be handed over and/or provided to the Shopper upon full settlement of the Full Purchase Price in cash by the Shopper.</p>
<p>(d) Cash and Store Collection</p>
<p>(i) The Shopper is allowed to use this payment method in the event that the Shopper finalises his or her purchase and/or subscription with the Seller directly at the Seller&rsquo;s physical stores or at any desired delivery address provided by the Shopper when the order was placed. In view that this cash payment transaction is entered between the Shopper and the Seller, the Company does not charge any fee on the Shopper or the Seller on such transaction.</p>
<p>(ii) The Product and/or Service will only be handed over to the Shopper upon full payment in cash by the Shopper to the Seller.</p>
<p>18.4 The Shopper shall use the payment methods available under his or her name when purchasing and/or subscribing to the Products and/or Services and shall not arbitrarily use the payment methods available under other&rsquo;s name. The Shopper shall be responsible for all damages and losses suffered or sustained by the Company, the owner of such payment methods and the Seller arising out of the Shopper&rsquo;s arbitral use of the payment methods available under other&rsquo;s name.</p>
<p>18.5 The Shopper shall be responsible and liable for the information provided by the Shopper in connection with the payment of the Full Purchase Price and any disadvantages arising out of such information.</p>
<p>18.6 The Company may confirm whether the Shopper has the justifiable right to use the payment method selected by the Shopper and may stop or cancel the transaction until such confirmation is completed.</p>
<p>18.7 In the event the Company posts the information provided by its partners, or provides the reference information or contents provided by a third party on the Prime Tickets Platform, for the convenient use of the Prime Tickets Services for the Shopper, the Shopper shall, at its own decision and risks, purchase and/or subscribe to the Products and/or Services, for which the Company shall have no liability whatsoever.</p>
<p>18.8 In case of any disputes arising out of the procedure relating to the purchase and sale of the Products and/or Services between the Shopper and the Seller, the Shopper shall sincerely try to resolve such dispute in a faithful manner. The Shopper shall be responsible for all damages and losses suffered or sustained by the Seller and/or the Company arising out of the Shopper&rsquo;s insincere approach to resolve the disputes.</p>
<p>18.9 Prime Tickets provides a market place that connects seller who have items available for purchase to buyer items (collectively, the "Services"), which Services are accessible at http://www.Prime Tickets.com.au and any other websites through which Prime Tickets makes the Services available (collectively, the "Website") and as applications for mobile devices (the "Application").</p>
<p>18.10 Prime Tickets cannot and does not control the content contained in any seller listing menu and the condition, legality or suitability of any item, the sale of which is facilitated by Prime Tickets. Prime Tickets is not responsible for and disclaims any and all liability related to any and all item sold. Any item sold or purchased will be made or accepted at the own risk.</p>
<p>18.11 You understand and agree that Prime Tickets is not a party to any agreements entered into between buyer and seller. Prime Tickets has no control over the conduct of seller, buyer other users of the Website and Application or any item, and disclaims all liability in this regard to the maximum extent permitted by law.</p>
<p><br /> 18.12 Prime Tickets does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Seller. You are advised to independently verify the bona fides of any particular Seller that you choose to deal with on the Platform and use your best judgment in that behalf. All Seller offers and third-party offers are subject to respective party terms and conditions. Prime Tickets takes no responsibility for such offers.</p>
<p>18.13 Prime Tickets&nbsp;neither make any representation or warranty as to specifics (such as quality, value, saleability, etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform nor does implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. Prime Tickets accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</p>
<p><br /> 18.14 Prime Tickets is not responsible for any non-performance or breach of any contract entered into between Buyers and Seller on the Platform. Prime Tickets cannot and does not guarantee that the concerned Buyers and/or Seller will perform any transaction concluded on the Platform. Prime Tickets is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>
<p>18.15 Prime Tickets is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer and Seller on the Platform come into or take possession of any of the products or services offered by Seller. At no time shall Prime Tickets hold any right, title or interest over the products nor shall Prime Tickets have any obligations or liabilities in respect of such contract entered into between Buyer and Seller.</p>
<p>18.15 Prime Tickets is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer. In case of complaints from the Buyer pertaining to efficacy, quality, or any other such issues, Prime Tickets shall notify the same to Seller. The Seller shall be liable for redressing Buyer complaints. In the event you raise any complaint on any Seller accessed using our Platform, we shall assist you to the best of our abilities by providing relevant information to you, such as details of the Seller and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.</p>
<p>18.16 As we are providing services in selected cities , we have complied with applicable laws on making the Platform and its content available to you. In the event the Platform is accessed from outside our delivery zones, it shall be entirely at your risk. We make no representation that the Platform and its contents are available or otherwise suitable for use outside select cities. If you choose to access or use the Platform from or in locations outside select cities, you do so on your own and shall be responsible for the consequences and ensuring compliance of applicable laws, regulations, bye-laws, licenses, registrations, permits, authorisations, rules and guidelines. You may not be able to avail our Services if your delivery location is outside our current scope of Service. We will keep you informed of the same at the time of confirming your order booking.&nbsp;</p>
<p>18.17 We also require the user location to tailor&nbsp;our&nbsp;product and services offerings thereby helping you to find what is&nbsp;near and applicable to your current location.</p>
<p>18.18 By using the Website or Application you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Member/s or other third parties who caused you harm. You agree not to attempt to impose liability on or seek any legal remedy on Prime Tickets with respect to such acts or omissions. This limitation shall not apply to any claim by a Maker against Prime Tickets regarding the remittance of payments received from a Buyer by Prime Tickets on behalf of a Maker, which instead shall be subject to the limitations described in the section below entitled "Limitation of Liability".</p>
<h2>19. Delivery</h2>
<p>19.1 Upon receiving the Full Purchase Price of the relevant Product and/or Service from the Shopper, the Seller shall make the necessary arrangements to have the purchased Product and/or Service delivered to the Shopper and provide details such as the name of the delivery service provider, the tracking number and the estimated time of delivery of the Product and/or Service to the Shopper. Delivery of the Products and/or Services shall be made to the delivery address specified by the Shopper when he or she placed the order.</p>
<p>19.2 In the event there is a dispute among the Seller, the Shopper and the delivery provider appointed by the Seller in connection with the delivery of the Product and/or Service, such disputes shall be resolved among the related parties. The Company shall not take part in any of such disputes, nor shall it be held liable thereto.</p>
<h2>20. Cancellation</h2>
<p>20.1 Both the Seller and the Shopper are allowed to cancel the sale and purchase transaction in respect of the Product provided that such cancellation is carried out prior to the Product is dispatched or shipped to the Shopper. Once the Product has been dispatched or shipped, such order may not be cancelled by the Seller or the Shopper.</p>
<p>20.2 If the Shopper would like to cancel his or her order, please contact the Company&rsquo;s Customer Support Team by e-mail at <a href="mailto:support@mybazar.com">support@Prime Tickets.com</a>.au Cancellation fees may apply for all payments made via online banking.</p>
<p>20.3 Upon receiving the Shopper&rsquo;s request to cancel his or her order, the Company shall contact the Seller to verify whether the Product and/or Service has been dispatched or provided. If the Product and/or Service is not dispatched or provided, the Company shall inform the Shopper via e-mail that the cancellation request is approved and accepted, the Shopper will get a refund of the payment made to the relevant Product and/or Service within thirty (30) Business Days if the Shopper had made the payment to the Product and/or Service using the Online Payment Methods. If the Shopper had opted for the cash-on-delivery payment method to pay for the Product and/or Service, the Company will promptly inform the Shopper that his or her cancellation request of the Product and/or Service has been approved and accepted.</p>
<p>20.4 If the Shopper had settled the payment for the Product and/or Service using the Cash-On-Delivery or the Cash and Store Collection payment option, the cancellation of the Product and/or Service shall be made between the Shopper and the Seller and the Company shall not take part in any of such cancellation, nor shall it be held liable for such cancellation.</p>
<h2>21. Return and refund</h2>
<p>21.1 The Shopper may contact the Seller and request to return the Product within seven (7) Business Days from the date on which the Product is delivered to the Shopper.</p>
<p>21.2 If the Seller is not contactable, the Shopper may contact the Company&rsquo;s Customer Support Team at <a href="mailto:support@mybazar.com">support@Prime Tickets.com</a>.au for such request. In the event the Company receives the request to return the Product from the Shopper, the Company shall immediately notify such return request to the Seller. The Seller shall be the party to determine and decide whether the Product is returnable based on the conditions set forth in Clauses 21.3 and 21.4 below.</p>
<p>21.3 The Product shall only be returnable under the following circumstances:</p>
<p>(a) The Product received by the Shopper is not the same Product as ordered by the Shopper; or</p>
<p>(b) The Product is defective at the point of receiving the Product.</p>
<p>21.4 The Shopper may not be entitled to return the Product in any of the following cases:</p>
<p>(a) The Product is damaged due to a cause attributable to the Shopper;</p>
<p>(b) The value of the Product has been significantly reduced due to the use or partial consumption thereof by the Shopper;</p>
<p>(c) The value of the Product has been significantly reduced to the extent that such Product is not resalable due to the time elapsed;</p>
<p>(d) The package of the reproducible Product is damaged; or</p>
<p>(e) Any are other reasonable grounds based on which the Shopper may not be entitled to request for refund or exchange.</p>
<p>21.5 Upon examining whether the Product is returnable, the Seller shall:</p>
<p>(a) inform the Shopper via e-mail and/or in-app inbox that the return request is approved or rejected; and</p>
<p>(b) send a report to the Company at <a href="mailto:support@mybazar.com">support@Prime Tickets.com</a>.au informing the Company whether the Shopper&rsquo;s request to return the Product has been approved or rejected.</p>
<p>21.6 Upon confirming that the Product is returnable, the Shopper shall get a refund of the Item Price and/or any expenses incurred by the Shopper in delivering the Product to the Seller (&ldquo;Refund Payment&rdquo;) within thirty (30) Business Days from the Company or the Seller, depending on which party has in custody the payment made by the Shopper when he or she ordered the purchase of the Product.</p>
<p>21.7 The Seller shall be solely liable for any expenses incurred or losses suffered by the Shopper arising out of the delivery of the returned Product to the Seller.</p>
<p>21.8 If a Product that the Shopper ordered becomes&nbsp;out of stock and the Shopper has fully paid for the Product, the Company or the Seller, depending on which party has in custody the payment made by the Shopper when he or she ordered the purchase of the Product, will issue a full refund to the Shopper.</p>
<p>21.9 If the Shopper had settled the payment for the Product and/or Service using the Cash-On-Delivery or the Cash and Store Collection payment option, the return and refund arrangement in respect of the Product and/or Service shall be made between the Shopper and the Seller and the Company shall not take part in any of such arrangement, nor shall it be held liable thereto.</p>
<p><strong>Part C: Miscellaneous</strong></p>
<h2>22. Miscellaneous provisions</h2>
<p>22.1 This Agreement between the Company and Users and any sales transaction between Users shall be governed by the laws of Australia and the parties submit to the exclusive jurisdiction of the courts of Australia.</p>
<p>22.2 Except as expressly provided herein to the contrary, no provisions of this Agreement, express or implied, are intended or will be construed to confer rights, remedies, or other benefits to any third party under or by reason of this Agreement.</p>
<p>22.3 The User shall not use or refer to the Company&rsquo;s name or logo, or refer to the Company in any manner whatsoever:</p>
<p>(a) in any communication to the public, including communication to the press;</p>
<p>(b) for advertising or promotional purposes; or</p>
<p>(c) for the purpose of informing or influencing any third party,</p>
<p>without the Company&rsquo;s prior written consent.</p>
<p>22.4 This Agreement shall bind and inure to benefit of the parties and their respective permitted assigns, representatives and successors in title.</p>
<p>22.5 The Company can assign, delegate or transfer any rights or obligations under this Agreement, in its sole discretion, to a third party. No User can assign, delegate or transfer any rights or obligations under this Agreement to a third party without a written consent of the Company.</p>
<p>22.6 A party waives a right under this Agreement only if it does so in writing. No failure or delay by the Company or the User in exercising any right under this Agreement shall operate as a waiver of such right or extend to or affect any other or subsequent event or impair any rights or remedies in respect of it or in any way modify or diminish the Company or the User&rsquo;s rights under this Agreement. A waiver of one breach of a term of this Agreement does not operate as a waiver of another breach of the same term or any other term.</p>
<p>22.7 This Agreement replaces any previous agreement, representation, warranty or understanding between the Company and the User concerning the subject matter and contains the entire agreement between the parties.</p>
<p>22.8 If a provision in this Agreement is wholly or partly invalid or unenforceable, the provision or the part of it that is invalid or unenforceable must, to that extent, be treated as deleted from this Agreement. This does not affect the validity or enforceability of the remaining provisions.</p>
<p>22.9 The Company shall establish and operate Customer Support Team to actively collect, and provide solutions to, fair opinions or grievances arising from the User&rsquo;s use of the Prime Tickets Services and mediate disputes between Users. The Company shall promptly process issues that it considers fair and reasonable upon deliberation of various complaints and opinions raised by Users and, for matters that cannot be immediately processed, shall notify the reason and estimated period to User via e-mail or telephone.</p>
`
