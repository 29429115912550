import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './HomeContent.scss';

import {
    BannerSlider,
} from '../../../compnents';
// import Categories               from './categories.config';
import HostContent              from './host-content.config';
import Utils                    from '../../../../services/utils';
// import { Carousel }             from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/home/reducer';
import * as actions             from '../../../../store/home/actions';


class HomeContent extends Component {

    constructor(props) {
        super(props)
        this.loadData()
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.hostEventState !== nextProps.hostEventState && nextProps.hostEventState) {
            this.props.dispatch(actions.setHostEventTapped(false));
            this.hostRef.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    /*-----------------------------------------------------------*/
    // Load data

    loadData() {
        // Load banners..
        this.props.dispatch(actions.loadBanners());
    }

    rearrangeBanners() {
        let { banners } = this.props
        let keys = Object.keys(banners)
        let ary = keys.map((key) => banners[key])
        return ary
    }

    /*-----------------------------------------------------------*/
    // Action Functions

    handleItemTapped(event) {
        this.handleBookTicket(event);
    }

    handleBookTicket(event) {
        console.log("Event to book", event)
        let listId = event['ListId'];
        this.props.history.push(`/event/${listId}`)
    }

    handleToContact() {
        this.props.history.push(`/contact`)
    }

    handleLink(link) {
        window.open(link, '_blank');
    }


    /*-----------------------------------------------------------*/
    // Render

    renderBanners(banners) {
        return banners.map((item, index) => {
            return (
                <div key={index}
                    style={{cursor: 'pointer'}}
                    className="slider-item"
                    onClick={() => this.handleBookTicket(item)}>
                    <img
                        src={item['WebImgUrl']} alt="BANNER-IMG" />
                </div>
            )
        })
    }

    renderEvents(events) {
        if(!events || events.length === 0) {
            return <div><img alt="" /></div>
        }

        return events.map((item, index) => {

            return (
                <div className="ticket-item" key = {index} onClick={() => this.handleItemTapped(item)}>
                    <img style={{cursor: 'pointer'}} src={item.WebImgUrl} alt="BANNER-IMG"></img>
                    <div className="action-pane">
                        <div className="ticket-title">{item.Title} {item.Title} {item.Title}</div>
                        <div className="row flex-center">
                            {/* <div className="ticket-price">{item.price === 0 ? 'FREE' : '$' + item.price}</div> */}
                            <div className="ticket-time">
                                {/* {item['EventDate']} */}
                                {/* {Utils.getTimeStringOf(item['EventDate'])}
                                <span className="fa fa-circle" />
                                {Utils.getDateStringOf(item['EventDate'])} */}
                            </div>
                        </div>

                        <div className="button ticket-book-button" onClick={() => this.handleBookTicket(item)}>Book Tickets</div>

                    </div>
                </div>
            )
        })
    }

    renderAdsBanner(banner) {
        if(!banner) banner = {}

        let items = [];
        if(Utils.isMobile()) {
            items = banner['MobileImages'];
        } else {
            items = banner['WebImages'];
        }

        if(!items) {
            return <div />
        } else {
            return <img style={{cursor: 'pointer'}}
                src={items[0]}
                onClick={() => this.handleLink(banner['LinkUrl'])}
                alt="BANNER" />
        }
    }

    render() {

        let banners = this.rearrangeBanners()
        let { events, ads } = this.props;
        if(!ads) ads = {};

        let { /*BottomBanner,*/ MainAd, HostAd } = ads;

        return (
            <div className="home-content-container">

                <div className="home-content">

                    <div className="carousel" style={{padding: 0}}>
                    {
                        banners && banners.length > 0 ?
                            <BannerSlider
                                items={banners}
                                cellRender={() => this.renderBanners(banners)} /> :
                            <div className="carousel-empty">
                                <div style={{display: 'inline-block', color: '#eb2732'}}>
                                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                                </div>
                            </div>
                    }
                    </div>

                    {/* <div className="main-carousel" style={{padding: 0}}>
                    {
                        banners && banners.length > 0 ?
                            <Carousel autoPlay
                                interval={5000}
                                showStatus={false}
                                showArrows={false}
                                showThumbs={false}
                                infiniteLoop
                                onClickThumb={(item) => this.handleBookTicket(item)}>
                                { this.renderBanners(banners) }
                            </Carousel> :
                            <div className="carousel-empty">
                                <div style={{display: 'inline-block', color: '#eb2732'}}>
                                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                                </div>
                            </div>
                    }
                    </div> */}

                    {/* <div className="banner-ads panel">
                        { this.renderAdsBanner(MainAd) }
                    </div> */}

                    {/* <div className="popular-event">

                        <div className="event-title">POPULAR EVENTS</div>

                        <div className="event-list">
                        {
                            events.map((item, index) => {
                                return (
                                    <div className="ticket-item" key = {index} onClick={() => this.handleItemTapped(item)}>
                                        <img style={{cursor: 'pointer'}} src={item.MobileImgUrl} alt="BANNER-IMG"></img>
                                        <div className="action-pane">
                                            <div className="ticket-title">{item.Title} {item.Title}</div>
                                            <div className="row flex-center">
                                                <div className="ticket-price">{item.price === 0 ? 'FREE' : '$' + item.price}</div>
                                                <div className="ticket-time">
                                                    {item['EventDate']}
                                                    {Utils.getTimeStringOf(item['EventDate'])}
                                                    <span className="fa fa-circle" />
                                                    {Utils.getDateStringOf(item['EventDate'])}
                                                </div>
                                            </div>

                                            <div className="button ticket-book-button" onClick={() => this.handleBookTicket(item)}>Book Tickets</div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>

                    </div> */}

                    <div className="host-event-panel" ref={ref => this.hostRef = ref}>

                        <div className="left-panel">

                            <div className="event-title">HOST YOUR NEXT EVENT WITH US, COMPLETELY FREE.</div>

                            {
                                HostContent.map((content, index) => {
                                    return (
                                        <div key={index} className="panel-item">

                                            <img src={content.icon} alt="IMG-PLACEHOLDER" />
                                            <div>
                                                <div className="item-title">
                                                    {content.title}
                                                    <div className="bottom-indicate" />
                                                </div>
                                                <div className="item-content">{content.detail}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="ctrl-panel">
                                <div className="button host-button" onClick={() => this.handleToContact()}>Host Your Event</div>
                                {/* <div className="text">Get started now. It's completely free.</div> */}
                            </div>
                        </div>

                        <div className="right-panel">
                            <div className="panel-logo">{ this.renderAdsBanner(HostAd) }</div>
                        </div>

                    </div>

                    {/* <div className="more-event">

                        <div className="left">
                            <div className="item">
                                { this.renderAdsBanner(TopBanner) }
                                <img src={logo} alt="IMG-PLACEHOLDER" />
                                <div className="detail">
                                    <div className="detail-title">SON Print Studio</div>
                                    <div className="detail-content">
                                        <div style={{fontSize: 16}}>For all your printing needs. Free same day delivery in Sydney CBD.</div><br/>
                                        0430 511 868<br/>
                                        info@sydneyprintstudio.com.au<br/>
                                        www.sydneyprintstudio.com.au<br/><br/>
                                        <div className="button more-detail">More Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="right">

                            <div className="item" style={{marginBottom: 20}}>
                                { this.renderAdsBanner(TopBanner) }
                                <div className="detail">
                                    <div className="detail-title">Japan Tsunami Relief Fund 2018</div>
                                    <div className="button donate">Donate Now</div>
                                </div>
                            </div>

                            <div className="item">
                                { this.renderAdsBanner(TopBanner) }
                                <div className="detail">
                                    <div className="detail-title">Pettai (Tamil)</div>
                                    <div className="detail-content">New Release This week at Palladium. Get your tickets now at palladium.com.au</div>
                                </div>
                            </div>

                        </div>

                    </div> */}

                    {/* <iframe className="youtube" title="ABOUT VIDEO" src={BottomBanner ? BottomBanner['YouTubeLink'] : ''} /> */}

                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:        selectors.getLoading(state),
        error:          selectors.getError(state),
        loginState:     selectors.getLoginState(state),
        banners:        selectors.getBanners(state),
        events:         selectors.getEvents(state),
        hostEventState: selectors.getHostEventTappedState(state),
        ads:            selectors.getAds(state),
    }
}

export default connect(mapStateToProps)(withRouter(HomeContent));
