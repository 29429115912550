import React, { Component } from 'react'
import './CategoryMenu.css'
import { toast } from 'react-toastify'

class CategoryMenu extends Component {

    onCategoryItem(item, subIndex) {
        if(item.category.ComingSoon) {
            this.showToast("Coming Soon!")
            return
        }

        if(!item.sub) {
            this.showToast("There is no sub category to show!")
            return
        }

        let subCategory = item.sub[subIndex]

        if(this.props.onSubCategory) {
            this.props.onSubCategory(subCategory)
        }
    }

    showToast(text) {
        toast.error(text)
    }

    render() {
        let { menu } = this.props
        
        return (
            <div className="sidebar sidebar-shop">
                <div className="widget widget-box widget-shop-category active text-left">
                    <h3 className="widget-title">Category</h3>

                    <ul className="shop-category-list accordion">
                    {
                        menu.map((item, index) => {
                            return (
                                <li key={index}>
                                    {
                                        item.sub ?
                                            <div style={{cursor: 'pointer'}}
                                                data-toggle="collapse" 
                                                data-target={"#accordion-ul-" + index} 
                                                aria-expanded="false" 
                                                aria-controls={"accordion-ul-" + index}>{item.title}</div> :
                                            <div style={{cursor: 'pointer'}} onClick={() => this.onCategoryItem(item)}>
                                                {item.title}
                                            </div>
                                    }

                                    {
                                        item.sub && 
                                        <button className="accordion-btn collapsed" 
                                            type="button" 
                                            data-toggle="collapse" 
                                            data-target={"#accordion-ul-" + index} 
                                            aria-expanded="false" 
                                            aria-controls={"accordion-ul-" + index}>
                                            <span className="accordion-icon"></span>
                                        </button>
                                    }
        
                                    {
                                        item.sub &&
                                        <ul className="collapse" id={"accordion-ul-" + index} aria-expanded="false">
                                        {
                                            item.sub.map((subItem, subIndex) => {
                                                return (
                                                    <li key={index + '-' + subIndex}>
                                                        <div style={{cursor: 'pointer'}} onClick={() => this.onCategoryItem(item, subIndex)}>
                                                            <i className="fa fa-caret-right"></i>{subItem.CategorySubName}
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>
                                    }
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        )
    }
}

export default CategoryMenu
