import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import './SeatMapContent.scss';

import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as selectors from '../../../../store/home/reducer';
import * as actions from '../../../../store/home/actions';
import {
    SeatsioSeatingChart,
} from '@seatsio/seatsio-react';


class SeatMapContent extends Component {

    blocks = null
    selected = 0
    totalItems = 0

    constructor(props) {
        super(props);

        let { params } = this.props.match;
        let { id } = params;
        this.eventId = id;

        let { listDetail } = this.props;

        this.state = {
            selected: {},
        };

        if (!listDetail) {
            let { params } = this.props.match;
            let { id } = params;
            this.props.history.push(`/event/${id}`);
            this.headerItems = []
            this.eventId = null;
            return
        }

        let { Title, ListId } = listDetail;
        console.log("listDetail", listDetail)

        this.headerItems = [{
            title: 'Home',
            link: '/home',
        }, {
            title: 'All Events',
            link: '/events',
        }, {
            title: Title,
            link: `/event/${ListId}`
        }];
    }

    componentDidMount() {
        console.log(this.chartRef);
    }

    /*-----------------------------------------------------------*/
    // Action Functions

    handleSelect(seat, selected) {
        console.log("Select", seat);
        let { id, label } = seat;

        if (selected) {
            var slength = this.chart.selectedSeats.length;
            console.log(this.chart);
            console.log(this.props.event.Maximum);
            console.log(slength);
            if (slength > this.props.event.Maximum) {
                console.log([id]);
                this.showToast("Due to covid-19 restriction  maximum of " + this.props.event.Maximum + " seat(s) allowed.");
                this.releaseSeats([id]);
                var index = this.chart.selectedSeats.indexOf(id);
                this.chart.selectedSeats.splice(index, 1);
            }
            else {
                let state = this.state;
                state['selected'][id] = { id, label };
                this.setState(state);
            }
        } else {
            let state = this.state;
            delete state['selected'][id];
            this.setState(state);
        }
    }

    releaseSeats(ids) {
        console.log(ids);
        // let { selected } = this.state;
        // console.log("Selected seats", selected);
        // let ids = Object.keys(selected);
        let { holdToken } = this.chart;

        let params = { objects: ids, holdToken };
        let bookURL = `https://api.seatsio.net/events/${this.eventId}/actions/release`;
        const encodedString = new Buffer('6efbcc68-1dae-40e8-a250-87f296217575:').toString('base64');

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + encodedString);

        this.props.dispatch(actions.setLoading(true));

        return fetch(bookURL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params),
        }).then((res) => {
            console.log(res)
            setTimeout(() => {
                this.props.dispatch(actions.setLoading(false));
            }, 1000)
        });
    }


    holdSeats() {
        let { selected } = this.state;
        console.log("Selected seats", selected);
        let ids = Object.keys(selected);
        let { holdToken } = this.chart;

        let params = { objects: ids, holdToken };
        let bookURL = `https://api.seatsio.net/events/${this.eventId}/actions/hold`;
        const encodedString = new Buffer('6efbcc68-1dae-40e8-a250-87f296217575:').toString('base64');

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + encodedString);

        this.props.dispatch(actions.setLoading(true));

        return fetch(bookURL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params),
        });
    }

    handleNext() {
        let { event } = this.props;
        let { selected } = this.state;

        if (!selected || Object.keys(selected).length === 0) {
            this.showToast("Please select seat!");
            return;
        }

        this.holdSeats()
            .then(result => {
                console.log("Hold Seat Result", result);

                let eventObj = { ...event };
                eventObj['SelectedSeats'] = Object.keys(selected);
                eventObj['ItemSelected'] = eventObj['SelectedSeats'].length;
                this.props.dispatch(actions.setSaleItems([eventObj]));
                console.log("Event", event, selected);

                let { listDetail } = this.props;
                let { ListId } = listDetail;

                this.props.history.push(`/event/${ListId}/checkout`);
            })
            .catch(error => {
                console.log("Hold Seat Error", error);
                this.showToast("Failed to hold seat! Try again please!");
            })
    }


    /*-----------------------------------------------------------*/
    // General Functions

    showToast(text) {
        toast.error(text);
    }


    /*-----------------------------------------------------------*/
    // Render

    render() {

        let { selected } = this.state;
        let headerItems = [...this.headerItems, { title: 'Select Seat', link: '' }];
        let { event, loading } = this.props;

        return (
            <div className="seat-content-container">

                {
                    loading &&
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="seat-header">
                    <img src={require('../../../../assets/images/ic_home.png')} alt="HOME-ICON" />
                    <div className="path">
                        {
                            headerItems.map((item, index) => {

                                let style = {};
                                if (index === headerItems.length - 1) {
                                    style = { color: '#be2927' }
                                }

                                if (index === 0) {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <Link to={item.link}>{item.title}</Link>
                                        </div>
                                    )
                                } else if (index === headerItems.length - 1) {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <span style={{ marginRight: 10, color: 'black' }} className="fa fa-angle-right" />
                                            {item.title}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} style={{ marginRight: 10, ...style }}>
                                            <span style={{ marginRight: 10, color: 'black' }} className="fa fa-angle-right" />
                                            <Link to={item.link}>{item.title}</Link>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>

                <div className="seat-content">
                    <div className="payment-method">
                        <div className="panel-title">SELECT SEATS</div>
                        <div className="panel-title-detail">{event['ItemTitle']}</div>

                        {
                            this.eventId &&
                            <div className="method-content" style={{ flex: 1 }}>
                                <SeatsioSeatingChart ref={ref => this.chartRef = ref}
                                    publicKey="6e37b977-9a4f-4a1c-be56-a918e9f3842c"
                                    onRenderStarted={createdChart => { this.chart = createdChart }}
                                    onObjectSelected={seat => this.handleSelect(seat, true)}
                                    onObjectDeselected={seat => this.handleSelect(seat, false)}
                                    holdOnSelect={true}
                                    event={this.eventId}
                                    region="eu"/>
                            </div>
                        }

                        <div className="method-content">
                            <div className="content-panel">
                                <div className="block-item">
                                </div>
                            </div>

                            <div className="row" style={{ padding: '10px 20px' }}>
                                <div className="button" onClick={() => this.handleNext()}>Next</div>
                            </div>
                        </div>
                    </div>

                    <div className="payment-summary">

                        <div className="panel-title">SUMMARY</div>

                        <div className="content-panel block">
                            {
                                Object.keys(selected).length === 0 ?
                                    <div className="title"><span className="fa fa-check-circle" /> No Seat Selected!</div> :
                                    <div className="title"><span className="fa fa-check-circle" /> Selected Seats ({Object.keys(selected).length})</div>
                            }
                            {
                                Object.keys(selected).length > 0 &&
                                Object.keys(selected).map((key) => {
                                    let seat = selected[key];

                                    return (<div key={key} className="item">{seat['id']}</div>)
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        event: selectors.getEventId(state),
        loginInfo: selectors.getLoginInfo(state),
        listDetail: selectors.getListDetail(state),
        eventDetail: selectors.getEventDetail(state),
        saleItems: selectors.getSaleItems(state),
    }
}

export default connect(mapStateToProps)(withRouter(SeatMapContent));
