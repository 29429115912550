import * as types from './actionTypes';
import * as selectors from './reducer';
import firebase from '../../services/firebase.service';
import Utils from '../../services/utils';


export function setLoading(value) {

    return (dispatch) => {
        dispatch({ type: types.LOADING_SET, value: value });
    }

}

export function setError(value) {

    return (dispatch) => {
        dispatch({ type: types.ERROR_SET, value: value });
    }

}

export function setLoginInfo(value) {

    return (dispatch) => {
        dispatch({ type: types.LOGIN_INFO_SET, value: value });
    }

}

export function setLoginState(value) {

    return (dispatch) => {
        dispatch({ type: types.LOGIN_SET, value: value });
    }

}

export function setBookEvent(value) {

    return (dispatch) => {
        dispatch({ type: types.BOOK_EVENT_SET, value: value });
    }

}

export function setSaleItems(value) {

    return (dispatch) => {
        dispatch({ type: types.SALE_ITEM_SET, value: value });
    }

}

export function setPaymentConfirm(value) {

    return (dispatch) => {
        dispatch({ type: types.CONFIRM_PAYMENT_SET, value: value });
    }

}

export function setReportState(value) {

    return (dispatch) => {
        dispatch({ type: types.REPORT_STATE_SET, value: value });
    }

}

export function setHostEventTapped(value) {

    return (dispatch) => {
        dispatch({ type: types.HOST_EVENT_TAP_SET, value: value });
    }

}

export function setSeats(value) {

    return (dispatch) => {
        dispatch({ type: types.SEATS_SET, value: value });
    }

}

export function setEventId(value) {

    return (dispatch) => {
        dispatch({ type: types.EVENT_ID_SET, value: value });
    }

}

export function loadBanners() {

    return (dispatch) => {

        firebase.firestore().collection('Banners')
            .where('Active', '==', true)
            .where('WebBanner', '==', true)
            .where('EndDate', ">", new Date())
            .get()
            .then(result => {
                let bannersNew = {}

                result.docs.forEach(doc => {
                    let key = doc.id;
                    let data = doc.data();
                    data['EventDate'] = data['EndDate'].toDate();
                    bannersNew[key] = data;
                })

                dispatch({ type: types.BANNERS_SET, value: bannersNew });
            })
    }
}

export function loadCategories(callback) {

    return (dispatch) => {

        firebase.firestore().collection('Category')
            .where('Active', '==', true)
            .get()
            .then(snapshot => {

                let categories = []
                
                snapshot.forEach(doc => {
                    let data = doc.data();
                    categories.push(data);
                });
                
                dispatch({ type: types.CATEGORIES_SET, value: categories });

                if(callback) callback();
            })
            .catch(error => {
                console.log("Load Categories Error", error);
            })
    }

}

export function loadSubCategories(callback) {

    return (dispatch) => {

        firebase.firestore().collection('CategorySub')
            .where('Active', '==', true)
            .get()
            .then(snapshot => {

                let subCategories = []
                
                snapshot.forEach(doc => {
                    let data = doc.data();
                    subCategories.push(data);
                });
                
                dispatch({ type: types.SUB_CATEGORIES_SET, value: subCategories });

                if(callback) callback();
            })
            .catch(error => {
                console.log("Load Sub Categories Error", error);
            })
    }

}

export function loadEvents() {

    return (dispatch, getState) => {

        let categories = selectors.getCategories(getState());
        let eventCategory = Utils.findNode(categories, 'CategoryId', 'Events');

        if(!eventCategory) return;
        let categoryId = eventCategory['CategoryId'];

        let subCategories = selectors.getSubCategories(getState());
        let eventSubCategory = Utils.findNode(subCategories, 'CategoryId', categoryId);

        if(!eventSubCategory) return;
        let categorySubId = eventSubCategory['CategorySubId'];

        console.log(categorySubId, categoryId)

        firebase.firestore().collection("Lists")
            .where('PopularEvent', '==', true)
            .where("CategorySubId", '==', categorySubId)
            .where("EndDate", '>=', new Date())
            .orderBy("EndDate", "asc")
            .get()
            .then(snapshot => {

                let events = []
                
                snapshot.forEach(doc => {
                    let data = doc.data();
                    events.push(data);
                });
                
                dispatch({ type: types.EVENTS_SET, value: events });
            })
            .catch(error => {
                console.log("Load Sub Categories Error", error);
            })
    }

}

export function loadAllEvents() {

    return (dispatch, getState) => {

        let categories = selectors.getCategories(getState());
        let eventCategory = Utils.findNode(categories, 'CategoryId', 'Events');

        if(!eventCategory) return;
        let categoryId = eventCategory['CategoryId'];

        let subCategories = selectors.getSubCategories(getState());
        let eventSubCategory = Utils.findNode(subCategories, 'CategoryId', categoryId);

        if(!eventSubCategory) return;
        let categorySubId = eventSubCategory['CategorySubId'];

        firebase.firestore().collection("Lists")
            .where('Active', '==', true)
            .where("CategorySubId", '==', categorySubId)
            .where("EndDate", '>=', new Date())
            .orderBy("EndDate", "asc")
            .get()
            .then(snapshot => {

                let events = []
                
                snapshot.forEach(doc => {
                    let data = doc.data();
                    // data['EventDate'] = data['EventDate'] ? new Date(data['EventDate']) : data['EndDate'].toDate();
                    console.log("****************", data);

                    events.push(data);
                });
                
                dispatch({ type: types.ALL_EVENTS_SET, value: events });
            })
            .catch(error => {
                console.log("Load Sub Categories Error", error);
            })
    }

}

export function loadPastEvents() {

    return (dispatch, getState) => {

        let categories = selectors.getCategories(getState());
        let eventCategory = Utils.findNode(categories, 'CategoryId', 'Events');

        if(!eventCategory) return;
        let categoryId = eventCategory['CategoryId'];

        let subCategories = selectors.getSubCategories(getState());
        let eventSubCategory = Utils.findNode(subCategories, 'CategoryId', categoryId);

        if(!eventSubCategory) return;
        let categorySubId = eventSubCategory['CategorySubId'];
        
        console.log("Past", categorySubId, eventSubCategory);

        firebase.firestore().collection("Lists")
            .where('ShowAsPastEvent', '==', true)
            .where("CategorySubId", '==', categorySubId)
            .orderBy("EndDate", "desc")
            .get()
            .then(snapshot => {

                let events = []
                
                snapshot.forEach(doc => {
                    let data = doc.data();
                    events.push(data);
                });
                
                dispatch({ type: types.PAST_EVENTS_SET, value: events });
            })
            .catch(error => {
                console.log("Load Sub Categories Error", error);
            })
    }

}

export function loadListDetail(listId, success) {

    return (dispatch) => {

        dispatch({ type: types.LOADING_SET, value: true });

        firebase.firestore().collection("Lists")
            .where("ListId", '==', listId)
            .get()
            .then(snapshot => {

                let data = snapshot.docs[0].data()
                let active = data['Active']

                if(!data || !active) {
                    console.log("No List Detail");
                    if(success) success(false);
                } else {
                    dispatch({ type: types.LIST_DETAIL_SET, value: data });
                    if(success) success(true);
                }

                dispatch({ type: types.LOADING_SET, value: false });
            })
            .catch(error => {
                dispatch({ type: types.LOADING_SET, value: false });
                console.log("Load List Detail Error", error);
            })
    }

}

export function loadEventDetail(listId, success) {

    return (dispatch) => {

        dispatch({ type: types.LOADING_SET, value: true });

        firebase.firestore().collection("Details")
            .where("ListId", '==', listId)
            .get()
            .then(snapshot => {

                let data = snapshot.docs[0].data()

                if(!data) {
                    console.log("No Event Detail");
                    if(success) success(false);
                } else {
                    dispatch({ type: types.EVENT_DETAIL_SET, value: data });
                    if(success) success(true);
                }

                dispatch({ type: types.LOADING_SET, value: false });
            })
            .catch(error => {
                dispatch({ type: types.LOADING_SET, value: false });
                console.log("Load Event Detail Error", error);
            })
    }

}

export function loadCoupons(collection) {

    return (dispatch) => {

        if(!collection) return;

        dispatch({ type: types.LOADING_SET, value: true });

        firebase.firestore().collection(collection)
            .where('Active', '==', true)
            .get()
            .then(snapshot => {
                let coupons = snapshot.docs.map(doc => doc.data())
                console.log("Loaded Coupons", coupons);
                dispatch({ type: types.COUPON_SET, value: coupons });
                dispatch({ type: types.LOADING_SET, value: false });
            })
            .catch(error => {
                dispatch({ type: types.LOADING_SET, value: false });
                console.log("Load Coupon Error", error)
            })

    }
}

export function loadUser() {

    return (dispatch) => {

        let user = firebase.auth().currentUser;
        firebase.firestore().collection('Users').doc(user.uid).get()
            .then(snapshot => {
                let data = snapshot.data();

                if(data) {
                    dispatch({ type: types.LOGIN_INFO_SET, value: data });
                }
            })
            .catch(error => {
                console.log("Load User Error", error)
            });
    }
}

export function loadAds() {

    return (dispatch) => {

        firebase.firestore().collection('Ads').get()
            .then(snapshot => {
                let docs = {};
                snapshot.docs.forEach(doc => {
                    let data = doc.data();
                    docs[doc.id] = data;
                });
                dispatch({ type: types.ADS_SET, value: docs });
            })
            .catch(error => {
                console.log("Load Ads Error", error)
            });
    }
}

export function signOut() {

    return (dispatch) => {

        firebase.auth().signOut();
        localStorage.clear();
        dispatch({ type: types.USER_STATE_SET, value: false });
        dispatch({ type: types.LOGIN_INFO_SET, value: null });
        
    }
}
