import React, { Component } from 'react'
import NumericInput from 'react-numeric-input'
import './NumericSpinner.css'


class NumericSpinner extends Component {

    state = {
        value: 0,
    }


    onChange(numValue) {
        this.setState({value: numValue})

        if(this.props.onChange) {
            this.props.onChange(numValue)
        }
    }

    render() {
        let {style, className, min, defaultValue, max, disabled} = this.props

        return (
            <NumericInput 
                disabled={disabled}
                style={style}
                className={className}
                min={min}
                value={this.state.value}
                defaultValue={defaultValue}
                max={max}
                onChange={(numValue) => this.onChange(numValue)} />
            )
    }
}

export default NumericSpinner
