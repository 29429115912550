import firebase, {firestore} from './firebase.service'
import EventService from './event.service'
import {AuthEventType as EventType} from './event.type'

const auth = {
    user: {},
}

export default class AuthService {
    
    static startService() {
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                console.log("LOGGED IN")
                // Load user..
                firestore.collection('Users').doc(user.uid).get()
                    .then(snapshot => {
                        if(!snapshot.exists) {
                            auth.user = {}
                        } else {
                            let doc = snapshot.data()
                            auth.user = doc
                        }
                    })
            } else {
                console.log("NOT LOGGED IN!")
                auth.user = {}
            }

            EventService.emitChange(EventType.AUTH_CHANGED)
        })
    }

    static user() {
        return auth.user
    }

    static logout() {
        firebase.auth().signOut()
    }

    static isLoggedIn() {
        let user = firebase.auth().currentUser
        if(user) {
            return true
        } else {
            return false
        }
    }
}
