import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app-router/AppRouter';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as reducers from './store/reducers';
const store = createStore(combineReducers(reducers), applyMiddleware(thunk));


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
  
serviceWorker.unregister();
