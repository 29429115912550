import React, { Component } from 'react'
import './Modal.css'


class Modal extends Component {

    onClose(evt) {
        evt.stopPropagation()
        
        if(this.props.onClose) {
            this.props.onClose()
        }
    }

    onModal(evt) {
        evt.stopPropagation()
    }

    render() {

        let {style} = this.props
        if(!style) style = {}
        
        let containerStyle = {}
        if(this.props.show) {
            containerStyle = 'visible'
        } else {
            containerStyle = ''
        }

        return (
            <div className={"backdrop " + containerStyle} onClick={(evt) => this.onClose(evt)}>
                <div style={style} className="modal-dialog-view" onClick={(evt) => this.onModal(evt)}>
                    <div><div className="close-button fa fa-close" onClick={evt => this.onClose(evt)}></div></div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Modal
