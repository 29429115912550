import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './HomeContent.scss';
import 'react-circular-progressbar/dist/styles.css';

import Utils                    from '../../../../services/utils';

import { connect }              from 'react-redux';
import * as selectors           from '../../../../store/dashboard/reducer';
import * as actions             from '../../../../store/dashboard/actions';
import { Dropdown }             from '../../../compnents';

class HomeContent extends Component {

    constructor(props) {
        super(props)
        this.loadData()
        console.log(this.props.userInfo);
    }

    /*-----------------------------------------------------------*/
    // Load data

    loadData() {
        this.props.dispatch(actions.loadHostedEventsWithDetails());
    }

    /*-----------------------------------------------------------*/
    // Action Functions

    handleReportTapped(item) {
        // if(type === 'earning') {
        //     console.log("Total Earning Report");
        // } else if(type === 'sold') {
        //     console.log("Tickets Sold Report");
        // }

        // this.props.dispatch(actions.setShowReport(true))

        if(item['type'] === 'earning') {
            console.log("Total Earning Report");
            this.props.history.push(`/dashboard/${item['listId']}/payment`)
        } else if(item['type'] === 'sold') {
            this.props.history.push(`/dashboard/${item['listId']}/ticket`)
            console.log("Tickets Sold Report");
        }
    }

    handleManageCoupon(item) {
        this.props.history.push(`/dashboard/${item['listId']}/coupons`)
    }

    handleAllEventsTapped() {
        this.props.history.push(`/events`)
    }

    handleEventTapped(event) {
        this.props.history.push(`/event/${event.ListId}`)
    }


    /*-----------------------------------------------------------*/
    // Process Functions

    handleProcessDashboard() {
        let { dashboard } = this.props;
        console.log(dashboard);
    }

    getTotalEarnings(items) {
        console.log("Payment Items", items);
        let total = 0;
        items.forEach(item => total += Number(item.PaymentTotal));
        return total;
    }

    getTotalTickets(items) {
        let total = 0;
        items.forEach(item => {
            let tickets = item.Items;
            if(tickets) {
                tickets.forEach(platform => total += Number(platform.ItemSelected));
            }
        });
        return total;
    }

    /*-----------------------------------------------------------*/
    // Render

    renderPanelDetails(items) {

        let tabs = [{
            title: 'All Time',
            index: 0,
        }];

        return items.map((item, index) => {

            return (
                <div key={index} className="detail-item">

                    <div className="item-content">
                        <div className="theme">
                            <div>{item['title']}</div>
                            <div className="row flex-center" style={{minWidth: 75}}>
                                <Dropdown 
                                    tabs={tabs}
                                    style={{
                                        border: 'none', 
                                        background: 'transparent', 
                                    }} 
                                    captionStyle={{
                                        fontWeight: 'normal',
                                        padding: 0,
                                    }}
                                    itemStyle={{
                                        fontWeight: 'normal',
                                        padding: 5,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="gain">
                            {item['unit']}{Utils.printNumberWithDelimiter(item['gain'])}
                        </div>

                        <div className="content">
                            
                            <div className="row flex-center">
                                <img className="gain-icon" alt="ARROW"
                                    src={require('../../../../assets/images/arrow.png')} />
                                <div>{item['value']}{item['label']}</div>
                            </div>

                            <div className="row flex-center">
                                <img className="gain-icon" alt="FLOW"
                                    src={require('../../../../assets/images/flow.png')} />
                                <div>{item['unit']}{Utils.printNumberWithDelimiter(item['average'])}</div>
                            </div>

                        </div>
                    </div>

                    <div className="action-pane">

                        <div className="button ticket-book-button" 
                            onClick={() => this.handleReportTapped(item)}>{item['button']}</div>

                        { item['type'] === 'earning' && <div style={{marginTop: 20}} className="button ticket-book-button" 
                            onClick={() => this.handleManageCoupon(item)}>Manage Coupons</div>}

                    </div>
                </div>
            )
        })
    }

    renderPayment(payment, index) {

        let { hostedEventsContent } = this.props;

        let { ListId, Visit } = payment;
        let loading = false;
        let items = [];

        let paymentItems = hostedEventsContent[ListId];

        if(!Visit) Visit = 0;

        if(!paymentItems) {
            loading = true;
        } else {
            let totalEarnings = this.getTotalEarnings(paymentItems);
            let totalTickets = this.getTotalTickets(paymentItems);
            let noOfTransaction = paymentItems.length;


            items = [{
                title: 'Total Earnings',
                type: 'earning',
                unit: '',
                gain: '$' + totalEarnings.toFixed(2),
                value: Visit,
                label: ' Total Visits',
                average: noOfTransaction + ' Transactions',
                listId: ListId,
                button: 'View Payment List',
            }, {
                title: 'Tickets sold',
                type: 'sold',
                unit: '',
                gain: totalTickets,
                value: '',
                label: '',
                average: '',
                listId: ListId,
                button: 'View Ticket List',
            }];   
        }

        return (
            <div key={index} className="row" style={{marginTop: 20}}>
                { 
                    loading && 
                    <div key={index} className="detail-item">
                        <div className="loading-container">
                            <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                        </div>
                    </div>
                }

                { !loading && this.renderPanelDetails(items) }

                <div className="upcoming-event">

                    <div className="theme">
                        <div>Upcoming event</div>
                        <div className="event-button" onClick={() => this.handleEventTapped(payment)}>Event Link</div>
                    </div>

                    <div className="theme-title" onClick={() => this.handleEventTapped(payment)}>{payment.Title}</div>

                    <div className="content">
                        
                        <div className="row flex-center">
                            <img className="clock-icon" alt="CLOCK"
                                src={require('../../../../assets/images/clock.png')} />
                            <div className="clock-text">{payment.EventDate}</div>
                        </div>

                    </div>

                </div>

            </div>
        )
    }

    render() {

        let { hostedEvents, loading } = this.props;

        return (
            <div className="dashboard-content-container">

                {
                    loading && 
                    <div className="loading-container">
                        <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                }

                <div className="event-title">DASHBOARD</div>

                <div className="dashboard-content">

                    { !hostedEvents && <div />}

                    { hostedEvents && hostedEvents.map((item, index) => this.renderPayment(item, index)) }

                    <div className="payment-info">
                        <div className="payment-title">Payment Information</div>
                        <div className="content">
                            <img alt="COIN" src={require('../../../../assets/images/ic_coin.png')} />
                            <div>No payment info on account.</div>
                            <div>
                                <span className="event-button">Add Payment Info</span>
                                to start receiving payments to your bank account.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loading:                selectors.getLoading(state),
        error:                  selectors.getError(state),
        hostedEvents:           selectors.getHostedEvents(state),
        hostedEventsContent:    selectors.getDashboardContent(state),
        userInfo:               selectors.getUserInfo(state),
    }
}

export default connect(mapStateToProps)(withRouter(HomeContent));
